import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { ActionByStoreService } from '../stores';


@Injectable()
export class ActionByGuardService implements CanActivate {

  constructor(private actionByStore: ActionByStoreService, private loginService: LoginService) { }


  canActivate(): Observable<boolean> {
    return new Observable((observator) => {
      const projectTemplate = this.loginService.projectTemplate;
      const actionByData = this.actionByStore.actionBy;
      if (actionByData && actionByData.projectId === projectTemplate.project.id
        && actionByData.templateId === projectTemplate.template.id) {
        observator.next(true);
        observator.complete();
      } else {
        this.actionByStore.loadActionBy().subscribe(data => {
          this.actionByStore.actionBy = { data,
            projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
          observator.next(true);
          observator.complete();
        });
      }
    });
  }
}
