import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  Input
} from '@angular/core';

@Directive({
  selector: '[appPaste]'
})
export class PasteDirective {
  @Output() dataPaste = new EventEmitter<ClipboardEvent>();
  @Input() handlePaste: (event: ClipboardEvent) => boolean = null;

  constructor(combo: ElementRef) {
    const pasteFunction = (event: ClipboardEvent) => {
      if (this.handlePaste === null || this.handlePaste(event)) {
        this.dataPaste.emit(event);

        event.stopImmediatePropagation();
        event.stopPropagation();
        return false;
      }
    };

    combo.nativeElement.onpaste = pasteFunction;
  }
}
