/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PunchSignatureViewModel } from './punchSignatureViewModel';
import { PunchStageStatusViewModel } from './punchStageStatusViewModel';
import { RemarkViewModel } from './remarkViewModel';
import { UserViewModel } from './userViewModel';

export interface PunchStageViewModel { 
    id?: number;
    done?: boolean;
    reject?: boolean;
    rejectCount?: number;
    overridden?: boolean;
    createdAt?: Date;
    user?: UserViewModel;
    assignedTo?: UserViewModel;
    lastModifiedAt?: Date;
    doneByUser?: UserViewModel;
    doneAt?: Date;
    isSignable?: boolean;
    isRejectable?: boolean;
    isDisputable?: boolean;
    isOverridable?: boolean;
    statusStage?: PunchStageStatusViewModel;
    remark?: RemarkViewModel;
    sign?: PunchSignatureViewModel;
    comment?: string;
    signConfigId?: number;
}