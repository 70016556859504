import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subject ,  Observable } from 'rxjs';
import { Alert } from '../alert';
import { AlertType } from '../alert-type';


@Injectable()
export class AlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string) {
        this.alert(AlertType.Success, message, 5000);
    }

    error(message: string) {
        this.alert(AlertType.Error, message);
    }

    info(message: string, diplay?: boolean) {
        this.alert(AlertType.Info, message, diplay ? 5000 : null);
    }

    warn(message: string) {
        this.alert(AlertType.Warning, message);
    }

    alert(type: AlertType, message: string, displayTime: number = null) {
        if (message) {
            this.subject.next({ type, message, displayTime } as Alert);
        }
    }

    clear() {
        // clear alerts
        this.subject.next(null);
    }
}
