import { Component } from '@angular/core';
import { EXAMPLE_USER } from './models/const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  user: any = EXAMPLE_USER;

  constructor() {
  }



}
