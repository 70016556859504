import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadingComponent } from './downloading.component';
import { DownloadingService } from './service/downloading.service';
import { LoaderModule } from '../loader/loader.module';



@NgModule({
  declarations: [DownloadingComponent],
  imports: [
    CommonModule,
    LoaderModule
  ],
  exports: [
    DownloadingComponent
  ],
  providers: [
    DownloadingService
  ]
})
export class FileDownloadModule { }
