import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvService } from '../deployment/env.service';
import { AuthService, URLKEY } from '../services/auth.service';
import { LoginService } from '../services/login.service';

@Injectable()
export class IsLoggedGuardService implements CanActivate {

  constructor(private authService: AuthService, private loginService: LoginService, private env: EnvService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url = state.url;
    sessionStorage.setItem(URLKEY, url);

    return new Observable((observator) => {
      let isLogged = false;
      if (this.authService.isLoggedIn()) {
        this.authService.loadUser().subscribe(() => {
          isLogged = true;
          observator.next(isLogged);
          observator.complete();
        });
      } else {
        this.authService.startAuthentication(url);
      }
    });

  }
}
