import { Component, OnInit } from '@angular/core';

import { AlertService } from './service/alert.service';
import { Alert } from './alert';

@Component({
    moduleId: module.id,
    selector: 'app-alert',
    template: '<div class="alert-container"><div *ngFor="let alert of alerts" class="alert {{ alert.type }} alert-dismissable">' +
    '{{alert.message}}<a class="close" (click)="removeAlert(alert)">&times;</a>' +
    '</div></div>',
    styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit {
    alerts: Alert[] = [];

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.alertService.getAlert().subscribe((alert: Alert) => {
            if (!alert) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }
            // add alert to array
            this.alerts.push(alert);
            if (alert.displayTime && alert.displayTime > 0) {
                setTimeout(() => {
                    this.removeAlert(alert);
                }, alert.displayTime);
            }
        });
    }

    removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }
}
