import { ProjectTemplateViewModel } from 'src/proxy';

export const PROJECT_ID = 'ProjectId';
export const TEMPLATE_ID = 'TemplateId';
export const SUBCONTRACTOR_ID = 'SubcontractorId';
export const COOKIE_PROJECTID = 'WAP-ProjectId';
export const COOKIE_TEMPLATEID = 'WAP-TemplateId';

export function getAuthorizationProperties(projectTemplate: ProjectTemplateViewModel, subcontractorId = null, includeAsClaim = true) {
    if (!projectTemplate) { return null; }
    const res = {
        [PROJECT_ID]: {
            value: String(projectTemplate.project.id),
            includeAsClaim
        },
        [TEMPLATE_ID]: {
            value: String(projectTemplate.template.id),
            includeAsClaim
        }
    };
    if (subcontractorId) {
        res[SUBCONTRACTOR_ID] = {
            value: subcontractorId,
            includeAsClaim
        };
    }
    return res;
}

export function getAuthorizationPropertiesByIds(projectId: number, templateId: number, includeAsClaim = true) {
    if (!projectId || !templateId) { return null; }
    return {
        [PROJECT_ID]: {
            value: String(projectId),
            includeAsClaim
        },
        [TEMPLATE_ID]: {
            value: String(templateId),
            includeAsClaim
        }
    }
}