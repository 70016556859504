/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InspectionWalkOutDto } from './inspectionWalkOutDto';

export interface UserWalkOutDto { 
    id?: string;
    email?: string;
    name?: string;
    phone?: string;
    company?: string;
    walks?: Array<InspectionWalkOutDto>;
}