import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { PriorityStoreService } from '../stores';


@Injectable()
export class PriorityGuardService implements CanActivate {

  constructor(private priorityStore: PriorityStoreService, private loginService: LoginService) { }


  canActivate(): Observable<boolean> {
    return new Observable((observator) => {
      const projectTemplate = this.loginService.projectTemplate;
      const priorityData = this.priorityStore.priority;
      if (priorityData && priorityData.projectId === projectTemplate.project.id
        && priorityData.templateId === projectTemplate.template.id) {
        observator.next(true);
        observator.complete();
      } else {
        this.priorityStore.loadPriority().subscribe(data => {
          this.priorityStore.priority = { data,
            projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
          observator.next(true);
          observator.complete();
        });
      }
    });
  }
}
