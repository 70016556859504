export * from './adHocFixes.service';
import { AdHocFixesService } from './adHocFixes.service';
export * from './category.service';
import { CategoryService } from './category.service';
export * from './customTemplate.service';
import { CustomTemplateService } from './customTemplate.service';
export * from './departmentAction.service';
import { DepartmentActionService } from './departmentAction.service';
export * from './discipline.service';
import { DisciplineService } from './discipline.service';
export * from './documentType.service';
import { DocumentTypeService } from './documentType.service';
export * from './elements.service';
import { ElementsService } from './elements.service';
export * from './entryType.service';
import { EntryTypeService } from './entryType.service';
export * from './experiedTime.service';
import { ExperiedTimeService } from './experiedTime.service';
export * from './experiedType.service';
import { ExperiedTypeService } from './experiedType.service';
export * from './external.service';
import { ExternalService } from './external.service';
export * from './externalReport.service';
import { ExternalReportService } from './externalReport.service';
export * from './inspection.service';
import { InspectionService } from './inspection.service';
export * from './inspectionType.service';
import { InspectionTypeService } from './inspectionType.service';
export * from './inspectionTypeFlowType.service';
import { InspectionTypeFlowTypeService } from './inspectionTypeFlowType.service';
export * from './inspectionWalk.service';
import { InspectionWalkService } from './inspectionWalk.service';
export * from './maps.service';
import { MapsService } from './maps.service';
export * from './master.service';
import { MasterService } from './master.service';
export * from './media.service';
import { MediaService } from './media.service';
export * from './memberStatus.service';
import { MemberStatusService } from './memberStatus.service';
export * from './migration.service';
import { MigrationService } from './migration.service';
export * from './notificationField.service';
import { NotificationFieldService } from './notificationField.service';
export * from './originator.service';
import { OriginatorService } from './originator.service';
export * from './priority.service';
import { PriorityService } from './priority.service';
export * from './process.service';
import { ProcessService } from './process.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './punch.service';
import { PunchService } from './punch.service';
export * from './punchSignFlow.service';
import { PunchSignFlowService } from './punchSignFlow.service';
export * from './report.service';
import { ReportService } from './report.service';
export * from './signAction.service';
import { SignActionService } from './signAction.service';
export * from './sleep.service';
import { SleepService } from './sleep.service';
export * from './status.service';
import { StatusService } from './status.service';
export * from './subcontract.service';
import { SubcontractService } from './subcontract.service';
export * from './tag.service';
import { TagService } from './tag.service';
export * from './template.service';
import { TemplateService } from './template.service';
export * from './templateProjectSubcontract.service';
import { TemplateProjectSubcontractService } from './templateProjectSubcontract.service';
export * from './templateTags.service';
import { TemplateTagsService } from './templateTags.service';
export * from './topic.service';
import { TopicService } from './topic.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './version.service';
import { VersionService } from './version.service';
export * from './wBS.service';
import { WBSService } from './wBS.service';
export const APIS = [AdHocFixesService, CategoryService, CustomTemplateService, DepartmentActionService, DisciplineService, DocumentTypeService, ElementsService, EntryTypeService, ExperiedTimeService, ExperiedTypeService, ExternalService, ExternalReportService, InspectionService, InspectionTypeService, InspectionTypeFlowTypeService, InspectionWalkService, MapsService, MasterService, MediaService, MemberStatusService, MigrationService, NotificationFieldService, OriginatorService, PriorityService, ProcessService, ProjectService, PunchService, PunchSignFlowService, ReportService, SignActionService, SleepService, StatusService, SubcontractService, TagService, TemplateService, TemplateProjectSubcontractService, TemplateTagsService, TopicService, UserService, VersionService, WBSService];
