import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReportService } from 'src/proxy';


@Injectable()
export class LiveReportsStoreService {
    

    _liveReports$ = new BehaviorSubject<any[]>(null);

    constructor(
        private reportService: ReportService
        ) {
    }

    refreshReports() {
        this.loadLiveReports().subscribe(data => {
            this.liveReports = data;
        });
    }

    loadLiveReports() {
        return this.reportService.getCustomReportsForUserRole();
    }

    get liveReports() {
        return this._liveReports$.value;
    }

    set liveReports(data: any[]) {
        this._liveReports$.next(data);
    }

    get tag$() {
        return this._liveReports$;
    }
}