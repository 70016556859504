/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MasterDto } from './masterDto';

export interface InspectionFileDto { 
    id?: number;
    mimeType?: string;
    name?: string;
    description?: string;
    imageFileServer?: string;
    file?: string;
    _default?: boolean;
    defaultDownload?: boolean;
    documentType?: MasterDto;
    lastModifiedAt?: Date;
    createdAt?: Date;
    size?: number;
}