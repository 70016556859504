/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CategoryViewModel } from '../model/categoryViewModel';
import { InspectionTypeViewModel } from '../model/inspectionTypeViewModel';
import { ProjectViewModel } from '../model/projectViewModel';
import { PunchCustomFieldViewModel } from '../model/punchCustomFieldViewModel';
import { TemplateIdCategoryBody } from '../model/templateIdCategoryBody';
import { TemplateIdCategoryBody1 } from '../model/templateIdCategoryBody1';
import { TemplateIdCategoryBody2 } from '../model/templateIdCategoryBody2';
import { TemplateIdCategoryBody3 } from '../model/templateIdCategoryBody3';
import { TemplateIdCategoryBody4 } from '../model/templateIdCategoryBody4';
import { TemplateIdCategoryBody5 } from '../model/templateIdCategoryBody5';
import { TemplateIdCategoryBody6 } from '../model/templateIdCategoryBody6';
import { TemplateIdCategoryBody7 } from '../model/templateIdCategoryBody7';
import { TemplateIdTemplatePunchCustomFieldBody } from '../model/templateIdTemplatePunchCustomFieldBody';
import { TemplateIdTemplatePunchCustomFieldBody1 } from '../model/templateIdTemplatePunchCustomFieldBody1';
import { TemplateIdTemplatePunchCustomFieldBody2 } from '../model/templateIdTemplatePunchCustomFieldBody2';
import { TemplateIdTemplatePunchCustomFieldBody3 } from '../model/templateIdTemplatePunchCustomFieldBody3';
import { TemplateIdTemplatePunchCustomFieldBody4 } from '../model/templateIdTemplatePunchCustomFieldBody4';
import { TemplateIdTemplatePunchCustomFieldBody5 } from '../model/templateIdTemplatePunchCustomFieldBody5';
import { TemplateIdTemplatePunchCustomFieldBody6 } from '../model/templateIdTemplatePunchCustomFieldBody6';
import { TemplateIdTemplatePunchCustomFieldBody7 } from '../model/templateIdTemplatePunchCustomFieldBody7';
import { TemplateIdTopicBody } from '../model/templateIdTopicBody';
import { TemplateIdTopicBody1 } from '../model/templateIdTopicBody1';
import { TemplateIdTopicBody2 } from '../model/templateIdTopicBody2';
import { TemplateIdTopicBody3 } from '../model/templateIdTopicBody3';
import { TemplateIdTopicBody4 } from '../model/templateIdTopicBody4';
import { TemplateIdTopicBody5 } from '../model/templateIdTopicBody5';
import { TemplateIdTopicBody6 } from '../model/templateIdTopicBody6';
import { TemplateIdTopicBody7 } from '../model/templateIdTopicBody7';
import { TemplateProjectConfigViewModel } from '../model/templateProjectConfigViewModel';
import { TemplateProjectConfigurationBody } from '../model/templateProjectConfigurationBody';
import { TemplateProjectConfigurationBody1 } from '../model/templateProjectConfigurationBody1';
import { TemplateProjectConfigurationBody2 } from '../model/templateProjectConfigurationBody2';
import { TemplateProjectConfigurationBody3 } from '../model/templateProjectConfigurationBody3';
import { TemplateProjectConfigurationBody4 } from '../model/templateProjectConfigurationBody4';
import { TemplateProjectConfigurationBody5 } from '../model/templateProjectConfigurationBody5';
import { TemplateProjectConfigurationBody6 } from '../model/templateProjectConfigurationBody6';
import { TemplateProjectConfigurationBody7 } from '../model/templateProjectConfigurationBody7';
import { TemplatePunchCustomFieldViewModel } from '../model/templatePunchCustomFieldViewModel';
import { TemplateViewModel } from '../model/templateViewModel';
import { TopicViewModel } from '../model/topicViewModel';
import { V1TemplateBody } from '../model/v1TemplateBody';
import { V1TemplateBody1 } from '../model/v1TemplateBody1';
import { V1TemplateBody2 } from '../model/v1TemplateBody2';
import { V1TemplateBody3 } from '../model/v1TemplateBody3';
import { V1TemplateBody4 } from '../model/v1TemplateBody4';
import { V1TemplateBody5 } from '../model/v1TemplateBody5';
import { V1TemplateBody6 } from '../model/v1TemplateBody6';
import { V1TemplateBody7 } from '../model/v1TemplateBody7';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TemplateService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param templateId 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectsById(templateId: number, projectId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<boolean>>;
    public deleteProjectsById(templateId: number, projectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<boolean>>>;
    public deleteProjectsById(templateId: number, projectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<boolean>>>;
    public deleteProjectsById(templateId: number, projectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling deleteProjectsById.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectsById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<boolean>>('delete',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Project/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTemplate(id: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteTemplate(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteTemplate(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteTemplate(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteTemplate.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/Template/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param categoryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTemplateCategory(templateId: number, categoryId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteTemplateCategory(templateId: number, categoryId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteTemplateCategory(templateId: number, categoryId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteTemplateCategory(templateId: number, categoryId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling deleteTemplateCategory.');
        }

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling deleteTemplateCategory.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Category/${encodeURIComponent(String(categoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectConfigurationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTemplateConfiguration(projectConfigurationId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteTemplateConfiguration(projectConfigurationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteTemplateConfiguration(projectConfigurationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteTemplateConfiguration(projectConfigurationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectConfigurationId === null || projectConfigurationId === undefined) {
            throw new Error('Required parameter projectConfigurationId was null or undefined when calling deleteTemplateConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/Template/ProjectConfiguration/${encodeURIComponent(String(projectConfigurationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param templatePunchCustomFieldId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTemplateTemplatePunchCustomField(templateId: number, templatePunchCustomFieldId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteTemplateTemplatePunchCustomField(templateId: number, templatePunchCustomFieldId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteTemplateTemplatePunchCustomField(templateId: number, templatePunchCustomFieldId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteTemplateTemplatePunchCustomField(templateId: number, templatePunchCustomFieldId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling deleteTemplateTemplatePunchCustomField.');
        }

        if (templatePunchCustomFieldId === null || templatePunchCustomFieldId === undefined) {
            throw new Error('Required parameter templatePunchCustomFieldId was null or undefined when calling deleteTemplateTemplatePunchCustomField.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/TemplatePunchCustomField/${encodeURIComponent(String(templatePunchCustomFieldId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param topicId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTemplateTopic(templateId: number, topicId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteTemplateTopic(templateId: number, topicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteTemplateTopic(templateId: number, topicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteTemplateTopic(templateId: number, topicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling deleteTemplateTopic.');
        }

        if (topicId === null || topicId === undefined) {
            throw new Error('Required parameter topicId was null or undefined when calling deleteTemplateTopic.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Topic/${encodeURIComponent(String(topicId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTemplates(observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateViewModel>>;
    public getAllTemplates(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateViewModel>>>;
    public getAllTemplates(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateViewModel>>>;
    public getAllTemplates(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateViewModel>>('get',`${this.basePath}/v1/Template/allTemplates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCategoriesByTemplateId(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CategoryViewModel>>;
    public getCategoriesByTemplateId(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CategoryViewModel>>>;
    public getCategoriesByTemplateId(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CategoryViewModel>>>;
    public getCategoriesByTemplateId(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getCategoriesByTemplateId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CategoryViewModel>>('get',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Categories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param categoryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCategoryById(templateId: number, categoryId: number, observe?: 'body', reportProgress?: boolean): Observable<CategoryViewModel>;
    public getCategoryById(templateId: number, categoryId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoryViewModel>>;
    public getCategoryById(templateId: number, categoryId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoryViewModel>>;
    public getCategoryById(templateId: number, categoryId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getCategoryById.');
        }

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getCategoryById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CategoryViewModel>('get',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Category/${encodeURIComponent(String(categoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectConfigurationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfigurationsById(projectConfigurationId: number, observe?: 'body', reportProgress?: boolean): Observable<TemplateProjectConfigViewModel>;
    public getConfigurationsById(projectConfigurationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateProjectConfigViewModel>>;
    public getConfigurationsById(projectConfigurationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateProjectConfigViewModel>>;
    public getConfigurationsById(projectConfigurationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectConfigurationId === null || projectConfigurationId === undefined) {
            throw new Error('Required parameter projectConfigurationId was null or undefined when calling getConfigurationsById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateProjectConfigViewModel>('get',`${this.basePath}/v1/Template/ProjectConfiguration/${encodeURIComponent(String(projectConfigurationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfigurationsByTemplateId(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateProjectConfigViewModel>>;
    public getConfigurationsByTemplateId(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateProjectConfigViewModel>>>;
    public getConfigurationsByTemplateId(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateProjectConfigViewModel>>>;
    public getConfigurationsByTemplateId(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getConfigurationsByTemplateId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateProjectConfigViewModel>>('get',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/ProjectConfigurations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInspectionTypeByTemplateId(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InspectionTypeViewModel>>;
    public getInspectionTypeByTemplateId(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InspectionTypeViewModel>>>;
    public getInspectionTypeByTemplateId(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InspectionTypeViewModel>>>;
    public getInspectionTypeByTemplateId(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getInspectionTypeByTemplateId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<InspectionTypeViewModel>>('get',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/InspectionTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectsByTemplateId(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectViewModel>>;
    public getProjectsByTemplateId(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectViewModel>>>;
    public getProjectsByTemplateId(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectViewModel>>>;
    public getProjectsByTemplateId(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getProjectsByTemplateId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProjectViewModel>>('get',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Projects`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param onlyCustosms 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPunchCustomFieldsByTemplateId(onlyCustosms?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<PunchCustomFieldViewModel>>;
    public getPunchCustomFieldsByTemplateId(onlyCustosms?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PunchCustomFieldViewModel>>>;
    public getPunchCustomFieldsByTemplateId(onlyCustosms?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PunchCustomFieldViewModel>>>;
    public getPunchCustomFieldsByTemplateId(onlyCustosms?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (onlyCustosms !== undefined && onlyCustosms !== null) {
            queryParameters = queryParameters.set('onlyCustosms', <any>onlyCustosms);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PunchCustomFieldViewModel>>('get',`${this.basePath}/v1/Template/PunchCustomFields`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemplateById(id: number, observe?: 'body', reportProgress?: boolean): Observable<TemplateViewModel>;
    public getTemplateById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateViewModel>>;
    public getTemplateById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateViewModel>>;
    public getTemplateById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTemplateById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateViewModel>('get',`${this.basePath}/v1/Template/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param templatePunchCustomFieldId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemplatePunchCustomFieldsById(templateId: number, templatePunchCustomFieldId: number, observe?: 'body', reportProgress?: boolean): Observable<TemplatePunchCustomFieldViewModel>;
    public getTemplatePunchCustomFieldsById(templateId: number, templatePunchCustomFieldId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplatePunchCustomFieldViewModel>>;
    public getTemplatePunchCustomFieldsById(templateId: number, templatePunchCustomFieldId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplatePunchCustomFieldViewModel>>;
    public getTemplatePunchCustomFieldsById(templateId: number, templatePunchCustomFieldId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getTemplatePunchCustomFieldsById.');
        }

        if (templatePunchCustomFieldId === null || templatePunchCustomFieldId === undefined) {
            throw new Error('Required parameter templatePunchCustomFieldId was null or undefined when calling getTemplatePunchCustomFieldsById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplatePunchCustomFieldViewModel>('get',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/TemplatePunchCustomField/${encodeURIComponent(String(templatePunchCustomFieldId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemplatePunchCustomFieldsByTemplateId(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplatePunchCustomFieldViewModel>>;
    public getTemplatePunchCustomFieldsByTemplateId(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplatePunchCustomFieldViewModel>>>;
    public getTemplatePunchCustomFieldsByTemplateId(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplatePunchCustomFieldViewModel>>>;
    public getTemplatePunchCustomFieldsByTemplateId(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getTemplatePunchCustomFieldsByTemplateId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplatePunchCustomFieldViewModel>>('get',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/TemplatePunchCustomFields`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param topicId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTopicsById(templateId: number, topicId: number, observe?: 'body', reportProgress?: boolean): Observable<TopicViewModel>;
    public getTopicsById(templateId: number, topicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TopicViewModel>>;
    public getTopicsById(templateId: number, topicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TopicViewModel>>;
    public getTopicsById(templateId: number, topicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getTopicsById.');
        }

        if (topicId === null || topicId === undefined) {
            throw new Error('Required parameter topicId was null or undefined when calling getTopicsById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TopicViewModel>('get',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Topic/${encodeURIComponent(String(topicId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTopicsByTemplateId(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TopicViewModel>>;
    public getTopicsByTemplateId(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TopicViewModel>>>;
    public getTopicsByTemplateId(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TopicViewModel>>>;
    public getTopicsByTemplateId(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getTopicsByTemplateId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TopicViewModel>>('get',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Topics`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postTemplate(body?: V1TemplateBody4, observe?: 'body', reportProgress?: boolean): Observable<TemplateViewModel>;
    public postTemplate(body?: V1TemplateBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateViewModel>>;
    public postTemplate(body?: V1TemplateBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateViewModel>>;
    public postTemplate(body?: V1TemplateBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplateViewModel>('post',`${this.basePath}/v1/Template`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postTemplateAssignProject(templateId: number, projectId: number, observe?: 'body', reportProgress?: boolean): Observable<TemplateViewModel>;
    public postTemplateAssignProject(templateId: number, projectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateViewModel>>;
    public postTemplateAssignProject(templateId: number, projectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateViewModel>>;
    public postTemplateAssignProject(templateId: number, projectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling postTemplateAssignProject.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling postTemplateAssignProject.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateViewModel>('post',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Assign/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postTemplateCategory(templateId: number, body?: TemplateIdCategoryBody4, observe?: 'body', reportProgress?: boolean): Observable<CategoryViewModel>;
    public postTemplateCategory(templateId: number, body?: TemplateIdCategoryBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoryViewModel>>;
    public postTemplateCategory(templateId: number, body?: TemplateIdCategoryBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoryViewModel>>;
    public postTemplateCategory(templateId: number, body?: TemplateIdCategoryBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling postTemplateCategory.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CategoryViewModel>('post',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Category`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postTemplateClonet(id: number, observe?: 'body', reportProgress?: boolean): Observable<TemplateViewModel>;
    public postTemplateClonet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateViewModel>>;
    public postTemplateClonet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateViewModel>>;
    public postTemplateClonet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling postTemplateClonet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateViewModel>('post',`${this.basePath}/v1/Template/${encodeURIComponent(String(id))}/Clone`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postTemplateConfiguration(body?: TemplateProjectConfigurationBody4, observe?: 'body', reportProgress?: boolean): Observable<TemplateProjectConfigViewModel>;
    public postTemplateConfiguration(body?: TemplateProjectConfigurationBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateProjectConfigViewModel>>;
    public postTemplateConfiguration(body?: TemplateProjectConfigurationBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateProjectConfigViewModel>>;
    public postTemplateConfiguration(body?: TemplateProjectConfigurationBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplateProjectConfigViewModel>('post',`${this.basePath}/v1/Template/ProjectConfiguration`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postTemplatePunchCustomField(templateId: number, body?: TemplateIdTemplatePunchCustomFieldBody4, observe?: 'body', reportProgress?: boolean): Observable<TemplatePunchCustomFieldViewModel>;
    public postTemplatePunchCustomField(templateId: number, body?: TemplateIdTemplatePunchCustomFieldBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplatePunchCustomFieldViewModel>>;
    public postTemplatePunchCustomField(templateId: number, body?: TemplateIdTemplatePunchCustomFieldBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplatePunchCustomFieldViewModel>>;
    public postTemplatePunchCustomField(templateId: number, body?: TemplateIdTemplatePunchCustomFieldBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling postTemplatePunchCustomField.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplatePunchCustomFieldViewModel>('post',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/TemplatePunchCustomField`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postTemplateTopic(templateId: number, body?: TemplateIdTopicBody4, observe?: 'body', reportProgress?: boolean): Observable<TopicViewModel>;
    public postTemplateTopic(templateId: number, body?: TemplateIdTopicBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TopicViewModel>>;
    public postTemplateTopic(templateId: number, body?: TemplateIdTopicBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TopicViewModel>>;
    public postTemplateTopic(templateId: number, body?: TemplateIdTopicBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling postTemplateTopic.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TopicViewModel>('post',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Topic`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param projectId 
     * @param closeProjectTemplate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putCloseProjectTemplate(templateId: number, projectId: number, closeProjectTemplate: boolean, observe?: 'body', reportProgress?: boolean): Observable<TemplateViewModel>;
    public putCloseProjectTemplate(templateId: number, projectId: number, closeProjectTemplate: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateViewModel>>;
    public putCloseProjectTemplate(templateId: number, projectId: number, closeProjectTemplate: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateViewModel>>;
    public putCloseProjectTemplate(templateId: number, projectId: number, closeProjectTemplate: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling putCloseProjectTemplate.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling putCloseProjectTemplate.');
        }

        if (closeProjectTemplate === null || closeProjectTemplate === undefined) {
            throw new Error('Required parameter closeProjectTemplate was null or undefined when calling putCloseProjectTemplate.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateViewModel>('put',`${this.basePath}/v1/Template/CloseProjectTemplate/${encodeURIComponent(String(templateId))}/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(closeProjectTemplate))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param isEdit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putPunchCustomFieldEdit(id: number, isEdit: boolean, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public putPunchCustomFieldEdit(id: number, isEdit: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public putPunchCustomFieldEdit(id: number, isEdit: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public putPunchCustomFieldEdit(id: number, isEdit: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling putPunchCustomFieldEdit.');
        }

        if (isEdit === null || isEdit === undefined) {
            throw new Error('Required parameter isEdit was null or undefined when calling putPunchCustomFieldEdit.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('put',`${this.basePath}/v1/Template/PunchCustomField/${encodeURIComponent(String(id))}/Edit/${encodeURIComponent(String(isEdit))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putPunchCustomFieldNotAllowedRoles(id: number, body?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PunchCustomFieldViewModel>;
    public putPunchCustomFieldNotAllowedRoles(id: number, body?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PunchCustomFieldViewModel>>;
    public putPunchCustomFieldNotAllowedRoles(id: number, body?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PunchCustomFieldViewModel>>;
    public putPunchCustomFieldNotAllowedRoles(id: number, body?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling putPunchCustomFieldNotAllowedRoles.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PunchCustomFieldViewModel>('put',`${this.basePath}/v1/Template/PunchCustomField/${encodeURIComponent(String(id))}/NotAllowedRoles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putTemplate(body?: V1TemplateBody, observe?: 'body', reportProgress?: boolean): Observable<TemplateViewModel>;
    public putTemplate(body?: V1TemplateBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateViewModel>>;
    public putTemplate(body?: V1TemplateBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateViewModel>>;
    public putTemplate(body?: V1TemplateBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplateViewModel>('put',`${this.basePath}/v1/Template`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putTemplateCategory(templateId: number, body?: TemplateIdCategoryBody, observe?: 'body', reportProgress?: boolean): Observable<CategoryViewModel>;
    public putTemplateCategory(templateId: number, body?: TemplateIdCategoryBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoryViewModel>>;
    public putTemplateCategory(templateId: number, body?: TemplateIdCategoryBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoryViewModel>>;
    public putTemplateCategory(templateId: number, body?: TemplateIdCategoryBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling putTemplateCategory.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CategoryViewModel>('put',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Category`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putTemplateConfiguration(body?: TemplateProjectConfigurationBody, observe?: 'body', reportProgress?: boolean): Observable<TemplateProjectConfigViewModel>;
    public putTemplateConfiguration(body?: TemplateProjectConfigurationBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateProjectConfigViewModel>>;
    public putTemplateConfiguration(body?: TemplateProjectConfigurationBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateProjectConfigViewModel>>;
    public putTemplateConfiguration(body?: TemplateProjectConfigurationBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplateProjectConfigViewModel>('put',`${this.basePath}/v1/Template/ProjectConfiguration`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putTemplateTemplatePunchCustomField(templateId: number, body?: TemplateIdTemplatePunchCustomFieldBody, observe?: 'body', reportProgress?: boolean): Observable<TemplatePunchCustomFieldViewModel>;
    public putTemplateTemplatePunchCustomField(templateId: number, body?: TemplateIdTemplatePunchCustomFieldBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplatePunchCustomFieldViewModel>>;
    public putTemplateTemplatePunchCustomField(templateId: number, body?: TemplateIdTemplatePunchCustomFieldBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplatePunchCustomFieldViewModel>>;
    public putTemplateTemplatePunchCustomField(templateId: number, body?: TemplateIdTemplatePunchCustomFieldBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling putTemplateTemplatePunchCustomField.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplatePunchCustomFieldViewModel>('put',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/TemplatePunchCustomField`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param templateId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putTemplateTopic(templateId: number, body?: TemplateIdTopicBody, observe?: 'body', reportProgress?: boolean): Observable<TopicViewModel>;
    public putTemplateTopic(templateId: number, body?: TemplateIdTopicBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TopicViewModel>>;
    public putTemplateTopic(templateId: number, body?: TemplateIdTopicBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TopicViewModel>>;
    public putTemplateTopic(templateId: number, body?: TemplateIdTopicBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling putTemplateTopic.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TopicViewModel>('put',`${this.basePath}/v1/Template/${encodeURIComponent(String(templateId))}/Topic`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param signatureFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public upsertOverridenSignatureForm(signatureFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<TemplateProjectConfigViewModel>;
    public upsertOverridenSignatureForm(signatureFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateProjectConfigViewModel>>;
    public upsertOverridenSignatureForm(signatureFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateProjectConfigViewModel>>;
    public upsertOverridenSignatureForm(signatureFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (signatureFile !== undefined) {
            formParams = formParams.append('signatureFile', <any>signatureFile) as any || formParams;
        }

        return this.httpClient.request<TemplateProjectConfigViewModel>('post',`${this.basePath}/v1/Template/OverridenSignature`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
