import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LoaderModule } from './shared/loader/loader.module';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ApiModule } from 'src/proxy';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import 'hammerjs';
import { EnvServiceProvider } from './core/deployment/env.service.provider';
import { OAuthModule } from 'angular-oauth2-oidc';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    GridModule,
    BrowserAnimationsModule,
    DropDownsModule,
    LoaderModule,
    DialogsModule,
    ApiModule,
    NavigationModule,
    OAuthModule.forRoot()
  ],
  providers: [
    EnvServiceProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
