/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllPunchesBookmarksPagedBody } from '../model/allPunchesBookmarksPagedBody';
import { AllPunchesBookmarksPagedBody1 } from '../model/allPunchesBookmarksPagedBody1';
import { AllPunchesBookmarksPagedBody2 } from '../model/allPunchesBookmarksPagedBody2';
import { AllPunchesBookmarksPagedBody3 } from '../model/allPunchesBookmarksPagedBody3';
import { BookmarkDataWapPunchReflectionViewModel } from '../model/bookmarkDataWapPunchReflectionViewModel';
import { MasterViewModel } from '../model/masterViewModel';
import { PropertyFilterViewModel } from '../model/propertyFilterViewModel';
import { PunchBookmarksViewModel } from '../model/punchBookmarksViewModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExternalService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param planningDate 
     * @param inspectionName 
     * @param userId 
     * @param applicationName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAndStartInspection(body?: Array<number>, planningDate?: Date, inspectionName?: string, userId?: string, applicationName?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createAndStartInspection(body?: Array<number>, planningDate?: Date, inspectionName?: string, userId?: string, applicationName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createAndStartInspection(body?: Array<number>, planningDate?: Date, inspectionName?: string, userId?: string, applicationName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createAndStartInspection(body?: Array<number>, planningDate?: Date, inspectionName?: string, userId?: string, applicationName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (planningDate !== undefined && planningDate !== null) {
            queryParameters = queryParameters.set('planningDate', <any>planningDate.toISOString());
        }
        if (inspectionName !== undefined && inspectionName !== null) {
            queryParameters = queryParameters.set('inspectionName', <any>inspectionName);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (applicationName !== undefined && applicationName !== null) {
            queryParameters = queryParameters.set('applicationName', <any>applicationName);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/v1/External/createAndStartInspection`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInspection(inspectionId?: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteInspection(inspectionId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteInspection(inspectionId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteInspection(inspectionId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inspectionId !== undefined && inspectionId !== null) {
            queryParameters = queryParameters.set('inspectionId', <any>inspectionId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/External/DeleteInspection`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPunchesBookmarksPaged(body?: AllPunchesBookmarksPagedBody, observe?: 'body', reportProgress?: boolean): Observable<Array<PunchBookmarksViewModel>>;
    public getAllPunchesBookmarksPaged(body?: AllPunchesBookmarksPagedBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PunchBookmarksViewModel>>>;
    public getAllPunchesBookmarksPaged(body?: AllPunchesBookmarksPagedBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PunchBookmarksViewModel>>>;
    public getAllPunchesBookmarksPaged(body?: AllPunchesBookmarksPagedBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PunchBookmarksViewModel>>('post',`${this.basePath}/v1/External/allPunchesBookmarks/Paged`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPunchDynamicBookmarksByProjectTemplate(projectId?: number, templateId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BookmarkDataWapPunchReflectionViewModel>>;
    public getPunchDynamicBookmarksByProjectTemplate(projectId?: number, templateId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookmarkDataWapPunchReflectionViewModel>>>;
    public getPunchDynamicBookmarksByProjectTemplate(projectId?: number, templateId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookmarkDataWapPunchReflectionViewModel>>>;
    public getPunchDynamicBookmarksByProjectTemplate(projectId?: number, templateId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }
        if (templateId !== undefined && templateId !== null) {
            queryParameters = queryParameters.set('templateId', <any>templateId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BookmarkDataWapPunchReflectionViewModel>>('get',`${this.basePath}/v1/External/dynamicBookmarksByProjectTemplate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPunchFiltersByProjectTemplate(projectId?: number, templateId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PropertyFilterViewModel>>;
    public getPunchFiltersByProjectTemplate(projectId?: number, templateId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PropertyFilterViewModel>>>;
    public getPunchFiltersByProjectTemplate(projectId?: number, templateId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PropertyFilterViewModel>>>;
    public getPunchFiltersByProjectTemplate(projectId?: number, templateId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }
        if (templateId !== undefined && templateId !== null) {
            queryParameters = queryParameters.set('templateId', <any>templateId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PropertyFilterViewModel>>('get',`${this.basePath}/v1/External/filtersByProjectTemplate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemplatesByProjectId(projectId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<MasterViewModel>>;
    public getTemplatesByProjectId(projectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MasterViewModel>>>;
    public getTemplatesByProjectId(projectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MasterViewModel>>>;
    public getTemplatesByProjectId(projectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getTemplatesByProjectId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MasterViewModel>>('get',`${this.basePath}/v1/External/templatesByProject/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hasInspectionPunches(inspectionId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public hasInspectionPunches(inspectionId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public hasInspectionPunches(inspectionId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public hasInspectionPunches(inspectionId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inspectionId !== undefined && inspectionId !== null) {
            queryParameters = queryParameters.set('inspectionId', <any>inspectionId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/v1/External/hasInspectionPunches`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionId 
     * @param inspectionName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInspectionName(inspectionId?: number, inspectionName?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateInspectionName(inspectionId?: number, inspectionName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateInspectionName(inspectionId?: number, inspectionName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateInspectionName(inspectionId?: number, inspectionName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inspectionId !== undefined && inspectionId !== null) {
            queryParameters = queryParameters.set('inspectionId', <any>inspectionId);
        }
        if (inspectionName !== undefined && inspectionName !== null) {
            queryParameters = queryParameters.set('inspectionName', <any>inspectionName);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/v1/External/updateInspectionName`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
