/**
 * TR Application Master Data API
 * This is the TR Application Master Data API.
 *
 * OpenAPI spec version: v1
 * Contact: tr@tecnicasreunidas.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddDepartmentDto } from '../modelAMD/addDepartmentDto';
import { AddDisciplineDto } from '../modelAMD/addDisciplineDto';
import { AddInternalCompanyDto } from '../modelAMD/addInternalCompanyDto';
import { AddSpecialityDto } from '../modelAMD/addSpecialityDto';
import { BooleanEnvelope } from '../modelAMD/booleanEnvelope';
import { DepartmentSpecialitiesDtoIEnumerableEnvelope } from '../modelAMD/departmentSpecialitiesDtoIEnumerableEnvelope';
import { DisciplineDtoIEnumerableEnvelope } from '../modelAMD/disciplineDtoIEnumerableEnvelope';
import { EditDepartmentDto } from '../modelAMD/editDepartmentDto';
import { EditDisciplineDto } from '../modelAMD/editDisciplineDto';
import { EditInternalCompanyDto } from '../modelAMD/editInternalCompanyDto';
import { EditSpecialityDto } from '../modelAMD/editSpecialityDto';
import { InternalCompanyDepartmentsDtoIEnumerableEnvelope } from '../modelAMD/internalCompanyDepartmentsDtoIEnumerableEnvelope';
import { InternalCompanyDtoIEnumerableEnvelope } from '../modelAMD/internalCompanyDtoIEnumerableEnvelope';
import { StringEnvelope } from '../modelAMD/stringEnvelope';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InternalCompaniesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject('pathAMD') basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesDepartmentsGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<InternalCompanyDepartmentsDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesDepartmentsGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InternalCompanyDepartmentsDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesDepartmentsGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InternalCompanyDepartmentsDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesDepartmentsGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesDepartmentsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InternalCompanyDepartmentsDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/departments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesDepartmentsSpecialitiesGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<DepartmentSpecialitiesDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesDepartmentsSpecialitiesGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DepartmentSpecialitiesDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesDepartmentsSpecialitiesGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DepartmentSpecialitiesDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesDepartmentsSpecialitiesGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesDepartmentsSpecialitiesGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DepartmentSpecialitiesDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/departments/specialities`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesDisciplinesGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<DisciplineDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesDisciplinesGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DisciplineDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesDisciplinesGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DisciplineDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesDisciplinesGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesDisciplinesGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DisciplineDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/disciplines`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<InternalCompanyDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InternalCompanyDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InternalCompanyDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InternalCompanyDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param internalCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDelete(applicationId: number, internalCompanyId: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDelete(applicationId: number, internalCompanyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDelete(applicationId: number, internalCompanyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDelete(applicationId: number, internalCompanyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDelete.');
        }

        if (internalCompanyId === null || internalCompanyId === undefined) {
            throw new Error('Required parameter internalCompanyId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/${encodeURIComponent(String(internalCompanyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param internalCompanyId 
     * @param departmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDelete.');
        }

        if (internalCompanyId === null || internalCompanyId === undefined) {
            throw new Error('Required parameter internalCompanyId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDelete.');
        }

        if (departmentId === null || departmentId === undefined) {
            throw new Error('Required parameter departmentId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/${encodeURIComponent(String(internalCompanyId))}/departments/${encodeURIComponent(String(departmentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param internalCompanyId 
     * @param departmentId 
     * @param disciplineId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesDisciplineIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, disciplineId: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesDisciplineIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, disciplineId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesDisciplineIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, disciplineId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesDisciplineIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, disciplineId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesDisciplineIdDelete.');
        }

        if (internalCompanyId === null || internalCompanyId === undefined) {
            throw new Error('Required parameter internalCompanyId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesDisciplineIdDelete.');
        }

        if (departmentId === null || departmentId === undefined) {
            throw new Error('Required parameter departmentId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesDisciplineIdDelete.');
        }

        if (disciplineId === null || disciplineId === undefined) {
            throw new Error('Required parameter disciplineId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesDisciplineIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/${encodeURIComponent(String(internalCompanyId))}/departments/${encodeURIComponent(String(departmentId))}/disciplines/${encodeURIComponent(String(disciplineId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param internalCompanyId 
     * @param departmentId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesPut(applicationId: number, internalCompanyId: number, departmentId: number, body?: EditDisciplineDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesPut(applicationId: number, internalCompanyId: number, departmentId: number, body?: EditDisciplineDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesPut(applicationId: number, internalCompanyId: number, departmentId: number, body?: EditDisciplineDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesPut(applicationId: number, internalCompanyId: number, departmentId: number, body?: EditDisciplineDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesPut.');
        }

        if (internalCompanyId === null || internalCompanyId === undefined) {
            throw new Error('Required parameter internalCompanyId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesPut.');
        }

        if (departmentId === null || departmentId === undefined) {
            throw new Error('Required parameter departmentId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdDisciplinesPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/${encodeURIComponent(String(internalCompanyId))}/departments/${encodeURIComponent(String(departmentId))}/disciplines`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param internalCompanyId 
     * @param departmentId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesPut(applicationId: number, internalCompanyId: number, departmentId: number, body?: EditSpecialityDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesPut(applicationId: number, internalCompanyId: number, departmentId: number, body?: EditSpecialityDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesPut(applicationId: number, internalCompanyId: number, departmentId: number, body?: EditSpecialityDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesPut(applicationId: number, internalCompanyId: number, departmentId: number, body?: EditSpecialityDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesPut.');
        }

        if (internalCompanyId === null || internalCompanyId === undefined) {
            throw new Error('Required parameter internalCompanyId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesPut.');
        }

        if (departmentId === null || departmentId === undefined) {
            throw new Error('Required parameter departmentId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/${encodeURIComponent(String(internalCompanyId))}/departments/${encodeURIComponent(String(departmentId))}/specialities`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param internalCompanyId 
     * @param departmentId 
     * @param specialityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesSpecialityIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, specialityId: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesSpecialityIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, specialityId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesSpecialityIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, specialityId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesSpecialityIdDelete(applicationId: number, internalCompanyId: number, departmentId: number, specialityId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesSpecialityIdDelete.');
        }

        if (internalCompanyId === null || internalCompanyId === undefined) {
            throw new Error('Required parameter internalCompanyId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesSpecialityIdDelete.');
        }

        if (departmentId === null || departmentId === undefined) {
            throw new Error('Required parameter departmentId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesSpecialityIdDelete.');
        }

        if (specialityId === null || specialityId === undefined) {
            throw new Error('Required parameter specialityId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDepartmentIdSpecialitiesSpecialityIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/${encodeURIComponent(String(internalCompanyId))}/departments/${encodeURIComponent(String(departmentId))}/specialities/${encodeURIComponent(String(specialityId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param internalCompanyId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDisciplinesPost(applicationId: number, internalCompanyId: number, body?: Array<AddDisciplineDto>, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDisciplinesPost(applicationId: number, internalCompanyId: number, body?: Array<AddDisciplineDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDisciplinesPost(applicationId: number, internalCompanyId: number, body?: Array<AddDisciplineDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDisciplinesPost(applicationId: number, internalCompanyId: number, body?: Array<AddDisciplineDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDisciplinesPost.');
        }

        if (internalCompanyId === null || internalCompanyId === undefined) {
            throw new Error('Required parameter internalCompanyId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsDisciplinesPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/${encodeURIComponent(String(internalCompanyId))}/departments/disciplines`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param internalCompanyId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPost(applicationId: number, internalCompanyId: number, body?: Array<AddDepartmentDto>, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPost(applicationId: number, internalCompanyId: number, body?: Array<AddDepartmentDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPost(applicationId: number, internalCompanyId: number, body?: Array<AddDepartmentDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPost(applicationId: number, internalCompanyId: number, body?: Array<AddDepartmentDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPost.');
        }

        if (internalCompanyId === null || internalCompanyId === undefined) {
            throw new Error('Required parameter internalCompanyId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/${encodeURIComponent(String(internalCompanyId))}/departments`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param internalCompanyId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPut(applicationId: number, internalCompanyId: number, body?: EditDepartmentDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPut(applicationId: number, internalCompanyId: number, body?: EditDepartmentDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPut(applicationId: number, internalCompanyId: number, body?: EditDepartmentDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPut(applicationId: number, internalCompanyId: number, body?: EditDepartmentDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPut.');
        }

        if (internalCompanyId === null || internalCompanyId === undefined) {
            throw new Error('Required parameter internalCompanyId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/${encodeURIComponent(String(internalCompanyId))}/departments`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param internalCompanyId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsSpecialitiesPost(applicationId: number, internalCompanyId: number, body?: Array<AddSpecialityDto>, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsSpecialitiesPost(applicationId: number, internalCompanyId: number, body?: Array<AddSpecialityDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsSpecialitiesPost(applicationId: number, internalCompanyId: number, body?: Array<AddSpecialityDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsSpecialitiesPost(applicationId: number, internalCompanyId: number, body?: Array<AddSpecialityDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsSpecialitiesPost.');
        }

        if (internalCompanyId === null || internalCompanyId === undefined) {
            throw new Error('Required parameter internalCompanyId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesInternalCompanyIdDepartmentsSpecialitiesPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies/${encodeURIComponent(String(internalCompanyId))}/departments/specialities`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesPost(applicationId: number, body?: Array<AddInternalCompanyDto>, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesPost(applicationId: number, body?: Array<AddInternalCompanyDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesPost(applicationId: number, body?: Array<AddInternalCompanyDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesPost(applicationId: number, body?: Array<AddInternalCompanyDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdInternalcompaniesPut(applicationId: number, body?: EditInternalCompanyDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdInternalcompaniesPut(applicationId: number, body?: EditInternalCompanyDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesPut(applicationId: number, body?: EditInternalCompanyDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdInternalcompaniesPut(applicationId: number, body?: EditInternalCompanyDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdInternalcompaniesPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/internalcompanies`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
