import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TemplateTypeStoreService } from '../stores';


@Injectable()
export class TemplateTypeGuardService implements CanActivate {

  constructor(private templateType: TemplateTypeStoreService) { }

  canActivate(): Observable<boolean> {
    return new Observable((observator) => {
      const templateTypes = this.templateType.templateTypes;
      if (templateTypes) {
        observator.next(true);
        observator.complete();
      } else {
        this.templateType.loadTemplateTypes().subscribe((data) => {
          this.templateType.templateTypes = data;
          observator.next(true);
          observator.complete();
        });
      }
    });
  }
}
