import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleStoreService } from '../stores';


@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(private roleStore: RoleStoreService) { }

  canActivate(): Observable<boolean> {
    return new Observable((observator) => {
      const roles = this.roleStore.roles;
      if (roles) {
        observator.next(true);
        observator.complete();
      } else {
        this.roleStore.loadRoles().subscribe((data) => {
          this.roleStore.roles = data.result;
          observator.next(true);
          observator.complete();
        });
      }
    });
  }
}
