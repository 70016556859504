import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PolicyService } from '../services/policy.service';



@Injectable()
export class LoadedPoliciesGuardService implements CanActivate {

  constructor(private policyService: PolicyService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url = state.url;
    return new Observable((observator) => {
      this.policyService.loadPolicies().subscribe(() => {
        let hasPolicies = false;
        if (this.policyService.hasPolicies()) {
          hasPolicies = true;
        }
        observator.next(hasPolicies);
        observator.complete();
      });
    });
  }
}
