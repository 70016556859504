import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RolesService } from 'src/proxy/apiPS/api';
import { RoleDto } from 'src/proxy/modelPS/models';


@Injectable()
export class RoleStoreService {

    _roles$ = new BehaviorSubject<RoleDto[]>(null);

    constructor(private rolesPolicyService: RolesService) {
    }

    loadRoles() {
        return this.rolesPolicyService.apiRolesGet(Number(environment.applicationId));
    }

    get roles() {
        return this._roles$.value;
    }

    set roles(data: RoleDto[]) {
        this._roles$.next(data);
    }

    get roles$() {
        return this._roles$;
    }
}
