import { Injectable } from '@angular/core';
import { OAuthService, ReceivedTokens } from 'angular-oauth2-oidc';
import { from as fromPromise, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getClientSettings } from 'src/app/config/oidc-auth2-config';
import { EnvService } from '../deployment/env.service';
import { RolesVerification } from '../policy/roles';
import { LoginService } from './login.service';

export const URLKEY = 'prevAuthUrl';

@Injectable()
export class AuthService {

  public verificator: RolesVerification;
  private user: any = null;
  private user$: Observable<any> = null;

  constructor(
    private oauthService: OAuthService,
    private loginService: LoginService,
    private env: EnvService) {
    this.loadAuthService();
  }


  loadAuthService() {
    this.oauthService.initLoginFlow();
    this.oauthService.configure(getClientSettings(this.env));
    this.manageSilentRenew();
  }

  private manageSilentRenew() {
    this.oauthService.setupAutomaticSilentRefresh();
  }

  loadUser(): Observable<any> {
    if (!this.user && !this.user$) {
      this.user$ = fromPromise(this.oauthService.loadUserProfile()).pipe(
        map(user => {
          this.verificator = new RolesVerification(this.loginService);
          return user;
        })
      );
    } else if (this.user) {
      this.user$ = of(this.user);
    }
    return this.user$;
  }

  isLoggedIn(): boolean {
    return this.user != null && this.user.idClaims.exp !== undefined && this.user.idClaims.exp < new Date();
  }

  getClaims(): any {
    return this.user.idClaims;
  }

  getUser() {
    return this.user;
  }


  getAuthorizationHeaderValue(): string {
    if (!this.user) {
      return '';
    }
    return `Bearer ${this.user.accessToken}`;
  }

  getAuthorizationToken(): string {
    if (!this.user) {
      return '';
    }
    return `${this.user.accessToken}`;
  }

  startAuthentication(callbackUrl: string) {
    sessionStorage.setItem(URLKEY, callbackUrl);
    return this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  completeAuthentication(): Promise<any> {
    return this.oauthService.tryLogin({ onTokenReceived: (receivedTokens: ReceivedTokens) => {
      this.user = receivedTokens;
      this.verificator = new RolesVerification(this.loginService);
    } });
  }

  logOut() {
    window.location.href = `${this.env.identity}/Account/Logout`;
  }

}


