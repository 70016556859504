/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TemplateProjectCustomReportViewModel { 
    id?: number;
    createdBy?: string;
    projectId?: number;
    templateId?: number;
    name?: string;
    description?: string;
    allowedRoles?: Array<string>;
    query?: string;
    previousQuery?: string;
    templateProjectCustomReportSourceId?: number;
}