/**
 * TR Application Master Data API
 * This is the TR Application Master Data API.
 *
 * OpenAPI spec version: v1
 * Contact: tr@tecnicasreunidas.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddExternalCompanyDto } from '../modelAMD/addExternalCompanyDto';
import { BooleanEnvelope } from '../modelAMD/booleanEnvelope';
import { DataSourceRequest } from '../modelAMD/dataSourceRequest';
import { EditExternalCompanyDto } from '../modelAMD/editExternalCompanyDto';
import { ExternalCompanyDtoDataSourceResponseEnvelope } from '../modelAMD/externalCompanyDtoDataSourceResponseEnvelope';
import { StringEnvelope } from '../modelAMD/stringEnvelope';
import { SubcontractorDtoIEnumerableEnvelope } from '../modelAMD/subcontractorDtoIEnumerableEnvelope';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExternalCompaniesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject('pathAMD') basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param applicationId 
     * @param externalCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdExternalcompaniesExternalCompanyIdDelete(applicationId: number, externalCompanyId: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdExternalcompaniesExternalCompanyIdDelete(applicationId: number, externalCompanyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdExternalcompaniesExternalCompanyIdDelete(applicationId: number, externalCompanyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdExternalcompaniesExternalCompanyIdDelete(applicationId: number, externalCompanyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdExternalcompaniesExternalCompanyIdDelete.');
        }

        if (externalCompanyId === null || externalCompanyId === undefined) {
            throw new Error('Required parameter externalCompanyId was null or undefined when calling apiApplicationApplicationIdExternalcompaniesExternalCompanyIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/externalcompanies/${encodeURIComponent(String(externalCompanyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdExternalcompaniesGetallpaginatedPost(applicationId: number, body?: DataSourceRequest, observe?: 'body', reportProgress?: boolean): Observable<ExternalCompanyDtoDataSourceResponseEnvelope>;
    public apiApplicationApplicationIdExternalcompaniesGetallpaginatedPost(applicationId: number, body?: DataSourceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExternalCompanyDtoDataSourceResponseEnvelope>>;
    public apiApplicationApplicationIdExternalcompaniesGetallpaginatedPost(applicationId: number, body?: DataSourceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExternalCompanyDtoDataSourceResponseEnvelope>>;
    public apiApplicationApplicationIdExternalcompaniesGetallpaginatedPost(applicationId: number, body?: DataSourceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdExternalcompaniesGetallpaginatedPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ExternalCompanyDtoDataSourceResponseEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/externalcompanies/getallpaginated`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdExternalcompaniesPost(applicationId: number, body?: Array<AddExternalCompanyDto>, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdExternalcompaniesPost(applicationId: number, body?: Array<AddExternalCompanyDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdExternalcompaniesPost(applicationId: number, body?: Array<AddExternalCompanyDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdExternalcompaniesPost(applicationId: number, body?: Array<AddExternalCompanyDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdExternalcompaniesPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/externalcompanies`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdExternalcompaniesPut(applicationId: number, body?: EditExternalCompanyDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdExternalcompaniesPut(applicationId: number, body?: EditExternalCompanyDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdExternalcompaniesPut(applicationId: number, body?: EditExternalCompanyDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdExternalcompaniesPut(applicationId: number, body?: EditExternalCompanyDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdExternalcompaniesPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/externalcompanies`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param filterParameter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdExternalcompaniesSubcontractorsGet(applicationId: number, filterParameter?: string, observe?: 'body', reportProgress?: boolean): Observable<SubcontractorDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdExternalcompaniesSubcontractorsGet(applicationId: number, filterParameter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubcontractorDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdExternalcompaniesSubcontractorsGet(applicationId: number, filterParameter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubcontractorDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdExternalcompaniesSubcontractorsGet(applicationId: number, filterParameter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdExternalcompaniesSubcontractorsGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterParameter !== undefined && filterParameter !== null) {
            queryParameters = queryParameters.set('filterParameter', <any>filterParameter);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SubcontractorDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/externalcompanies/subcontractors`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
