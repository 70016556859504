/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MigrationService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public migrateSignAttachments(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public migrateSignAttachments(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public migrateSignAttachments(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public migrateSignAttachments(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/v1/Migration/SignAttachments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param signatureId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scaleSign(signatureId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public scaleSign(signatureId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public scaleSign(signatureId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public scaleSign(signatureId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (signatureId === null || signatureId === undefined) {
            throw new Error('Required parameter signatureId was null or undefined when calling scaleSign.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/v1/Migration/ScaleSign/${encodeURIComponent(String(signatureId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param startId 
     * @param endId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scaleSigns(startId: number, endId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public scaleSigns(startId: number, endId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public scaleSigns(startId: number, endId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public scaleSigns(startId: number, endId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (startId === null || startId === undefined) {
            throw new Error('Required parameter startId was null or undefined when calling scaleSigns.');
        }

        if (endId === null || endId === undefined) {
            throw new Error('Required parameter endId was null or undefined when calling scaleSigns.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/v1/Migration/ScaleSigns/${encodeURIComponent(String(startId))}/${encodeURIComponent(String(endId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
