import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { InspectionTypeService } from 'src/proxy';
import { StoreModel } from '../models/model.store';


@Injectable()
export class InspectionTypeStoreService {

    _inspectionType$ = new BehaviorSubject<StoreModel>(null);

    constructor(
        private inspectionTypeService: InspectionTypeService,
        private loginService: LoginService
        ) {
    }

    reloadInspectionTypes() {
        const projectTemplate = this.loginService.projectTemplate;
        if (projectTemplate) {
            this.loadInspectionTypes().pipe(take(1)).subscribe(data => {
                this.inspectionTypes = { data, projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
            });
        }
    }

    reloadInspectionTypesFromData(projectId: number, templateId: number, data: any[]) {
        this.inspectionTypes = { data, projectId, templateId };
    }

    loadInspectionTypes() {
        return this.inspectionTypeService.getInspectionTypeAll();
    }

    get inspectionTypes() {
        return this._inspectionType$.value;
    }

    set inspectionTypes(data: StoreModel) {
        this._inspectionType$.next(data);
    }

    get inspectionTypes$() {
        return this._inspectionType$;
    }
}