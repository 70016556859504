import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { Subject, Observable } from 'rxjs';
import { FileDownload } from './FileDownload';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http';
import { AlertService } from '../../alert/service/alert.service';
import { catchError } from 'rxjs/operators';
import { saveBlob } from './SaveData';


@Injectable()
export class DownloadingService {

  private subject = new Subject<FileDownload>();

  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService
  ) { }

  testDownloadComponent(fileName: string) {
    let percentDone = -1;
    const fileToDownload: FileDownload = {
      fileName,
      progress: percentDone
    };

    this.subject.next(fileToDownload);

    setTimeout(() => {
      percentDone = percentDone + 0.2;
      fileToDownload.progress = percentDone;
      this.subject.next(fileToDownload);
    }, 3000);
  }

  getFiles(): Observable<FileDownload> {
    return this.subject.asObservable();
  }

  downloadFileAsBlob(fileUrl: string, fileName: string, method: string = 'GET', body: string = ''): void {
    let headers = new HttpHeaders();

    if (body) {
      headers = headers.set('Content-Type', 'application/json');
    }
    const req = new HttpRequest(method, fileUrl, body, {
      reportProgress: true,
      responseType: 'blob',
      headers
    });

    const fileToDownload: FileDownload = {
      fileName,
      progress: -1
    };

    this.httpClient.request(req).pipe(
      catchError(err => {
        this.subject.next(fileToDownload);
        this.alertService.error(`Error downloading file ${fileName}`);
        return observableThrowError(err);
      })
    ).subscribe(event => {
      // Via this API, you get access to the raw event stream.
      // Look for download progress events.
      if (event.type === HttpEventType.DownloadProgress) {
        // This is an download progress event. Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        fileToDownload.progress = percentDone;
      } else if (event instanceof HttpResponse) {
        saveBlob(event.body as Blob, fileName);
        fileToDownload.progress = 100;
        this.subject.next(fileToDownload);
      }
    });

    this.subject.next(fileToDownload);
  }

}

function printDocument(doc) {
  // Wait until PDF is ready to print
  if (typeof doc.print === 'undefined') {
    setTimeout(() => { printDocument(doc); }, 1000);
  } else {
    doc.print();
  }
}
