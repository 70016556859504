import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { DisciplineStoreService } from '../stores';


@Injectable()
export class DisciplineGuardService implements CanActivate {

  constructor(private disciplineStore: DisciplineStoreService, private loginService: LoginService) { }


  canActivate(): Observable<boolean> {
    return new Observable((observator) => {
      const projectTemplate = this.loginService.projectTemplate;
      const disciplineData = this.disciplineStore.discipline;
      if (disciplineData && disciplineData.projectId === projectTemplate.project.id
        && disciplineData.templateId === projectTemplate.template.id) {
        observator.next(true);
        observator.complete();
      } else {
        this.disciplineStore.loadDiscipline().subscribe(data => {
          this.disciplineStore.discipline = { data,
            projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
          observator.next(true);
          observator.complete();
        });
      }
    });
  }
}
