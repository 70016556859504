/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InspectionTypeFlowSignDto } from './inspectionTypeFlowSignDto';
import { PunchRemarkDto } from './punchRemarkDto';
import { PunchSignatureDto } from './punchSignatureDto';
import { PunchStageStatusDto } from './punchStageStatusDto';
import { UserDetailDto } from './userDetailDto';

export interface PunchStageDto { 
    id?: number;
    punchId?: number;
    done?: boolean;
    reject?: boolean;
    rejectCount?: number;
    overridden?: boolean;
    createdBy?: string;
    createdAt?: Date;
    doneById?: string;
    doneByUser?: UserDetailDto;
    doneAt?: Date;
    lastModifiedAt?: Date;
    assignedTo?: UserDetailDto;
    inspectionTypeFlowSign?: InspectionTypeFlowSignDto;
    isSignable?: boolean;
    isRejectable?: boolean;
    isDisputable?: boolean;
    isHoldable?: boolean;
    isOverridable?: boolean;
    statusStage?: PunchStageStatusDto;
    punchRemark?: Array<PunchRemarkDto>;
    sign?: PunchSignatureDto;
    comment?: string;
}