export * from './alias-guard.service';
import { AliasGuardService } from './alias-guard.service';
export * from './role-guard.service';
import { RoleGuardService } from './role-guard.service';
export * from './actionBy-guard.service';
import { ActionByGuardService } from './actionBy-guard.service';
export * from './priority-guard.service';
import { PriorityGuardService } from './priority-guard.service';
export * from './discipline-guard.service';
import { DisciplineGuardService } from './discipline-guard.service';
export * from './tag-guard.service';
import { TagGuardService } from './tag-guard.service';
export * from './inspection-type-guard.service';
import { InspectionTypeGuardService } from './inspection-type-guard.service';
export * from './status-guard.service';
import { StatusGuardService } from './status-guard.service';
export * from './sources-guard.service';
import { SourcesGuardService } from './sources-guard.service';
export * from './live-reports-guard.service';
import { LiveReportsGuardService } from './live-reports-guard.service';
export * from './template-type-guard.service';
import { TemplateTypeGuardService } from './template-type-guard.service';
export * from './punches-templates-guard.service';
import { PunchesTemplatesGuardService } from './punches-templates-guard.service';

export const GUARDS_SHARED = [
    AliasGuardService,
    RoleGuardService,
    ActionByGuardService,
    PriorityGuardService,
    DisciplineGuardService,
    TagGuardService,
    InspectionTypeGuardService,
    StatusGuardService,
    SourcesGuardService,
    LiveReportsGuardService,
    TemplateTypeGuardService,
    PunchesTemplatesGuardService
];
