/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InvitationStatusDto } from './invitationStatusDto';
import { MemberStatusDto } from './memberStatusDto';
import { UserDetailDto } from './userDetailDto';

export interface InspectionMemberDto { 
    id?: number;
    memberStatus?: MemberStatusDto;
    invitationStatus?: InvitationStatusDto;
    user?: UserDetailDto;
    confirmed?: boolean;
}