/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MasterDto } from './masterDto';
import { MemberStatusDto } from './memberStatusDto';
import { UserViewDtoOut } from './userViewDtoOut';

export interface UserInspectionDto { 
    invitationStatus?: MemberStatusDto;
    memberStatus?: MasterDto;
    user?: UserViewDtoOut;
    confirmed?: boolean;
}