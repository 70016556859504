export const CTE_POLICY = 'policy';

export enum Policies {

  // Projects
  ProjectsView = 'projects/view',
  ProjectsDownloads = 'projects/download',
  ProjectsSync = 'projects/syncwbs',

  // Templates
  TemplatesView = 'templates/view',
  TemplatesAdd = 'templates/add',
  TemplatesDelete = 'templates/delete',
  TemplateUpdate = 'templates/update',
  TemplatesClone = 'templates/clone',

  // Master tables
  MasterTablesView = 'mastertables/view',

  // Live Reports
  LiveReports = 'livereports/view',

  // Maps
  MapsView = 'maps/view',
  MapsAdd = 'maps/add',
  MapsEdit = 'maps/edit',
  MapsDelete = 'maps/delete',

  // Users
  UsersView = 'users/view',
  UsersAdd = 'users/add',
  UsersDelete = 'users/delete',
  UsersEdit = 'users/edit',

  // Configuration
  ConfigurationView = 'configuration/view',
  ConfigurationEdit = 'configuration/edit',

  // Inspection
  InspectionsView = 'inspections/view',
  InspectionsImport = 'inspections/import',
  InspectionsNew = 'inspections/new',
  InspectionChange = 'inspections/changestatus',
  InspectionOverview = 'inspectionoverview/view',
  InspectionAttendees = 'inspectionattendees/view',
  InspectionWalkthrought = 'inspectionwalkthrough/view',
  InspectionConsolidationView = 'inspectionconsolidation/view',
  InspectionConsolidationImport = 'inspectionconsolidation/import',
  InspectionConsolidationApprove = 'inspectionconsolidation/approve',
  InspectionConsolidationDiscard = 'inspectionconsolidation/discard',
  InspectionConsolidationModify = 'inspectionconsolidation/modify',
  InspectionConsolidationExportExcel = 'inspectionconsolidation/exportexcel',
  InspectionConsolidationExportPdf = 'inspectionconsolidation/exportpdf',

  // Punches
  PunchesView = 'punches/view',
  PunchesTransfer = 'punches/transfer',
  PunchesBulkSign = 'punches/masssign',
  PunchesBulkReject = 'punches/massreject',
  PunchesBulkModify = 'punches/massmodify',
  PunchesBulkModifyFile = 'punches/massmodifyexcel',
  PunchesBulkModifyFileProcess = 'punches/massmodifyexcelprocess',
  PunchesExportAll = 'punches/exportall',
  PunchesExportExcel = 'punches/exportexcel',
  PunchesExportPdf = 'punches/exportpdf',
  PunchesDetailView = 'punchesdetails/view',
  PunchesDetailEdit = 'punchesdetails/edit',
  PunchesDetailSignatureView = 'punchesdetailssignatures/view',
  PunchesDetailSignatureAction = 'punchesdetailssignatures/sign',
  PunchesDetailSignatureReset = 'punchesdetailssignatures/reset',
  PunchesDetailMediaView = 'punchesdetailsmedia/view',
  PunchesDetailMediaAdd = 'punchesdetailsmedia/add',
  PunchesDetailRemarksView = 'punchesdetailsremarks/view',
  PunchesDetailRemarksAdd = 'punchesdetailsremarks/add',
  PunchesDetailTimelineView = 'punchesdetailstimeline/view',
  PunchesAdminModify = 'punches/adminmodify',

  // Reports
  WapReportsView = 'reports/view',
  BiReportsView = 'bireports/view',
  WapProcessView = 'processes/view',

}
