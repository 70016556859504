import { AuthConfig } from 'angular-oauth2-oidc';
import { EnvService } from '../core/deployment/env.service';

export function getClientSettings(env: EnvService): AuthConfig {

    return {
      issuer: env.identity,
      clientId: env.client_id,
      redirectUri: `${env.frontEnd}/auth-callback`,
      responseType: 'id_token token',
      scope: `openid profile WAndPApi Tr.Notification.Api.Scope Tr.AS Tr.PS Tr.AMD IdentityServerApi BIAccessPointApi `,
      silentRefreshRedirectUri: `${env.frontEnd}/silentrefresh.html`,
      postLogoutRedirectUri: env.frontEnd,
      logoutUrl: env.frontEnd,
      strictDiscoveryDocumentValidation: false,
      revocationEndpoint: `${env.identity}/connect/endsession`
    };
  }