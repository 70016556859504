import { Injectable } from '@angular/core';
import { BehaviorSubject, map, take } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { TemplateProjectConfigViewModel, TemplateService } from 'src/proxy';
import { StoreModel } from '../models/model.store';

export const MOCK_ALIAS = [];
export const KEY_ALIES = 'roles_aliases';

export interface Alias {
    id?: number;
    roleId?: number;
    roleCode?: string;
    alias?: string;
}

@Injectable()
export class AliasStoreService {

    _alias$ = new BehaviorSubject<StoreModel>(null);

    constructor(
        private loginService: LoginService,
        private templateService: TemplateService,
    ) {
    }

    reloadAlias() {
        const projectTemplate = this.loginService.projectTemplate;
        this.loadAlias()
            .pipe(take(1))
            .subscribe(data => {
            this.alias = { data, projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
        });
    }

    refreshAlias(data: TemplateProjectConfigViewModel | boolean) {
        const projectTemplate = this.loginService.projectTemplate;
        if (typeof data === 'boolean') {
            this.alias =  { data: [], projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
        } else {
            const alias = this.transformConfigToAlias(data);
            this.alias =  { data: alias, projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
        }
    }

    loadAlias() {
        const projectTemplate = this.loginService.projectTemplate;
        return this.templateService.getConfigurationsByTemplateId(projectTemplate.template.id)
            .pipe(
                map(a => {
                    const jsonAlias = a.find(alias => alias.key === KEY_ALIES);
                    return this.transformConfigToAlias(jsonAlias);
                })
            );
    }

    get alias() {
        return this._alias$.value;
    }

    set alias(data: StoreModel) {
        this._alias$.next(data);
    }

    get alias$() {
        return this._alias$;
    }

    private transformConfigToAlias(data?: any) {
        const jsonValues: Alias[] = data ? JSON.parse(data.value) : [];
        return jsonValues.map(res => ({ ...res, id: data ? data.id : 0 }));
    }
}