import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StatusService, StatusViewModel } from 'src/proxy';


@Injectable()
export class StatusStoreService {

    _status$ = new BehaviorSubject<StatusViewModel[]>(null);

    constructor(private statusSertice: StatusService) {
    }

    loadStatus() {
        return this.statusSertice.getAllStatus();
    }

    get status() {
        return this._status$.value;
    }

    set status(data: StatusViewModel[]) {
        this._status$.next(data);
    }

    get status$() {
        return this._status$;
    }
}
