/**
 * TR Notification API
 * This is the TR Notification API.
 *
 * OpenAPI spec version: v1
 * Contact: tr@tecnicasreunidas.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AlertViewModelIEnumerableEnvelope } from '../modelNotifications/alertViewModelIEnumerableEnvelope';
import { BooleanEnvelope } from '../modelNotifications/booleanEnvelope';
import { EnumViewModelIEnumerableEnvelope } from '../modelNotifications/enumViewModelIEnumerableEnvelope';
import { NotificationDistributionPropertyDto } from '../modelNotifications/notificationDistributionPropertyDto';
import { NotificationDistributionPropertyViewModel } from '../modelNotifications/notificationDistributionPropertyViewModel';
import { NotificationInViewModel } from '../modelNotifications/notificationInViewModel';
import { NotificationInViewModelEnvelope } from '../modelNotifications/notificationInViewModelEnvelope';
import { NotificationInViewModelIEnumerableEnvelope } from '../modelNotifications/notificationInViewModelIEnumerableEnvelope';
import { NotificationSendViewModel } from '../modelNotifications/notificationSendViewModel';
import { NotificationViewModelEnvelope } from '../modelNotifications/notificationViewModelEnvelope';
import { StringEnvelope } from '../modelNotifications/stringEnvelope';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NotificationService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject('env') basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationAlertsGet(body?: Array<NotificationDistributionPropertyViewModel>, observe?: 'body', reportProgress?: boolean): Observable<AlertViewModelIEnumerableEnvelope>;
    public notificationAlertsGet(body?: Array<NotificationDistributionPropertyViewModel>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AlertViewModelIEnumerableEnvelope>>;
    public notificationAlertsGet(body?: Array<NotificationDistributionPropertyViewModel>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AlertViewModelIEnumerableEnvelope>>;
    public notificationAlertsGet(body?: Array<NotificationDistributionPropertyViewModel>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AlertViewModelIEnumerableEnvelope>('get',`${this.basePath}/notification/alerts`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationAllPost(body?: Array<NotificationDistributionPropertyDto>, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<NotificationInViewModelIEnumerableEnvelope>;
    public notificationAllPost(body?: Array<NotificationDistributionPropertyDto>, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotificationInViewModelIEnumerableEnvelope>>;
    public notificationAllPost(body?: Array<NotificationDistributionPropertyDto>, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotificationInViewModelIEnumerableEnvelope>>;
    public notificationAllPost(body?: Array<NotificationDistributionPropertyDto>, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<NotificationInViewModelIEnumerableEnvelope>('post',`${this.basePath}/notification/all`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param notificationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationDelete(notificationId?: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public notificationDelete(notificationId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public notificationDelete(notificationId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public notificationDelete(notificationId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (notificationId !== undefined && notificationId !== null) {
            queryParameters = queryParameters.set('notificationId', <any>notificationId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/notification`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param notificationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationNotificationIdGet(notificationId: number, observe?: 'body', reportProgress?: boolean): Observable<NotificationViewModelEnvelope>;
    public notificationNotificationIdGet(notificationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotificationViewModelEnvelope>>;
    public notificationNotificationIdGet(notificationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotificationViewModelEnvelope>>;
    public notificationNotificationIdGet(notificationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notificationId === null || notificationId === undefined) {
            throw new Error('Required parameter notificationId was null or undefined when calling notificationNotificationIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<NotificationViewModelEnvelope>('get',`${this.basePath}/notification/${encodeURIComponent(String(notificationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param notificationId 
     * @param body 
     * @param sendEmail 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationNotificationIdSubscribePatch(notificationId: number, body?: Array<NotificationDistributionPropertyViewModel>, sendEmail?: boolean, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public notificationNotificationIdSubscribePatch(notificationId: number, body?: Array<NotificationDistributionPropertyViewModel>, sendEmail?: boolean, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public notificationNotificationIdSubscribePatch(notificationId: number, body?: Array<NotificationDistributionPropertyViewModel>, sendEmail?: boolean, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public notificationNotificationIdSubscribePatch(notificationId: number, body?: Array<NotificationDistributionPropertyViewModel>, sendEmail?: boolean, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notificationId === null || notificationId === undefined) {
            throw new Error('Required parameter notificationId was null or undefined when calling notificationNotificationIdSubscribePatch.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sendEmail !== undefined && sendEmail !== null) {
            queryParameters = queryParameters.set('sendEmail', <any>sendEmail);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('patch',`${this.basePath}/notification/${encodeURIComponent(String(notificationId))}/subscribe`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param notificationId 
     * @param body 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationNotificationIdUnsubscribePatch(notificationId: number, body?: Array<NotificationDistributionPropertyViewModel>, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public notificationNotificationIdUnsubscribePatch(notificationId: number, body?: Array<NotificationDistributionPropertyViewModel>, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public notificationNotificationIdUnsubscribePatch(notificationId: number, body?: Array<NotificationDistributionPropertyViewModel>, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public notificationNotificationIdUnsubscribePatch(notificationId: number, body?: Array<NotificationDistributionPropertyViewModel>, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notificationId === null || notificationId === undefined) {
            throw new Error('Required parameter notificationId was null or undefined when calling notificationNotificationIdUnsubscribePatch.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('patch',`${this.basePath}/notification/${encodeURIComponent(String(notificationId))}/unsubscribe`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationPost(body?: NotificationInViewModel, observe?: 'body', reportProgress?: boolean): Observable<NotificationInViewModelEnvelope>;
    public notificationPost(body?: NotificationInViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotificationInViewModelEnvelope>>;
    public notificationPost(body?: NotificationInViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotificationInViewModelEnvelope>>;
    public notificationPost(body?: NotificationInViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<NotificationInViewModelEnvelope>('post',`${this.basePath}/notification`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationPut(body?: NotificationInViewModel, observe?: 'body', reportProgress?: boolean): Observable<NotificationViewModelEnvelope>;
    public notificationPut(body?: NotificationInViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotificationViewModelEnvelope>>;
    public notificationPut(body?: NotificationInViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotificationViewModelEnvelope>>;
    public notificationPut(body?: NotificationInViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<NotificationViewModelEnvelope>('put',`${this.basePath}/notification`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationSendPost(body?: NotificationSendViewModel, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public notificationSendPost(body?: NotificationSendViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public notificationSendPost(body?: NotificationSendViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public notificationSendPost(body?: NotificationSendViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/notification/send`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationTypesGet(observe?: 'body', reportProgress?: boolean): Observable<EnumViewModelIEnumerableEnvelope>;
    public notificationTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnumViewModelIEnumerableEnvelope>>;
    public notificationTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnumViewModelIEnumerableEnvelope>>;
    public notificationTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EnumViewModelIEnumerableEnvelope>('get',`${this.basePath}/notification/types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
