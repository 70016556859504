import { ProjectTemplateViewModel } from 'src/proxy';
import { LoginService } from '../services/login.service';

export const ROL_ADMIN = 'administrator';

export class RolesVerification {

  constructor(private loginService: LoginService) { }


  verifyRoles(roles: string[]) {
    const projectTemplatePolicies = this.loginService.user;
    const selectedProjectTemplate = this.loginService.projectTemplate;

    if (!selectedProjectTemplate) {
      this.loginService.resetUserProjects();
    }

    if ((!projectTemplatePolicies || !selectedProjectTemplate) && !projectTemplatePolicies.role) {
      return false;
    }
    let rolesProjectTemplate = null;
    if (!projectTemplatePolicies.projectsTemplates || projectTemplatePolicies.projectsTemplates.length === 0) {
      rolesProjectTemplate = projectTemplatePolicies.role;
    } else {
      const authProjectTemplate: ProjectTemplateViewModel = projectTemplatePolicies.projectsTemplates
        .find(pt => pt.project.id === selectedProjectTemplate.project.id
        && pt.template.id === selectedProjectTemplate.template.id);

      rolesProjectTemplate = authProjectTemplate ? authProjectTemplate.role : '';
    }

    return this.stringOrArrayFind(roles, [rolesProjectTemplate]);
  }

  private stringOrArrayFind(roles: string[], profileRoles: string | string[]) {
    if (!!(profileRoles as string[]).find) {
      return !!(profileRoles as string[]).find(k => !!roles.find(r => r.toLocaleLowerCase() === k.toLocaleLowerCase()));
    } else {
      return !!roles.find(r => r.toLocaleLowerCase() === (profileRoles as string).toLocaleLowerCase());
    }

  }
}
