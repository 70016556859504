/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MasterViewModel } from './masterViewModel';

export interface PunchImportMultipleInspectionsPreviewViewModel { 
    template?: string;
    templateNamePreview?: string;
    inspections?: Array<MasterViewModel>;
    summary?: { [key: string]: string; };
    error?: string;
    isCorrect?: boolean;
}