import { Component, OnInit } from '@angular/core';
import { DownloadingService } from './service/downloading.service';
import { FileDownload } from './service/FileDownload';

@Component({
  moduleId: module.id,
  selector: 'app-downloading',
  template: `<div class="file-dialog-container">
              <div *ngFor="let file of files" class="alert alert-info">
                <div class="file-dialog-content">
                  <div class="file-dialog-content-text">{{getText(file)}} </div>
                    <div class="file-dialog-loader-container" *ngIf="file.progress < 0">
                      <div class="loader">Loading...</div>
                      <!-- <app-lds-ring></app-lds-ring> -->
                    </div>
                </div>
                <div class="file-dialog-progress-container">
                  <div class="progress " *ngIf="file.progress >= 0">
                    <div class="progress-bar progress-bar-striped progress-bar-animated {{getProgressType(file)}}"
                    role="progressbar" [attr.aria-valuenow]="getValueNow(file)"  aria-valuemin="0" aria-valuemax="100"
                    [style.width.%]="getValueNow(file)"></div>
                  </div>
                </div>
              </div>
            </div>`,
  styleUrls: ['./downloading.component.scss']
})

export class DownloadingComponent implements OnInit {
  files: FileDownload[] = [];

  constructor(private downloadService: DownloadingService) { }

  ngOnInit() {
    this.downloadService.getFiles().subscribe((file) => {
      if (!this.files.find(f => f === file)) {
        this.files.push(file);
      } else {
        this.removeFile(file);
      }
    });
  }

  getProgressType({ progress }: FileDownload) {
    return progress < 0 ? 'bg-info' : 'bg-success';
  }

  getValueNow({ progress }: FileDownload) {
    return progress < 0 ? 100 : progress;
  }

  removeFile(file: FileDownload) {
    this.files = this.files.filter(x => x !== file);
  }

  getText(file: FileDownload) {
    return file.progress < 0 ? `Generating file ${file.fileName}` : `Downloading ${file.fileName}: ${file.progress}%`;
  }

}
