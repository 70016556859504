/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DisciplineDto } from './disciplineDto';
import { ElementTypesDto } from './elementTypesDto';
import { ElementsDto } from './elementsDto';
import { NodeLevelsDto } from './nodeLevelsDto';

export interface ElementOutDto { 
    id?: number;
    code?: string;
    description?: string;
    nodeLevel?: NodeLevelsDto;
    elementType?: ElementTypesDto;
    discipline?: DisciplineDto;
    subElement?: Array<ElementsDto>;
    selected?: boolean;
    elementMatchId?: number;
}