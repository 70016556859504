/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElementOutDto } from './elementOutDto';
import { InspectionFileDto } from './inspectionFileDto';
import { InspectionMemberUnknownDto } from './inspectionMemberUnknownDto';
import { InspectionRemarkOutDto } from './inspectionRemarkOutDto';
import { InspectionStatusDto } from './inspectionStatusDto';
import { MasterDto } from './masterDto';
import { UserDto } from './userDto';
import { UserInspectionDto } from './userInspectionDto';
import { UserWalkOutDto } from './userWalkOutDto';
import { WbsDtoOut } from './wbsDtoOut';

export interface InspectionDetailDto { 
    id?: number;
    code?: string;
    description?: string;
    inspectionType?: MasterDto;
    organizer?: UserDto;
    items?: Array<ElementOutDto>;
    name?: string;
    information?: string;
    location?: string;
    tracked?: boolean;
    date?: Date;
    lastModifiedAt?: Date;
    inspectionState?: InspectionStatusDto;
    wbs?: WbsDtoOut;
    attendes?: Array<UserInspectionDto>;
    userWalks?: Array<UserWalkOutDto>;
    files?: Array<InspectionFileDto>;
    attendeesUnknown?: Array<InspectionMemberUnknownDto>;
    remarks?: Array<InspectionRemarkOutDto>;
}