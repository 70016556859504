import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { SourcesStoreService } from '../stores';


@Injectable()
export class SourcesGuardService implements CanActivate {

  constructor(private sourcesStore: SourcesStoreService) { }

  canActivate(): Observable<boolean> {
    return new Observable((observator) => {
      const sources = this.sourcesStore.sources;
      if (sources) {
        observator.next(true);
        observator.complete();
      } else {
        this.sourcesStore.loadReportSources().subscribe((data) => {
          this.sourcesStore.sources = data;
          observator.next(true);
          observator.complete();
        });
      }
    });
  }
}
