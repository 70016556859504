/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryDto } from './categoryDto';
import { InspectionTypeDto } from './inspectionTypeDto';
import { TemplateProjectConfigDto } from './templateProjectConfigDto';
import { TemplateProjectDto } from './templateProjectDto';
import { TemplatePunchCustomFieldDto } from './templatePunchCustomFieldDto';
import { TopicDto } from './topicDto';

export interface TemplateDto { 
    id?: number;
    code?: string;
    description?: string;
    topicsCount?: number;
    tags?: Array<string>;
    templateProject?: Array<TemplateProjectDto>;
    templateProjectConfig?: Array<TemplateProjectConfigDto>;
    templatePunchCustomField?: Array<TemplatePunchCustomFieldDto>;
    inspectionType?: Array<InspectionTypeDto>;
    category?: Array<CategoryDto>;
    topic?: Array<TopicDto>;
}