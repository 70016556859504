/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InspectionStatusDto } from './inspectionStatusDto';
import { PunchDto } from './punchDto';
import { UserDetailDto } from './userDetailDto';

export interface InspectionWalkDto { 
    id?: number;
    track?: string;
    createdAt?: Date;
    lastModifiedAt?: Date;
    closeDate?: Date;
    punch?: Array<PunchDto>;
    inspectionStatus?: InspectionStatusDto;
    createdBy?: string;
    userWalks?: UserDetailDto;
    fromImport?: boolean;
    readonly date?: Date;
}