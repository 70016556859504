/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MapsDto } from './mapsDto';
import { ProjectDto } from './projectDto';
import { TemplateDto } from './templateDto';

export interface TemplateProjectDto { 
    id?: number;
    code?: string;
    description?: string;
    project?: ProjectDto;
    template?: TemplateDto;
    _default?: boolean;
    useProjectWbs?: boolean;
    closed?: boolean;
    role?: string;
    visibleRole?: string;
    maps?: Array<MapsDto>;
}