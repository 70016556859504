import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appHexColor]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: AppHexColorDirective,
    multi: true
  }]
})
export class AppHexColorDirective implements Validator {
  CTE_HEX_COLOR_REGEX = /^#([0-9A-F]{3}){1,2}$/i;

  validate(control: AbstractControl): {[key: string]: any} | null {
    if (!control.value || control.value === '') {
      return null;
    }

    if (!this.CTE_HEX_COLOR_REGEX.test(control.value)) {
      return { hexColorInvalid: true };
    }
    return null; // return null if validation is passed.
  }
}
