export enum RoutesShortWandP {
  Login = 'login',
  Detail = 'detail',
  New = 'new',
  Inspections = 'inspections',
  Punches = 'punches',
  Consolidations = 'consolidations',
  UsersSetup = 'users-setup',
  AlertsSetup = 'alerts-setup',
  RootConfiguration = 'wap/configuration/',
  Templates = 'templates/',
  TemplatesDetail = 'project-detail',
  TemplatesGlobal = 'global-detail',
  MasterTables = 'master-tables',
  TemplatesGlobalCollections = 'global-collections',
  TemplatesProjectCollections = 'project-collections',
  Projects = 'projects',
  LiveReports = 'live-reports',
  Maps = 'maps',
  Reports = 'reports',
  Downloads = 'downloads',
  Process = 'process',
}

export enum TextMenu {
  Inspections = 'Inspections',
  Punches = 'Punches',
  Consolidations = 'Consolidations',
  Users = 'Users',
  MasterTables = 'Master Tables',
  Alerts = 'Alerts',
  Configuration = 'Configuration',
  Templates = 'Templates',
  Projects = 'Projects',
  LiveReports = 'Live Reports',
  Maps = 'Maps',
  Reports = 'BI Reports',
  Downloads = 'Downloads',
  Process = 'Processes',
}

export enum InvitationRoutesEnum {
  AcceptInvitation = 'AcceptInvitation'
}

export enum InvitationParamsEnum {
  key = 'key',
  accept = 'accept'
}

export enum PunchesDetailEnum {
  nodeId = 'nodeId',
  projectId = 'projectId',
  templateId = 'templateId',
  punchId = 'punchId'
}
