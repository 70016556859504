/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElementsViewModel } from './elementsViewModel';
import { PunchCustomFieldViewModel } from './punchCustomFieldViewModel';
import { PunchDetailInViewModel } from './punchDetailInViewModel';
import { TagsViewModel } from './tagsViewModel';
import { UserViewModel } from './userViewModel';

export interface V1PunchBody1 extends PunchDetailInViewModel { 
}