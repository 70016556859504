/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CategoryViewModel } from '../model/categoryViewModel';
import { InspectionTypeCategoriesBody } from '../model/inspectionTypeCategoriesBody';
import { InspectionTypeCategoriesBody1 } from '../model/inspectionTypeCategoriesBody1';
import { InspectionTypeCategoriesBody2 } from '../model/inspectionTypeCategoriesBody2';
import { InspectionTypeCategoriesBody3 } from '../model/inspectionTypeCategoriesBody3';
import { InspectionTypeFlowSignBody } from '../model/inspectionTypeFlowSignBody';
import { InspectionTypeFlowSignBody1 } from '../model/inspectionTypeFlowSignBody1';
import { InspectionTypeFlowSignBody2 } from '../model/inspectionTypeFlowSignBody2';
import { InspectionTypeFlowSignBody3 } from '../model/inspectionTypeFlowSignBody3';
import { InspectionTypeFlowSignBody4 } from '../model/inspectionTypeFlowSignBody4';
import { InspectionTypeFlowSignBody5 } from '../model/inspectionTypeFlowSignBody5';
import { InspectionTypeFlowSignBody6 } from '../model/inspectionTypeFlowSignBody6';
import { InspectionTypeFlowSignBody7 } from '../model/inspectionTypeFlowSignBody7';
import { InspectionTypeFlowSignViewModel } from '../model/inspectionTypeFlowSignViewModel';
import { InspectionTypeTopicsBody } from '../model/inspectionTypeTopicsBody';
import { InspectionTypeTopicsBody1 } from '../model/inspectionTypeTopicsBody1';
import { InspectionTypeTopicsBody2 } from '../model/inspectionTypeTopicsBody2';
import { InspectionTypeTopicsBody3 } from '../model/inspectionTypeTopicsBody3';
import { InspectionTypeViewModel } from '../model/inspectionTypeViewModel';
import { TopicViewModel } from '../model/topicViewModel';
import { V1InspectionTypeBody } from '../model/v1InspectionTypeBody';
import { V1InspectionTypeBody1 } from '../model/v1InspectionTypeBody1';
import { V1InspectionTypeBody2 } from '../model/v1InspectionTypeBody2';
import { V1InspectionTypeBody3 } from '../model/v1InspectionTypeBody3';
import { V1InspectionTypeBody4 } from '../model/v1InspectionTypeBody4';
import { V1InspectionTypeBody5 } from '../model/v1InspectionTypeBody5';
import { V1InspectionTypeBody6 } from '../model/v1InspectionTypeBody6';
import { V1InspectionTypeBody7 } from '../model/v1InspectionTypeBody7';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InspectionTypeService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param inspectionTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canEditSignsOrder(inspectionTypeId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public canEditSignsOrder(inspectionTypeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public canEditSignsOrder(inspectionTypeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public canEditSignsOrder(inspectionTypeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionTypeId === null || inspectionTypeId === undefined) {
            throw new Error('Required parameter inspectionTypeId was null or undefined when calling canEditSignsOrder.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/v1/InspectionType/${encodeURIComponent(String(inspectionTypeId))}/FlowSigns/CanEditSignsOrder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param singId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInspectionTypeFlowSign(singId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteInspectionTypeFlowSign(singId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteInspectionTypeFlowSign(singId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteInspectionTypeFlowSign(singId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (singId === null || singId === undefined) {
            throw new Error('Required parameter singId was null or undefined when calling deleteInspectionTypeFlowSign.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/InspectionType/FlowSign/${encodeURIComponent(String(singId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInspectionTypeInspectionType(inspectionTypeId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteInspectionTypeInspectionType(inspectionTypeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteInspectionTypeInspectionType(inspectionTypeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteInspectionTypeInspectionType(inspectionTypeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionTypeId === null || inspectionTypeId === undefined) {
            throw new Error('Required parameter inspectionTypeId was null or undefined when calling deleteInspectionTypeInspectionType.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/v1/InspectionType/${encodeURIComponent(String(inspectionTypeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFlowSignsByInspectionTypeId(inspectionTypeId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InspectionTypeFlowSignViewModel>>;
    public getFlowSignsByInspectionTypeId(inspectionTypeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InspectionTypeFlowSignViewModel>>>;
    public getFlowSignsByInspectionTypeId(inspectionTypeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InspectionTypeFlowSignViewModel>>>;
    public getFlowSignsByInspectionTypeId(inspectionTypeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionTypeId === null || inspectionTypeId === undefined) {
            throw new Error('Required parameter inspectionTypeId was null or undefined when calling getFlowSignsByInspectionTypeId.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<InspectionTypeFlowSignViewModel>>('get',`${this.basePath}/v1/InspectionType/${encodeURIComponent(String(inspectionTypeId))}/FlowSigns`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInspectionTypeAll(observe?: 'body', reportProgress?: boolean): Observable<Array<InspectionTypeViewModel>>;
    public getInspectionTypeAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InspectionTypeViewModel>>>;
    public getInspectionTypeAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InspectionTypeViewModel>>>;
    public getInspectionTypeAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<InspectionTypeViewModel>>('get',`${this.basePath}/v1/InspectionType/allInspectionsTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInspectionTypeById(inspectionTypeId: number, observe?: 'body', reportProgress?: boolean): Observable<InspectionTypeViewModel>;
    public getInspectionTypeById(inspectionTypeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionTypeViewModel>>;
    public getInspectionTypeById(inspectionTypeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionTypeViewModel>>;
    public getInspectionTypeById(inspectionTypeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionTypeId === null || inspectionTypeId === undefined) {
            throw new Error('Required parameter inspectionTypeId was null or undefined when calling getInspectionTypeById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionTypeViewModel>('get',`${this.basePath}/v1/InspectionType/${encodeURIComponent(String(inspectionTypeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInspectionTypeCategoriesById(inspectionTypeId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CategoryViewModel>>;
    public getInspectionTypeCategoriesById(inspectionTypeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CategoryViewModel>>>;
    public getInspectionTypeCategoriesById(inspectionTypeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CategoryViewModel>>>;
    public getInspectionTypeCategoriesById(inspectionTypeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionTypeId === null || inspectionTypeId === undefined) {
            throw new Error('Required parameter inspectionTypeId was null or undefined when calling getInspectionTypeCategoriesById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CategoryViewModel>>('get',`${this.basePath}/v1/InspectionType/${encodeURIComponent(String(inspectionTypeId))}/Categories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param signId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInspectionTypeFlowSignById(signId: number, observe?: 'body', reportProgress?: boolean): Observable<InspectionTypeFlowSignViewModel>;
    public getInspectionTypeFlowSignById(signId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionTypeFlowSignViewModel>>;
    public getInspectionTypeFlowSignById(signId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionTypeFlowSignViewModel>>;
    public getInspectionTypeFlowSignById(signId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (signId === null || signId === undefined) {
            throw new Error('Required parameter signId was null or undefined when calling getInspectionTypeFlowSignById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InspectionTypeFlowSignViewModel>('get',`${this.basePath}/v1/InspectionType/FlowSign/${encodeURIComponent(String(signId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inspectionTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInspectionTypeTopicsById(inspectionTypeId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TopicViewModel>>;
    public getInspectionTypeTopicsById(inspectionTypeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TopicViewModel>>>;
    public getInspectionTypeTopicsById(inspectionTypeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TopicViewModel>>>;
    public getInspectionTypeTopicsById(inspectionTypeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionTypeId === null || inspectionTypeId === undefined) {
            throw new Error('Required parameter inspectionTypeId was null or undefined when calling getInspectionTypeTopicsById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TopicViewModel>>('get',`${this.basePath}/v1/InspectionType/${encodeURIComponent(String(inspectionTypeId))}/Topics`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inspectionTypesSelectedCategories(body?: InspectionTypeCategoriesBody, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public inspectionTypesSelectedCategories(body?: InspectionTypeCategoriesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public inspectionTypesSelectedCategories(body?: InspectionTypeCategoriesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public inspectionTypesSelectedCategories(body?: InspectionTypeCategoriesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('put',`${this.basePath}/v1/InspectionType/Categories`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inspectionTypesSelectedTopics(body?: InspectionTypeTopicsBody, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public inspectionTypesSelectedTopics(body?: InspectionTypeTopicsBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public inspectionTypesSelectedTopics(body?: InspectionTypeTopicsBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public inspectionTypesSelectedTopics(body?: InspectionTypeTopicsBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('put',`${this.basePath}/v1/InspectionType/Topics`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postInspectionTypeFlowSign(body?: InspectionTypeFlowSignBody4, observe?: 'body', reportProgress?: boolean): Observable<InspectionTypeFlowSignViewModel>;
    public postInspectionTypeFlowSign(body?: InspectionTypeFlowSignBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionTypeFlowSignViewModel>>;
    public postInspectionTypeFlowSign(body?: InspectionTypeFlowSignBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionTypeFlowSignViewModel>>;
    public postInspectionTypeFlowSign(body?: InspectionTypeFlowSignBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionTypeFlowSignViewModel>('post',`${this.basePath}/v1/InspectionType/FlowSign`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postInspectionTypeInspectionType(body?: V1InspectionTypeBody4, observe?: 'body', reportProgress?: boolean): Observable<InspectionTypeViewModel>;
    public postInspectionTypeInspectionType(body?: V1InspectionTypeBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionTypeViewModel>>;
    public postInspectionTypeInspectionType(body?: V1InspectionTypeBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionTypeViewModel>>;
    public postInspectionTypeInspectionType(body?: V1InspectionTypeBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionTypeViewModel>('post',`${this.basePath}/v1/InspectionType`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putInspectionTypeFlowSign(body?: InspectionTypeFlowSignBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionTypeFlowSignViewModel>;
    public putInspectionTypeFlowSign(body?: InspectionTypeFlowSignBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionTypeFlowSignViewModel>>;
    public putInspectionTypeFlowSign(body?: InspectionTypeFlowSignBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionTypeFlowSignViewModel>>;
    public putInspectionTypeFlowSign(body?: InspectionTypeFlowSignBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionTypeFlowSignViewModel>('put',`${this.basePath}/v1/InspectionType/FlowSign`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putInspectionTypeInspectionType(body?: V1InspectionTypeBody, observe?: 'body', reportProgress?: boolean): Observable<InspectionTypeViewModel>;
    public putInspectionTypeInspectionType(body?: V1InspectionTypeBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InspectionTypeViewModel>>;
    public putInspectionTypeInspectionType(body?: V1InspectionTypeBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InspectionTypeViewModel>>;
    public putInspectionTypeInspectionType(body?: V1InspectionTypeBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InspectionTypeViewModel>('put',`${this.basePath}/v1/InspectionType`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
