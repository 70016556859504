/**
 * TR Policy Server API
 * This is the TR Policy Server API.
 *
 * OpenAPI spec version: v1
 * Contact: tr@tecnicasreunidas.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 /* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from './encoder';

import { Observable } from 'rxjs';

import { BooleanEnvelope } from '../modelPS/booleanEnvelope';
import { CreateRoleDto } from '../modelPS/createRoleDto';
import { EditRoleDto } from '../modelPS/editRoleDto';
import { Int64Envelope } from '../modelPS/int64Envelope';
import { RoleDtoEnvelope } from '../modelPS/roleDtoEnvelope';
import { RoleDtoIEnumerableEnvelope } from '../modelPS/roleDtoIEnumerableEnvelope';
import { SetPermissionsDto } from '../modelPS/setPermissionsDto';
import { StringEnvelope } from '../modelPS/stringEnvelope';

import { BASE_PATH, COLLECTION_FORMATS } from './variables';
import { Configuration } from './configuration';


@Injectable()
export class RolesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject('pathPS') basePath: string, @Optional() @Inject('configuration') configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param applicationId 
     * @param mr 
     * @param withPermissions 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRolesGet(applicationId?: number, mr?: boolean, withPermissions?: boolean, observe?: 'body', reportProgress?: boolean): Observable<RoleDtoIEnumerableEnvelope>;
    public apiRolesGet(applicationId?: number, mr?: boolean, withPermissions?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoleDtoIEnumerableEnvelope>>;
    public apiRolesGet(applicationId?: number, mr?: boolean, withPermissions?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoleDtoIEnumerableEnvelope>>;
    public apiRolesGet(applicationId?: number, mr?: boolean, withPermissions?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (applicationId !== undefined && applicationId !== null) {
            queryParameters = queryParameters.set('applicationId', <any>applicationId);
        }
        if (mr !== undefined && mr !== null) {
            queryParameters = queryParameters.set('mr', <any>mr);
        }
        if (withPermissions !== undefined && withPermissions !== null) {
            queryParameters = queryParameters.set('withPermissions', <any>withPermissions);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RoleDtoIEnumerableEnvelope>('get',`${this.basePath}/api/roles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRolesIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiRolesIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiRolesIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiRolesIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiRolesIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/roles/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRolesIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<RoleDtoEnvelope>;
    public apiRolesIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoleDtoEnvelope>>;
    public apiRolesIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoleDtoEnvelope>>;
    public apiRolesIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiRolesIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RoleDtoEnvelope>('get',`${this.basePath}/api/roles/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRolesIdPut(id: number, body?: EditRoleDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiRolesIdPut(id: number, body?: EditRoleDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiRolesIdPut(id: number, body?: EditRoleDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiRolesIdPut(id: number, body?: EditRoleDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiRolesIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/roles/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param roleId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRolesPermissionRoleIdPut(roleId: number, body?: SetPermissionsDto, observe?: 'body', reportProgress?: boolean): Observable<Int64Envelope>;
    public apiRolesPermissionRoleIdPut(roleId: number, body?: SetPermissionsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Int64Envelope>>;
    public apiRolesPermissionRoleIdPut(roleId: number, body?: SetPermissionsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Int64Envelope>>;
    public apiRolesPermissionRoleIdPut(roleId: number, body?: SetPermissionsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (roleId === null || roleId === undefined) {
            throw new Error('Required parameter roleId was null or undefined when calling apiRolesPermissionRoleIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Int64Envelope>('put',`${this.basePath}/api/roles/permission/${encodeURIComponent(String(roleId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRolesPost(body?: CreateRoleDto, observe?: 'body', reportProgress?: boolean): Observable<Int64Envelope>;
    public apiRolesPost(body?: CreateRoleDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Int64Envelope>>;
    public apiRolesPost(body?: CreateRoleDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Int64Envelope>>;
    public apiRolesPost(body?: CreateRoleDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Int64Envelope>('post',`${this.basePath}/api/roles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
