/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElementsDto } from './elementsDto';
import { InspectionFileDto } from './inspectionFileDto';
import { InspectionMemberDto } from './inspectionMemberDto';
import { InspectionMemberUnknownDto } from './inspectionMemberUnknownDto';
import { InspectionRemarkDto } from './inspectionRemarkDto';
import { InspectionStateDto } from './inspectionStateDto';
import { InspectionTypeDto } from './inspectionTypeDto';
import { InspectionWalkDto } from './inspectionWalkDto';
import { UserDetailDto } from './userDetailDto';

export interface InspectionDto { 
    id?: number;
    code?: string;
    name?: string;
    location?: string;
    information?: string;
    tracked?: boolean;
    schedule?: Date;
    expectedDate?: Date;
    createdAt?: Date;
    lastModifiedAt?: Date;
    inspectionType?: InspectionTypeDto;
    elements?: ElementsDto;
    inspectionState?: Array<InspectionStateDto>;
    inspectionMember?: Array<InspectionMemberDto>;
    inspectionMemberUnknown?: Array<InspectionMemberUnknownDto>;
    inspectionWalk?: Array<InspectionWalkDto>;
    inspectionFile?: Array<InspectionFileDto>;
    inspectionRemark?: Array<InspectionRemarkDto>;
    items?: Array<ElementsDto>;
    organizer?: UserDetailDto;
}