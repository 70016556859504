/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryDto } from './categoryDto';
import { DepartmentActionDto } from './departmentActionDto';
import { EntryTypeDto } from './entryTypeDto';
import { ForecastClosingTypeDto } from './forecastClosingTypeDto';
import { PriorityDto } from './priorityDto';
import { PunchLogChangeDto } from './punchLogChangeDto';
import { PunchRemarkDto } from './punchRemarkDto';
import { RemarkTypesDto } from './remarkTypesDto';
import { SubcontractorDto } from './subcontractorDto';
import { UserDetailDto } from './userDetailDto';

export interface PunchLogDto { 
    id?: number;
    createdBy?: UserDetailDto;
    punchId?: number;
    entryDate?: Date;
    expectedClosingDate?: Date;
    isException?: boolean;
    closingResponsibleEntity?: string;
    commentary?: string;
    category?: CategoryDto;
    deparmentAction?: DepartmentActionDto;
    entryType?: EntryTypeDto;
    entryUser?: UserDetailDto;
    forecastClosingType?: ForecastClosingTypeDto;
    priority?: PriorityDto;
    remarkType?: RemarkTypesDto;
    punchRemark?: PunchRemarkDto;
    userGuest?: string;
    changes?: Array<PunchLogChangeDto>;
    subContractor?: SubcontractorDto;
}