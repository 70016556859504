import { Directive, TemplateRef, ViewContainerRef, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Directive({
  selector: '[appShowByRoute]'
})
export class ShowByRouteDirective implements OnInit{

  routes: string[] = [];

  constructor(private templateRef: TemplateRef<any>,
              private router: Router,
              private viewContainer: ViewContainerRef)
    { }

  @Input() set appShowByRoute(routesAdmitted: string[]) {
      this.routes = routesAdmitted;
    }

  ngOnInit() {
    const url = this.router.url;
    this.checkRoutes(url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.checkRoutes(event.url);
      }
    });
  }

  checkRoutes(url: string) {
    this.viewContainer.clear();
    if (this.checkUrl(url)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private checkUrl(url: string) {
    let res = false;
    if (this.routes.filter(r => r.indexOf(':') !== -1).length > 0) {
      this.routes.forEach(route => {
        if (url.indexOf(route) !== -1) {
          res =  true;
        }
        if (route.indexOf(':') !== -1 && url.indexOf(route.replace(':', '')) !== -1) {
          res =  true;
        }
      });
    }
    return res || this.routes.indexOf(url) !== -1;
  }
}
