import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { PriorityService } from 'src/proxy';
import { StoreModel } from '../models/model.store';


@Injectable()
export class PriorityStoreService {

    _priorities$ = new BehaviorSubject<StoreModel>(null);

    constructor(
        private prioritiesService: PriorityService,
        private loginService: LoginService
        ) {
    }

    reloadPriority() {
        const projectTemplate = this.loginService.projectTemplate;
        this.loadPriority().pipe(take(1)).subscribe(data => {
            this.priority = { data, projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
        });
    }

    loadPriority() {
        return this.prioritiesService.getAllPriority();
    }

    get priority() {
        return this._priorities$.value;
    }

    set priority(data: StoreModel) {
        this._priorities$.next(data);
    }

    get priority$() {
        return this._priorities$;
    }
}