/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InspectionTypeFlowTypeDto } from './inspectionTypeFlowTypeDto';
import { SignActionDto } from './signActionDto';
import { StatusDto } from './statusDto';

export interface InspectionTypeFlowSignActionDto { 
    id?: number;
    code?: string;
    description?: string;
    signAction?: SignActionDto;
    inspectionTypeFlowType?: InspectionTypeFlowTypeDto;
    status?: StatusDto;
}