/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConfiguredReportViewModel } from './configuredReportViewModel';

export interface ConfiguredReportViewModelDataSourceResult { 
    data?: Array<ConfiguredReportViewModel>;
    total?: number;
    currentPage?: number;
    pageCount?: number;
    pageSize?: number;
}