import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';

import { from, Observable } from 'rxjs';
import * as _ from 'lodash-es';
import { PolicyDto } from 'src/proxy/modelPS/models';
import { PoliciesService } from 'src/proxy/apiPS/api';
import { environment } from 'src/environments/environment';

@Injectable()
export class PolicyService {
  private policiesEnum: PolicyDto[] = [];
  private policies$: Observable<PolicyDto[]> = null;

  constructor(private policiesService: PoliciesService) {
    // this.loadPolicies();
  }

  get policies() {
    return this.policiesEnum;
  }

  hasPolicies () {
    return this.policiesEnum && this.policiesEnum.length > 0;
  }

  loadPolicies() {
    this.policies$ = from(this.policiesService.apiPoliciesGet(parseInt(environment.applicationId, 0)))
      .pipe(map(policies => this.policiesEnum = policies.result));
    return this.policies$;
  }

  getRolesFromPolicies(names: string[]): string[] {
    const claims: string[] = _.flattenDeep(this.policies.filter(p => names.includes(p.name))
                  .map(p => p.requiredClaimValues));
    if (!claims) {
      return [];
    }
    return [...new Set(claims)];
  }


}


