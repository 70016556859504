import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from './service/loader.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  loading: boolean;

  private destroyed$ = new Subject();

  constructor(private service: LoaderService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.service.loadingS.pipe(takeUntil(this.destroyed$)).subscribe(loading => {
      this.loading = loading;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

}
