/**
 * TR Auth Server API
 * This is the TR Auth Server API.
 *
 * OpenAPI spec version: v1
 * Contact: tr@tecnicasreunidas.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthorizeDto } from '../modelAS/authorizeDto';
import { AuthorizeGenericDto } from '../modelAS/authorizeGenericDto';
import { BooleanEnvelope } from '../modelAS/booleanEnvelope';
import { DataSourceRequest } from '../modelAS/dataSourceRequest';
import { EditAuthorizationDto } from '../modelAS/editAuthorizationDto';
import { EditAuthorizationPropertiesDto } from '../modelAS/editAuthorizationPropertiesDto';
import { Int64Envelope } from '../modelAS/int64Envelope';
import { RegisterAndAuthorizeUserDto } from '../modelAS/registerAndAuthorizeUserDto';
import { RegisterAndAuthorizeUserGenericDto } from '../modelAS/registerAndAuthorizeUserGenericDto';
import { RegisterUserDto } from '../modelAS/registerUserDto';
import { StringEnvelope } from '../modelAS/stringEnvelope';
import { UserAuthorizationDtoEnvelope } from '../modelAS/userAuthorizationDtoEnvelope';
import { UserAuthorizationResumeDtoDataSourceResponseEnvelope } from '../modelAS/userAuthorizationResumeDtoDataSourceResponseEnvelope';
import { UserAuthorizationResumeDtoIEnumerableEnvelope } from '../modelAS/userAuthorizationResumeDtoIEnumerableEnvelope';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthorizationService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject('pathAS') basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationAuthorizeKeyPost(body?: AuthorizeGenericDto, observe?: 'body', reportProgress?: boolean): Observable<Int64Envelope>;
    public apiAuthorizationAuthorizeKeyPost(body?: AuthorizeGenericDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Int64Envelope>>;
    public apiAuthorizationAuthorizeKeyPost(body?: AuthorizeGenericDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Int64Envelope>>;
    public apiAuthorizationAuthorizeKeyPost(body?: AuthorizeGenericDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Int64Envelope>('post',`${this.basePath}/api/authorization/authorize/key`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationAuthorizePost(body?: AuthorizeDto, observe?: 'body', reportProgress?: boolean): Observable<Int64Envelope>;
    public apiAuthorizationAuthorizePost(body?: AuthorizeDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Int64Envelope>>;
    public apiAuthorizationAuthorizePost(body?: AuthorizeDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Int64Envelope>>;
    public apiAuthorizationAuthorizePost(body?: AuthorizeDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Int64Envelope>('post',`${this.basePath}/api/authorization/authorize`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationEditauthpropertiesPut(body?: EditAuthorizationPropertiesDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiAuthorizationEditauthpropertiesPut(body?: EditAuthorizationPropertiesDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiAuthorizationEditauthpropertiesPut(body?: EditAuthorizationPropertiesDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiAuthorizationEditauthpropertiesPut(body?: EditAuthorizationPropertiesDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/authorization/editauthproperties`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationGetapplicationusersGet(applicationId?: number, observe?: 'body', reportProgress?: boolean): Observable<UserAuthorizationResumeDtoIEnumerableEnvelope>;
    public apiAuthorizationGetapplicationusersGet(applicationId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserAuthorizationResumeDtoIEnumerableEnvelope>>;
    public apiAuthorizationGetapplicationusersGet(applicationId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserAuthorizationResumeDtoIEnumerableEnvelope>>;
    public apiAuthorizationGetapplicationusersGet(applicationId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (applicationId !== undefined && applicationId !== null) {
            headers = headers.set('applicationId', String(applicationId));
        }

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserAuthorizationResumeDtoIEnumerableEnvelope>('get',`${this.basePath}/api/authorization/getapplicationusers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationGetapplicationuserspaginatedPost(body?: DataSourceRequest, applicationId?: number, observe?: 'body', reportProgress?: boolean): Observable<UserAuthorizationResumeDtoDataSourceResponseEnvelope>;
    public apiAuthorizationGetapplicationuserspaginatedPost(body?: DataSourceRequest, applicationId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserAuthorizationResumeDtoDataSourceResponseEnvelope>>;
    public apiAuthorizationGetapplicationuserspaginatedPost(body?: DataSourceRequest, applicationId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserAuthorizationResumeDtoDataSourceResponseEnvelope>>;
    public apiAuthorizationGetapplicationuserspaginatedPost(body?: DataSourceRequest, applicationId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (applicationId !== undefined && applicationId !== null) {
            headers = headers.set('applicationId', String(applicationId));
        }

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserAuthorizationResumeDtoDataSourceResponseEnvelope>('post',`${this.basePath}/api/authorization/getapplicationuserspaginated`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationGetappuserauthorizationsGet(applicationId?: number, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<UserAuthorizationDtoEnvelope>;
    public apiAuthorizationGetappuserauthorizationsGet(applicationId?: number, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserAuthorizationDtoEnvelope>>;
    public apiAuthorizationGetappuserauthorizationsGet(applicationId?: number, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserAuthorizationDtoEnvelope>>;
    public apiAuthorizationGetappuserauthorizationsGet(applicationId?: number, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (applicationId !== undefined && applicationId !== null) {
            queryParameters = queryParameters.set('applicationId', <any>applicationId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserAuthorizationDtoEnvelope>('get',`${this.basePath}/api/authorization/getappuserauthorizations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationGetuserauthorizationsGet(applicationId?: number, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<UserAuthorizationDtoEnvelope>;
    public apiAuthorizationGetuserauthorizationsGet(applicationId?: number, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserAuthorizationDtoEnvelope>>;
    public apiAuthorizationGetuserauthorizationsGet(applicationId?: number, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserAuthorizationDtoEnvelope>>;
    public apiAuthorizationGetuserauthorizationsGet(applicationId?: number, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (applicationId !== undefined && applicationId !== null) {
            headers = headers.set('applicationId', String(applicationId));
        }
        if (userId !== undefined && userId !== null) {
            headers = headers.set('userId', String(userId));
        }

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserAuthorizationDtoEnvelope>('get',`${this.basePath}/api/authorization/getuserauthorizations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationMultipleunauthorizeDelete(body?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiAuthorizationMultipleunauthorizeDelete(body?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiAuthorizationMultipleunauthorizeDelete(body?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiAuthorizationMultipleunauthorizeDelete(body?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/authorization/multipleunauthorize`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationPut(body?: EditAuthorizationDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiAuthorizationPut(body?: EditAuthorizationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiAuthorizationPut(body?: EditAuthorizationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiAuthorizationPut(body?: EditAuthorizationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/authorization`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationRegisterPost(body?: RegisterUserDto, observe?: 'body', reportProgress?: boolean): Observable<Int64Envelope>;
    public apiAuthorizationRegisterPost(body?: RegisterUserDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Int64Envelope>>;
    public apiAuthorizationRegisterPost(body?: RegisterUserDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Int64Envelope>>;
    public apiAuthorizationRegisterPost(body?: RegisterUserDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Int64Envelope>('post',`${this.basePath}/api/authorization/register`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationRegisterandauthorizeKeyPost(body?: RegisterAndAuthorizeUserGenericDto, observe?: 'body', reportProgress?: boolean): Observable<Int64Envelope>;
    public apiAuthorizationRegisterandauthorizeKeyPost(body?: RegisterAndAuthorizeUserGenericDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Int64Envelope>>;
    public apiAuthorizationRegisterandauthorizeKeyPost(body?: RegisterAndAuthorizeUserGenericDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Int64Envelope>>;
    public apiAuthorizationRegisterandauthorizeKeyPost(body?: RegisterAndAuthorizeUserGenericDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Int64Envelope>('post',`${this.basePath}/api/authorization/registerandauthorize/key`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationRegisterandauthorizePost(body?: RegisterAndAuthorizeUserDto, observe?: 'body', reportProgress?: boolean): Observable<Int64Envelope>;
    public apiAuthorizationRegisterandauthorizePost(body?: RegisterAndAuthorizeUserDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Int64Envelope>>;
    public apiAuthorizationRegisterandauthorizePost(body?: RegisterAndAuthorizeUserDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Int64Envelope>>;
    public apiAuthorizationRegisterandauthorizePost(body?: RegisterAndAuthorizeUserDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Int64Envelope>('post',`${this.basePath}/api/authorization/registerandauthorize`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param authorizationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationUnauthorizeDelete(authorizationId?: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiAuthorizationUnauthorizeDelete(authorizationId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiAuthorizationUnauthorizeDelete(authorizationId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiAuthorizationUnauthorizeDelete(authorizationId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (authorizationId !== undefined && authorizationId !== null) {
            headers = headers.set('authorizationId', String(authorizationId));
        }

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/authorization/unauthorize`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userId 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationUnregisterDelete(userId?: string, applicationId?: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiAuthorizationUnregisterDelete(userId?: string, applicationId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiAuthorizationUnregisterDelete(userId?: string, applicationId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiAuthorizationUnregisterDelete(userId?: string, applicationId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (userId !== undefined && userId !== null) {
            headers = headers.set('userId', String(userId));
        }
        if (applicationId !== undefined && applicationId !== null) {
            headers = headers.set('applicationId', String(applicationId));
        }

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/authorization/unregister`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userId 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationUnregisterappuserDelete(userId?: string, applicationId?: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiAuthorizationUnregisterappuserDelete(userId?: string, applicationId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiAuthorizationUnregisterappuserDelete(userId?: string, applicationId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiAuthorizationUnregisterappuserDelete(userId?: string, applicationId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (applicationId !== undefined && applicationId !== null) {
            queryParameters = queryParameters.set('applicationId', <any>applicationId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/authorization/unregisterappuser`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthorizationUsersApplicationIdPost(applicationId: number, body?: DataSourceRequest, observe?: 'body', reportProgress?: boolean): Observable<UserAuthorizationResumeDtoDataSourceResponseEnvelope>;
    public apiAuthorizationUsersApplicationIdPost(applicationId: number, body?: DataSourceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserAuthorizationResumeDtoDataSourceResponseEnvelope>>;
    public apiAuthorizationUsersApplicationIdPost(applicationId: number, body?: DataSourceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserAuthorizationResumeDtoDataSourceResponseEnvelope>>;
    public apiAuthorizationUsersApplicationIdPost(applicationId: number, body?: DataSourceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiAuthorizationUsersApplicationIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserAuthorizationResumeDtoDataSourceResponseEnvelope>('post',`${this.basePath}/api/authorization/users/${encodeURIComponent(String(applicationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
