/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DisciplineDto } from './disciplineDto';
import { InspectionDto } from './inspectionDto';
import { InspectionWalkDto } from './inspectionWalkDto';
import { MasterDto } from './masterDto';
import { OriginatorDto } from './originatorDto';
import { PunchCustomFieldDto } from './punchCustomFieldDto';
import { PunchItemDto } from './punchItemDto';
import { PunchLogDto } from './punchLogDto';
import { PunchMediaDto } from './punchMediaDto';
import { PunchRemarkDto } from './punchRemarkDto';
import { PunchStageDto } from './punchStageDto';
import { PunchTagsDto } from './punchTagsDto';
import { StatusDto } from './statusDto';
import { SubcontractorDto } from './subcontractorDto';
import { TopicDto } from './topicDto';
import { UserDetailDto } from './userDetailDto';

export interface PunchDto { 
    id?: number;
    code?: string;
    description?: string;
    altitude?: number;
    location?: string;
    pdfPositionX?: number;
    pdfPositionY?: number;
    pdfPage?: number;
    punchCoordinator?: string;
    rejectCount?: number;
    inspectionWalk?: InspectionWalkDto;
    originator?: OriginatorDto;
    userOriginator?: UserDetailDto;
    topic?: TopicDto;
    discipline?: DisciplineDto;
    status?: StatusDto;
    punchMedia?: Array<PunchMediaDto>;
    punchItem?: Array<PunchItemDto>;
    punchLogs?: Array<PunchLogDto>;
    punchTags?: Array<PunchTagsDto>;
    punchRemark?: Array<PunchRemarkDto>;
    punchFields?: Array<PunchCustomFieldDto>;
    punchCustomFields?: Array<PunchCustomFieldDto>;
    inspection?: InspectionDto;
    createdAt?: Date;
    lastModifiedAt?: Date;
    user?: UserDetailDto;
    unit?: MasterDto;
    subSystem?: MasterDto;
    createdBy?: string;
    punchStage?: Array<PunchStageDto>;
    isMyPunch?: boolean;
    assignedToId?: string;
    isRejected?: boolean;
    isSignable?: boolean;
    pendingFlowSignStepId?: number;
    isRejectable?: boolean;
    reasonNotSignable?: string;
    onDispute?: boolean;
    subContractorId?: number;
    subContractor?: SubcontractorDto;
    departmentActionId?: number;
    departmentActionCode?: string;
    departmentAction?: string;
    signFlowRole?: string;
    closed?: boolean;
    isLocked?: boolean;
    commentary?: string;
}