/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InspectionViewModel } from './inspectionViewModel';

export interface PunchImportPreviewViewModel { 
    inspectionId?: number;
    inspection?: InspectionViewModel;
    template?: string;
    templateNamePreview?: string;
    summary?: { [key: string]: number; };
    error?: string;
}