import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DEFAULT_ROUTE_APP } from '../models/const';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { CoreComponent } from './core.component';
import { IsLoggedGuardService } from './guards/is-logged-guard.service';
import { LoadedPoliciesGuardService } from './guards/loaded-policies-guard.service';
import { PolicyGuardService } from './guards/policy-guard.service';
import { URLKEY } from './services/auth.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: getRedirectTo(),
    pathMatch: 'full'
  }, {
    path: '',
    canActivate: [IsLoggedGuardService],
    children: [
      {
        path: 'wap',
        canActivate: [LoadedPoliciesGuardService],
        canActivateChild: [PolicyGuardService],
        component: CoreComponent,
        children: [
          {
            path: '',
            canActivateChild: [],
            children: [
              {
                path: 'configuration',
                canActivate: [],
                loadChildren: () => import('../wap/configuration/configuration.module').then(mod => mod.ConfigurationModule)
              }
            ]
          }
        ]
      }
    ]
  },
  {
      path: 'auth-callback',
      component: AuthCallbackComponent
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class CoreRoutingModule { }

function getRedirectTo(): string {
  const urlRedirected = sessionStorage.getItem(URLKEY);
  // if (environment.dev_mode) {
  //     return ROUTE_LOGIN;
  // } else {
  return urlRedirected ? urlRedirected : DEFAULT_ROUTE_APP;
  // }
}
