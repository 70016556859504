import {
  HttpErrorResponse,
  HttpEvent, HttpHandler,

  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ROUTES_RELOAD, ROUTES_WITH_PROJECT } from 'src/app/models/const';
import { environment } from 'src/environments/environment';
import { AuthService, URLKEY } from '../services/auth.service';
import { LoginService } from '../services/login.service';
import { ManageErrorsService } from '../services/manage-errors.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public authService: AuthService,
    private loginService: LoginService,
    private manageErrorsService: ManageErrorsService,
    private route: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const projectId = /*(this.checkCurrentUrlHasProject(this.route.url) && */
    this.loginService.projectTemplate ? String(this.loginService.projectTemplate.project.id) : '';
    const templateId =/* (this.checkCurrentUrlHasProject(this.route.url) &&*/
    this.loginService.projectTemplate ? String(this.loginService.projectTemplate.template.id) : '';
    request = request.clone({
      setHeaders: {
        'User-Identity': this.loginService.devMode && this.loginService.user ? this.loginService.user.id : '',
        'Project-Id': projectId,
        'Template-Id': templateId,
        Key: (projectId && templateId) ? `${projectId}$${templateId}` : '',
        Authorization: this.authService.getAuthorizationHeaderValue(),
        ApplicationId: environment.applicationId.toString(),
        projectId,
        applicationId: environment.applicationId,
      }
    });
    return next.handle(request)
    .pipe(
      catchError((err: HttpErrorResponse): Observable<HttpEvent<any>> => {
        if (!this.loginService.devMode && (err.status === 401 || err.status === 403)) {
          const urlRedirected = sessionStorage.getItem(URLKEY);
          this.authService.startAuthentication(urlRedirected ? urlRedirected : this.route.url);
        }
        this.manageErrorsService.showError(err);
        return throwError(err);
      })
    );
  }

  private checkCurrentUrlHasProject(url: string) {
    const allRoutes = [...ROUTES_RELOAD, ...ROUTES_WITH_PROJECT];
    let res = false;
    const urlTrimmed = url.indexOf('?') === -1 ? url : url.substr(0, url.indexOf('?'));
    if (allRoutes.filter(r => r.indexOf(':') !== -1).length > 0) {
      allRoutes.forEach(route => {
        if (route.indexOf(urlTrimmed) !== -1) {
          res =  true;
        }
        if (route.indexOf(':') !== -1 && urlTrimmed.indexOf(route.replace(':', '')) !== -1) {
          res =  true;
        }
      });
    }
    return res || allRoutes.indexOf(urlTrimmed) !== -1;
  }


}
