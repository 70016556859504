/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryViewModel } from './categoryViewModel';
import { ElementsViewModel } from './elementsViewModel';
import { InspectionTypeViewModel } from './inspectionTypeViewModel';
import { InspectionViewModel } from './inspectionViewModel';
import { InspectionWalkInfoViewModel } from './inspectionWalkInfoViewModel';
import { MasterViewModel } from './masterViewModel';
import { OriginatorDto } from './originatorDto';
import { PunchCustomFieldViewModel } from './punchCustomFieldViewModel';
import { PunchDetailViewModel } from './punchDetailViewModel';
import { PunchLogViewModel } from './punchLogViewModel';
import { PunchMediaViewModel } from './punchMediaViewModel';
import { PunchRemarkViewModel } from './punchRemarkViewModel';
import { PunchStageViewModel } from './punchStageViewModel';
import { StatusViewModel } from './statusViewModel';
import { SubcontractorDto } from './subcontractorDto';
import { TagsViewModel } from './tagsViewModel';
import { UserInfoViewModel } from './userInfoViewModel';

export interface V1PunchBody7 extends PunchDetailViewModel { 
}