import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LiveReportsStoreService } from '../stores';


@Injectable()
export class LiveReportsGuardService implements CanActivate {

  constructor(private liveReportsStore: LiveReportsStoreService) { }

  canActivate(): Observable<boolean> {
    return new Observable((observator) => {
      const liveReports = this.liveReportsStore.liveReports;
      if (liveReports) {
        observator.next(true);
        observator.complete();
      } else {
        this.liveReportsStore.loadLiveReports().subscribe((data) => {
          this.liveReportsStore.liveReports = data;
          observator.next(true);
          observator.complete();
        });
      }
    });
  }
}
