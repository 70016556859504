/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MasterDto } from './masterDto';

export interface TemplateProjectReportViewModel { 
    id?: number;
    requestUser?: string;
    requestDate?: Date;
    startDate?: Date;
    endDate?: Date;
    generationStatus?: MasterDto;
    reportType?: MasterDto;
    reportName?: string;
    isFiltered?: boolean;
    templateProjectId?: number;
    errorDescription?: string;
}