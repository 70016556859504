export * from './alias.store';
import { AliasStoreService } from './alias.store';
export * from './roles.store';
import { RoleStoreService } from './roles.store';
export * from './actionBy.store';
import { ActionByStoreService } from './actionBy.store';
export * from './priority.store';
import { PriorityStoreService } from './priority.store';
export * from './discipline.store';
import { DisciplineStoreService } from './discipline.store';
export * from './tags.store';
import { TagsStoreService } from './tags.store';
export * from './inspection-types.store';
import { InspectionTypeStoreService } from './inspection-types.store';
export * from './status.store';
import { StatusStoreService } from './status.store';
export * from './sources.store';
import { SourcesStoreService } from './sources.store';
export * from './live-reports.store';
import { LiveReportsStoreService } from './live-reports.store';
export * from './template-type.store';
import { TemplateTypeStoreService } from './template-type.store';
export * from './punches-templates.store';
import { PunchesTemplatesStoreService } from './punches-templates.store';

export const SHARED_STORES = [
    AliasStoreService,
    RoleStoreService,
    ActionByStoreService,
    PriorityStoreService,
    DisciplineStoreService,
    TagsStoreService,
    InspectionTypeStoreService,
    StatusStoreService,
    SourcesStoreService,
    LiveReportsStoreService,
    TemplateTypeStoreService,
    PunchesTemplatesStoreService
];
