import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PoliciesService } from 'src/proxy/apiPS/api';
import { AlertModule } from '../shared/alert/alert.module';
import { DirectivesModule } from '../shared/directives/directives.module';
import { FileDownloadModule } from '../shared/file-download/file-download.module';
import { DownloadingService } from '../shared/file-download/service/downloading.service';
import { CoreRoutingModule } from './core-routing.module';
import { CoreComponent } from './core.component';
import { IsLoggedGuardService } from './guards/is-logged-guard.service';
import { LoadedPoliciesGuardService } from './guards/loaded-policies-guard.service';
import { PolicyGuardService } from './guards/policy-guard.service';
import { HttpCancelInterceptor } from './interceptors/cancel.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthService } from './services/auth.service';
import { HttpCancelService } from './services/cancel.service';
import { ManageErrorsService } from './services/manage-errors.service';
import { PolicyService } from './services/policy.service';
import { GUARDS } from './store/guards';
import { STORES } from './store/stores';

@NgModule({
  declarations: [
    CoreComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    CoreRoutingModule,
    HttpClientModule,
    AlertModule,
    FileDownloadModule,
    DirectivesModule // Remove if you don't use directives in this module
  ],
  exports: [
    RouterModule
  ],
  providers: [
    IsLoggedGuardService,
    PolicyGuardService,
    LoadedPoliciesGuardService,
    PolicyService,
    PoliciesService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
      multi: true
    },
    HttpCancelService,
    DownloadingService,
    ManageErrorsService,
    ...STORES,
    ...GUARDS,
  ]
})
export class CoreModule { }
