/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PunchBulkModifyViewModel { 
    punchesId?: Array<number>;
    description?: string;
    categoryId?: number;
    disciplineId?: number;
    topicId?: number;
    originatorId?: number;
    subcontractor?: number;
    departmentActionId?: number;
    commentary?: string;
    items?: Array<number>;
    labels?: Array<number>;
}