import { Directive, TemplateRef, ViewContainerRef, Input, OnInit } from '@angular/core';
import { canAccess } from 'src/app/core/policy/policies';
import { AuthService } from 'src/app/core/services/auth.service';
import { PolicyService } from 'src/app/core/services/policy.service';

@Directive({
  selector: '[appShowAuthed]'
})
export class ShowAuthedDirective implements OnInit{

  policies: string[] = [];

  constructor(private templateRef: TemplateRef<any>,
              private authService: AuthService,
              private policyService: PolicyService,
              private viewContainerRef: ViewContainerRef)
    { }

  @Input() set appShowAuthed(policiesAdmitted: string[]) {
      this.policies = policiesAdmitted;
    }

    visible = false;

  ngOnInit() {
    if (!this.authService.getUser() || !this.authService.getUser().idClaims) {
      this.hide();
      return;
    }
    if (canAccess(this.policies, this.authService.verificator,
                  this.policyService.getRolesFromPolicies.bind(this.policyService))) {
      // Creo que siempre será false
      if (!this.visible) {
        this.visible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.hide();
    }
  }

  private hide() {
    this.visible = false;
    this.viewContainerRef.clear();
  }
}
