import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AlertService } from 'src/app/shared/alert/service/alert.service';
import { ERROR_CODE_BAD_REQUEST,ERROR_CODE_CONFLICT, ERROR_CODE_SERVER_ERROR, ErrorCodeInspections } from 'src/app/wap/configuration/inspections/models/error.code';
import { mergeMap as _observableMergeMap } from 'rxjs/operators';
import { EnvService } from '../deployment/env.service';

@Injectable()
export class ManageErrorsService {

  constructor(private alertService: AlertService, private env: EnvService) { }

  public showError(err: HttpErrorResponse) {
    const message = this.manageCustomErrors(err);

    if (message) {
      this.alertService.error(message);
    }
    else {
      const responseBlob =
      err instanceof HttpResponse ? err.body : (err as any).error instanceof Blob
      ? (err as any).error : undefined;
      const res = blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return of(_responseText);
      }));
      res.subscribe(r => {
        this.alertService.warn(r);
      });
    }
  }

  public showWarning(err: HttpErrorResponse) {
    this.alertService.warn('****'.concat(err.message));
  }

  public showInfo(err: HttpErrorResponse) {
    this.alertService.info('****'.concat(err.message));
  }

  public showSuccess(err: HttpErrorResponse) {
    this.alertService.success('****'.concat(err.message));
  }

  private manageCustomErrors(error: any): null | string
  {
    switch (error.status) {
      case ERROR_CODE_BAD_REQUEST:
        if (error.error && error.error.ClassName === ErrorCodeInspections.PendingPunches)
        {
          this.alertService.warn(`There is a problem in this action: ${error.error.Message}`);
          return null;
        }
        return this.env.dev_mode ? JSON.stringify(error) : error.message;
      case ERROR_CODE_CONFLICT:
        this.alertService.warn(this.env.dev_mode ? JSON.stringify(error) : error.error.detail);
        return null;
      case ERROR_CODE_SERVER_ERROR:
        this.alertService.error(this.env.dev_mode ? JSON.stringify(error) : error.error.detail);
        return null;
      default:
        return this.env.dev_mode ? JSON.stringify(error) : error.message;
    }
  }
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
      if (!blob) {
          observer.next('');
          observer.complete();
      } else {
          const reader = new FileReader();
          reader.onload = event => {
              observer.next((<any>event.target).result);
              observer.complete();
          };
          reader.readAsText(blob);
      }
  });
}