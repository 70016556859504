let aElement;
(function init() {
    aElement = (document as any).createElement('a');
    document.body.appendChild(aElement);
    aElement.style.display = 'none';
})();

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

export function saveDataFile(file: File) {
    const url = window.URL.createObjectURL(file);
    aElement.href = url;
    aElement.download = file.name;
    aElement.click();
    window.URL.revokeObjectURL(url);
}

export function saveBlob(localBlob: Blob, fileName: string) {
    if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(localBlob, fileName);
    } else {
        const url = window.URL.createObjectURL(localBlob);
        aElement.href = url;
        aElement.download = fileName;
        aElement.click();
        window.URL.revokeObjectURL(url);
    }
}

export function saveDataByUrl(fileUrl: string, fileName: string, mimeType?: string) {
    const blob = new Blob([fileUrl], { type: mimeType ? mimeType : 'octet/stream' });
    saveBlob(blob, fileName);
}


