import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProjectTemplateViewModel, UserService, UserViewModel } from 'src/proxy';
import { AuthorizationService } from 'src/proxy/apiAS/api';
import { AuthorizeGenericDto, UserAuthorizationDtoEnvelope } from 'src/proxy/modelAS/models';
import { COOKIE_PROJECTID, COOKIE_TEMPLATEID, getAuthorizationPropertiesByIds } from '../policy/authorization-properties.utils';
import { ROL_ADMIN } from '../policy/roles';
import { CTE_DEV_MODE, CTE_PROJECT, CTE_PROJECTS, CTE_PROJECT_NOTIFICATION, CTE_USER } from './coockies.user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  changesProject$ = new Subject();

  fullScreen = false;

  constructor(private userService: UserService, private userAuthService: AuthorizationService) { }

  get user() {
    return JSON.parse(localStorage.getItem(CTE_USER));
  }

  set user(user: UserViewModel) {
    localStorage.setItem(CTE_USER, JSON.stringify(user));
  }

  get projectTemplate() {
    const project = localStorage.getItem(CTE_PROJECT);
    return project ? JSON.parse(project) : null;
  }

  set projectTemplate(project: ProjectTemplateViewModel) {
    let jsonPrj = null;
    if (project) {
      jsonPrj = JSON.stringify(project);
      localStorage.setItem(CTE_PROJECT_NOTIFICATION, JSON.stringify(project?.project?.id));
      localStorage.setItem(CTE_PROJECT, jsonPrj);
      localStorage.setItem(COOKIE_PROJECTID, project.project.id.toString());
      localStorage.setItem(COOKIE_TEMPLATEID, project.template.id.toString());
    } else {
      localStorage.removeItem(CTE_PROJECT);
    }
    this.changesProject$.next(project);
  }

  get projectTemplates() {
    const projects = localStorage.getItem(CTE_PROJECTS);
    return projects ? JSON.parse(localStorage.getItem(CTE_PROJECTS)) : [];
  }

  set projectTemplates(projects: ProjectTemplateViewModel[]) {
    localStorage.setItem(CTE_PROJECTS, JSON.stringify(projects));
    if (!this.projectTemplate || !this.templateExistsInProjects(projects)) {
      this.projectTemplate = this.getDefaultProject(projects);
    }
  }

  get devMode() {
    return JSON.parse(localStorage.getItem(CTE_DEV_MODE));
  }

  set devMode(devMode: boolean) {
    localStorage.setItem(CTE_DEV_MODE, JSON.stringify(devMode));
  }

  set fullScreenMode(fullScreenMode: boolean) {
    this.fullScreen = fullScreenMode;
  }

  get fullScreenMode() {
    return this.fullScreen;
  }

  setProjectTemplateUser(projectId: number, templateId: number) {
    let projectTemplate;
    if (templateId) {
      projectTemplate = this.projectTemplates.find(pt => pt.project.id === projectId
        && pt.template.id === templateId);
    } else {
      projectTemplate = this.projectTemplates.find(pt => pt.project.id === projectId);
        // && pt.template.hcsLinked); // TODO change with label??
    }
    this.projectTemplate = projectTemplate ? projectTemplate : this.projectTemplates.filter(pt => pt.project.id === projectId)[0];
    this.changesProject$.next(this.projectTemplate);
  }

  // Mock: Implement im application
  loginDevMode(selectedUser: UserViewModel) {
    this.user = selectedUser;
    this.projectTemplates = selectedUser.projectsTemplates;
    return of(true);
  }

  loadUsers() {
    return this.userService.getAllUsers();
  }

  loadUserConnected() {
    return this.userService.getUserLogin();
  }

  loadUserTrIdentityById(userId: string): Observable<UserAuthorizationDtoEnvelope> {
    return this.userAuthService.apiAuthorizationGetuserauthorizationsGet(Number(environment.applicationId), userId);
  }

  async resetUserProjects(projectId: number = null, templateId: number = null) {
    // TODO Delete Roles temp
    if (projectId && templateId) {
      const userTrIdentity = await this.userAuthService
          .apiAuthorizationGetuserauthorizationsGet(Number(environment.applicationId), this.user.id).toPromise();
      const authGenericKey: AuthorizeGenericDto = {
        userId: this.user.id,
        applicationUserAuthId: userTrIdentity.result.applicationUserAuthId,
        authorizationProperties: getAuthorizationPropertiesByIds(projectId, templateId),
        roles: [ROL_ADMIN]
      };
      const auth = await this.userAuthService.apiAuthorizationAuthorizeKeyPost(authGenericKey).toPromise();
    }

    if (this.user) {
        this.projectTemplates = this.user.projectsTemplates;
        this.projectTemplate = this.getDefaultProject(this.user.projectsTemplates);
    }
  }

  private getDefaultProject(projects: ProjectTemplateViewModel[]) {
    const selectedProject = this.projectTemplate;
    if (!selectedProject) {
      return projects[0];
    } else {
      const selected = JSON.stringify(selectedProject);
      const saved = JSON.stringify(projects.find(p => p.project.id === selectedProject.project.id &&
                                      p.template.id === selectedProject.template.id));
      return selected === saved ? selectedProject : projects[0];
    }
  }

  private templateExistsInProjects(templates) {
    const templateSelected = this.projectTemplate;
    if (!templateSelected) {
      return false;
    }
    return templates.filter(pt => pt.project.id === templateSelected.project.id
                                        && pt.template.id === templateSelected.template.id).length > 0;
  }
}
