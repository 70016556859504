/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ProcessStatus } from './processStatus';
import { ProcessType } from './processType';

export interface ProcessDto { 
    id?: number;
    priority?: number;
    inputData?: string;
    outputData?: string;
    processType?: ProcessType;
    status?: ProcessStatus;
    createdBy?: string;
    createdUserName?: string;
    enqueueDate?: Date;
    startDate?: Date;
    endDate?: Date;
    projectId?: number;
    projectCode?: string;
    templateId?: number;
    templateCode?: string;
    error?: string;
    hangfireId?: string;
}