import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DigitOnlyDirective } from './digit-only/digit-only.directive';
import { ClickOutsidedDirective } from './click-outside/click-outside.directive';
import { AppHexColorDirective } from './hexColor/hex-color.directive';
import { PasteDirective } from './paste/paste.directive';
import { DraggableDirective } from './drag/draggable.directive';
import { ShowAuthedDirective } from './show-authored/show-authed.directive';
import { ShowByRouteDirective } from './show-by-route/show-by-route.directive';
import { SwitchCasesDirective } from './switch-case/switch-case.directive';

@NgModule({
  declarations: [
    PasteDirective,
    DigitOnlyDirective,
    ClickOutsidedDirective,
    AppHexColorDirective,
    DraggableDirective,
    ShowAuthedDirective,
    ShowByRouteDirective,
    SwitchCasesDirective
  ],
  imports: [CommonModule],
  exports: [
    PasteDirective,
    DigitOnlyDirective,
    ClickOutsidedDirective,
    AppHexColorDirective,
    DraggableDirective,
    ShowAuthedDirective,
    ShowByRouteDirective,
    SwitchCasesDirective
  ],
  providers: []
})
export class DirectivesModule {}
