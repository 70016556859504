/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MapsDto { 
    id?: number;
    code?: string;
    description?: string;
    name?: string;
    version?: number;
    projectId?: number;
    templateId?: number;
    mimeType?: string;
    imageFileServer?: string;
    location?: string;
    zoomLevel?: number;
    file?: string;
    _default?: boolean;
    createdAt?: Date;
    lastModifiedAt?: Date;
}