/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MapsViewModel } from './mapsViewModel';
import { MasterViewModel } from './masterViewModel';
import { TemplateInViewModel } from './templateInViewModel';

export interface ProjectTemplateViewModel { 
    project?: MasterViewModel;
    template?: TemplateInViewModel;
    maps?: Array<MapsViewModel>;
    _default?: boolean;
    useProjectWbs?: boolean;
    closed?: boolean;
    role?: string;
    visibleRole?: string;
}