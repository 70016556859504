/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EmbedToken } from './embedToken';

export interface PbiReportViewModel { 
    id?: number;
    modifiedBy?: string;
    createdBy?: string;
    pbiId?: string;
    pbiName?: string;
    pbiDescription?: string;
    pbiModified?: Date;
    pbiCreated?: Date;
    pbiModifiedBy?: string;
    pbiCreatedBy?: string;
    datasetId?: string;
    version?: string;
    embedUrl?: string;
    webUrl?: string;
    embedToken?: EmbedToken;
}