/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExpiredTimeDto } from './expiredTimeDto';
import { ExpiredTypeDto } from './expiredTypeDto';
import { InspectionTypeFlowSignActionDto } from './inspectionTypeFlowSignActionDto';
import { UserDetailDto } from './userDetailDto';

export interface InspectionTypeFlowSignDto { 
    id?: number;
    inspectionTypeId?: number;
    inspectionTypeFlowSignAction?: Array<InspectionTypeFlowSignActionDto>;
    user?: UserDetailDto;
    role?: string;
    expiredType?: ExpiredTypeDto;
    expiredTime?: ExpiredTimeDto;
    expiredValue?: number;
    sequence?: number;
    override?: boolean;
}