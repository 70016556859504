/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TemplatePunchCustomFieldViewModel { 
    id?: number;
    code?: string;
    description?: string;
    order?: number;
    name?: string;
    front?: string;
    visual?: string;
    type?: string;
    options?: string;
    templateId?: number;
    notAllowedRoles?: Array<string>;
    edit?: boolean;
}