/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DepartmentActionInspectionTypeDto } from './departmentActionInspectionTypeDto';
import { InspectionTypeCategoryDto } from './inspectionTypeCategoryDto';
import { InspectionTypeFlowSignDto } from './inspectionTypeFlowSignDto';
import { InspectionTypeTopicDto } from './inspectionTypeTopicDto';

export interface InspectionTypeDto { 
    id?: number;
    code?: string;
    description?: string;
    tags?: Array<string>;
    name?: string;
    connector?: string;
    templateId?: number;
    inspectionTypeCategory?: Array<InspectionTypeCategoryDto>;
    inspectionTypeTopic?: Array<InspectionTypeTopicDto>;
    inspectionTypeFlowSign?: Array<InspectionTypeFlowSignDto>;
    departmentActionInspectionType?: Array<DepartmentActionInspectionTypeDto>;
}