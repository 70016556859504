import { RolesVerification } from './roles';

export function canAccess(policyName: string | string[], verificator: RolesVerification,
                          getRolesFromPolicies: (policyName: string[]) => string[]) {
    if (!policyName || !verificator) {
      return true;
    }
    let policies = [];
    if (typeof policyName === 'string') {
      policies = [policyName];
    } else {
      policies = [...policyName];
    }
    const roles = getRolesFromPolicies(policies);
    const res = verificator.verifyRoles(roles);
    return res;
}
