import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { ReportService, StatusViewModel, TemplateProjectCustomReportSourceViewModel } from 'src/proxy';


@Injectable()
export class SourcesStoreService {

    _sources$ = new BehaviorSubject<TemplateProjectCustomReportSourceViewModel[]>(null);

    constructor(private reportsService: ReportService) {
    }

    loadReportSources() {
        return this.reportsService.getAvailableCustomReportSources([]);
    }

    loadSourcesFromProjectTemplate(projectTemplates: any[]) {
        return this.reportsService.getAvailableCustomReportSources(projectTemplates.map(pt => ({projectId: pt.id.split('$')[0],  templateId:  pt.id.split('$')[1]})));
    }

    get sources() {
        return this._sources$.value;
    }

    set sources(data: StatusViewModel[]) {
        this._sources$.next(data);
    }

    get sources$() {
        return this._sources$;
    }
}
