import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, take } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { DepartmentActionService } from 'src/proxy';
import { StoreModel } from '../models/model.store';


@Injectable()
export class ActionByStoreService {

    _actionBy$ = new BehaviorSubject<StoreModel>(null);

    constructor(
        private departmentActionService: DepartmentActionService,
        private loginService: LoginService
        ) {
    }

    reloadActionBy() {
        const projectTemplate = this.loginService.projectTemplate;
        this.loadActionBy().pipe(take(1)).subscribe(data => {
            this.actionBy = { data, projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
        });
    }

    loadActionBy() {
        return this.departmentActionService.getAllDepartmentAction();
    }

    get actionBy() {
        return this._actionBy$.value;
    }

    set actionBy(data: StoreModel) {
        this._actionBy$.next(data);
    }

    get actionBy$() {
        return this._actionBy$;
    }
}