/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DisciplineDto } from './disciplineDto';
import { ElementTypesDto } from './elementTypesDto';
import { NodeLevelsDto } from './nodeLevelsDto';
import { ProjectDto } from './projectDto';
import { TemplateDto } from './templateDto';

export interface ElementsDto { 
    id?: number;
    code?: string;
    description?: string;
    nodeLevel?: NodeLevelsDto;
    elementTypeId?: number;
    elementType?: ElementTypesDto;
    active?: boolean;
    priority?: number;
    remarks?: string;
    discipline?: DisciplineDto;
    project?: ProjectDto;
    template?: TemplateDto;
    childs?: Array<ElementsDto>;
    selected?: boolean;
    elementMatchId?: number;
}