/**
 * TR Application Master Data API
 * This is the TR Application Master Data API.
 *
 * OpenAPI spec version: v1
 * Contact: tr@tecnicasreunidas.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddAreaDto } from '../modelAMD/addAreaDto';
import { AddLocationDto } from '../modelAMD/addLocationDto';
import { AddProjectDto } from '../modelAMD/addProjectDto';
import { AddUnitDto } from '../modelAMD/addUnitDto';
import { AreaDtoIEnumerableEnvelope } from '../modelAMD/areaDtoIEnumerableEnvelope';
import { AreaUnitsDtoIEnumerableEnvelope } from '../modelAMD/areaUnitsDtoIEnumerableEnvelope';
import { BooleanEnvelope } from '../modelAMD/booleanEnvelope';
import { EditAreaDto } from '../modelAMD/editAreaDto';
import { EditLocationDto } from '../modelAMD/editLocationDto';
import { EditProjectDto } from '../modelAMD/editProjectDto';
import { EditUnitDto } from '../modelAMD/editUnitDto';
import { LocationDtoIEnumerableEnvelope } from '../modelAMD/locationDtoIEnumerableEnvelope';
import { ProjectAreasDtoIEnumerableEnvelope } from '../modelAMD/projectAreasDtoIEnumerableEnvelope';
import { ProjectDtoIEnumerableEnvelope } from '../modelAMD/projectDtoIEnumerableEnvelope';
import { ProjectLocationsDtoIEnumerableEnvelope } from '../modelAMD/projectLocationsDtoIEnumerableEnvelope';
import { StringEnvelope } from '../modelAMD/stringEnvelope';
import { UnitDtoIEnumerableEnvelope } from '../modelAMD/unitDtoIEnumerableEnvelope';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProjectsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject('pathAMD') basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsAreasGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectAreasDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdProjectsAreasGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectAreasDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsAreasGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectAreasDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsAreasGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsAreasGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProjectAreasDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/areas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsAreasUnitsGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<AreaUnitsDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdProjectsAreasUnitsGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AreaUnitsDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsAreasUnitsGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AreaUnitsDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsAreasUnitsGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsAreasUnitsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AreaUnitsDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/areas/units`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdProjectsGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProjectDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsLocationsGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectLocationsDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdProjectsLocationsGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectLocationsDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsLocationsGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectLocationsDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsLocationsGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsLocationsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProjectLocationsDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/locations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsPost(applicationId: number, body?: Array<AddProjectDto>, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsPost(applicationId: number, body?: Array<AddProjectDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsPost(applicationId: number, body?: Array<AddProjectDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsPost(applicationId: number, body?: Array<AddProjectDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param areaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdDelete(applicationId: number, projectId: number, areaId: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdDelete(applicationId: number, projectId: number, areaId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdDelete(applicationId: number, projectId: number, areaId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdDelete(applicationId: number, projectId: number, areaId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasAreaIdDelete.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasAreaIdDelete.');
        }

        if (areaId === null || areaId === undefined) {
            throw new Error('Required parameter areaId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasAreaIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/areas/${encodeURIComponent(String(areaId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param areaId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsPut(applicationId: number, projectId: number, areaId: number, body?: EditUnitDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsPut(applicationId: number, projectId: number, areaId: number, body?: EditUnitDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsPut(applicationId: number, projectId: number, areaId: number, body?: EditUnitDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsPut(applicationId: number, projectId: number, areaId: number, body?: EditUnitDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsPut.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsPut.');
        }

        if (areaId === null || areaId === undefined) {
            throw new Error('Required parameter areaId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/areas/${encodeURIComponent(String(areaId))}/units`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param areaId 
     * @param unitId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsUnitIdDelete(applicationId: number, projectId: number, areaId: number, unitId: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsUnitIdDelete(applicationId: number, projectId: number, areaId: number, unitId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsUnitIdDelete(applicationId: number, projectId: number, areaId: number, unitId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsUnitIdDelete(applicationId: number, projectId: number, areaId: number, unitId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsUnitIdDelete.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsUnitIdDelete.');
        }

        if (areaId === null || areaId === undefined) {
            throw new Error('Required parameter areaId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsUnitIdDelete.');
        }

        if (unitId === null || unitId === undefined) {
            throw new Error('Required parameter unitId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasAreaIdUnitsUnitIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/areas/${encodeURIComponent(String(areaId))}/units/${encodeURIComponent(String(unitId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdAreasGet(applicationId: number, projectId: number, observe?: 'body', reportProgress?: boolean): Observable<AreaDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdAreasGet(applicationId: number, projectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AreaDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasGet(applicationId: number, projectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AreaDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasGet(applicationId: number, projectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasGet.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AreaDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/areas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdAreasPost(applicationId: number, projectId: number, body?: Array<AddAreaDto>, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdAreasPost(applicationId: number, projectId: number, body?: Array<AddAreaDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasPost(applicationId: number, projectId: number, body?: Array<AddAreaDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasPost(applicationId: number, projectId: number, body?: Array<AddAreaDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasPost.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/areas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdAreasPut(applicationId: number, projectId: number, body?: EditAreaDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdAreasPut(applicationId: number, projectId: number, body?: EditAreaDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasPut(applicationId: number, projectId: number, body?: EditAreaDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasPut(applicationId: number, projectId: number, body?: EditAreaDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasPut.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/areas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdAreasUnitsGet(applicationId: number, projectId: number, observe?: 'body', reportProgress?: boolean): Observable<AreaUnitsDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdAreasUnitsGet(applicationId: number, projectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AreaUnitsDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasUnitsGet(applicationId: number, projectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AreaUnitsDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasUnitsGet(applicationId: number, projectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasUnitsGet.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasUnitsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AreaUnitsDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/areas/units`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdAreasUnitsPost(applicationId: number, projectId: number, body?: Array<AddUnitDto>, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdAreasUnitsPost(applicationId: number, projectId: number, body?: Array<AddUnitDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasUnitsPost(applicationId: number, projectId: number, body?: Array<AddUnitDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdAreasUnitsPost(applicationId: number, projectId: number, body?: Array<AddUnitDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasUnitsPost.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdAreasUnitsPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/areas/units`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdDelete(applicationId: number, projectId: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdDelete(applicationId: number, projectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdDelete(applicationId: number, projectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdDelete(applicationId: number, projectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdDelete.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdLocationsGet(applicationId: number, projectId: number, observe?: 'body', reportProgress?: boolean): Observable<LocationDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsGet(applicationId: number, projectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LocationDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsGet(applicationId: number, projectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LocationDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsGet(applicationId: number, projectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdLocationsGet.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdLocationsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LocationDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/locations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param locationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdLocationsLocationIdDelete(applicationId: number, projectId: number, locationId: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsLocationIdDelete(applicationId: number, projectId: number, locationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsLocationIdDelete(applicationId: number, projectId: number, locationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsLocationIdDelete(applicationId: number, projectId: number, locationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdLocationsLocationIdDelete.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdLocationsLocationIdDelete.');
        }

        if (locationId === null || locationId === undefined) {
            throw new Error('Required parameter locationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdLocationsLocationIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/locations/${encodeURIComponent(String(locationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdLocationsPost(applicationId: number, projectId: number, body?: Array<AddLocationDto>, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsPost(applicationId: number, projectId: number, body?: Array<AddLocationDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsPost(applicationId: number, projectId: number, body?: Array<AddLocationDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsPost(applicationId: number, projectId: number, body?: Array<AddLocationDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdLocationsPost.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdLocationsPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/locations`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdLocationsPut(applicationId: number, projectId: number, body?: EditLocationDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsPut(applicationId: number, projectId: number, body?: EditLocationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsPut(applicationId: number, projectId: number, body?: EditLocationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdLocationsPut(applicationId: number, projectId: number, body?: EditLocationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdLocationsPut.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdLocationsPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/locations`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsProjectIdUnitsGet(applicationId: number, projectId: number, observe?: 'body', reportProgress?: boolean): Observable<UnitDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdProjectsProjectIdUnitsGet(applicationId: number, projectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UnitDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdUnitsGet(applicationId: number, projectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UnitDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdProjectsProjectIdUnitsGet(applicationId: number, projectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdUnitsGet.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiApplicationApplicationIdProjectsProjectIdUnitsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UnitDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects/${encodeURIComponent(String(projectId))}/units`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdProjectsPut(applicationId: number, body?: EditProjectDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdProjectsPut(applicationId: number, body?: EditProjectDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsPut(applicationId: number, body?: EditProjectDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdProjectsPut(applicationId: number, body?: EditProjectDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdProjectsPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/projects`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
