/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachmentDto';
import { PunchRemarkDto } from './punchRemarkDto';

export interface PunchBulkSignatureDto { 
    role?: string;
    punchIds?: Array<number>;
    sign?: string;
    date?: Date;
    remark?: PunchRemarkDto;
    attachments?: Array<AttachmentDto>;
}