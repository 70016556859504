/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ElementsMatchWbsBody } from '../model/elementsMatchWbsBody';
import { ElementsMatchWbsBody1 } from '../model/elementsMatchWbsBody1';
import { ElementsMatchWbsBody2 } from '../model/elementsMatchWbsBody2';
import { ElementsMatchWbsBody3 } from '../model/elementsMatchWbsBody3';
import { ElementsViewModel } from '../model/elementsViewModel';
import { ElementsWithPunchesIdsViewModel } from '../model/elementsWithPunchesIdsViewModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ElementsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param projectId 
     * @param templateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllByIds(projectId: number, templateId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ElementsViewModel>>;
    public getAllByIds(projectId: number, templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElementsViewModel>>>;
    public getAllByIds(projectId: number, templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElementsViewModel>>>;
    public getAllByIds(projectId: number, templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getAllByIds.');
        }

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getAllByIds.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElementsViewModel>>('get',`${this.basePath}/v1/Elements/allElements/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllElements(observe?: 'body', reportProgress?: boolean): Observable<Array<ElementsViewModel>>;
    public getAllElements(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElementsViewModel>>>;
    public getAllElements(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElementsViewModel>>>;
    public getAllElements(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElementsViewModel>>('get',`${this.basePath}/v1/Elements/allElements`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllElementsAndPunchesIds(projectId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ElementsWithPunchesIdsViewModel>>;
    public getAllElementsAndPunchesIds(projectId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElementsWithPunchesIdsViewModel>>>;
    public getAllElementsAndPunchesIds(projectId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElementsWithPunchesIdsViewModel>>>;
    public getAllElementsAndPunchesIds(projectId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElementsWithPunchesIdsViewModel>>('get',`${this.basePath}/v1/Elements/ElementsAndPunches`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param elementId 
     * @param elementHierarchyTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getElementChildren(elementId?: number, elementHierarchyTypeId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ElementsViewModel>>;
    public getElementChildren(elementId?: number, elementHierarchyTypeId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElementsViewModel>>>;
    public getElementChildren(elementId?: number, elementHierarchyTypeId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElementsViewModel>>>;
    public getElementChildren(elementId?: number, elementHierarchyTypeId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (elementId !== undefined && elementId !== null) {
            queryParameters = queryParameters.set('elementId', <any>elementId);
        }
        if (elementHierarchyTypeId !== undefined && elementHierarchyTypeId !== null) {
            queryParameters = queryParameters.set('elementHierarchyTypeId', <any>elementHierarchyTypeId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElementsViewModel>>('get',`${this.basePath}/v1/Elements/elementChildren`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param elementId 
     * @param elementHierarchyTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getElementChildrenFlattened(elementId?: number, elementHierarchyTypeId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ElementsViewModel>>;
    public getElementChildrenFlattened(elementId?: number, elementHierarchyTypeId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElementsViewModel>>>;
    public getElementChildrenFlattened(elementId?: number, elementHierarchyTypeId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElementsViewModel>>>;
    public getElementChildrenFlattened(elementId?: number, elementHierarchyTypeId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (elementId !== undefined && elementId !== null) {
            queryParameters = queryParameters.set('elementId', <any>elementId);
        }
        if (elementHierarchyTypeId !== undefined && elementHierarchyTypeId !== null) {
            queryParameters = queryParameters.set('elementHierarchyTypeId', <any>elementHierarchyTypeId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElementsViewModel>>('get',`${this.basePath}/v1/Elements/elementChildrenFlattened`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubSystems(observe?: 'body', reportProgress?: boolean): Observable<ElementsViewModel>;
    public getSubSystems(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElementsViewModel>>;
    public getSubSystems(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElementsViewModel>>;
    public getSubSystems(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElementsViewModel>('get',`${this.basePath}/v1/Elements/allSubSystems`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnits(observe?: 'body', reportProgress?: boolean): Observable<Array<ElementsViewModel>>;
    public getUnits(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ElementsViewModel>>>;
    public getUnits(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ElementsViewModel>>>;
    public getUnits(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ElementsViewModel>>('get',`${this.basePath}/v1/Elements/allUnits`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param nodeLevel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importFromAPI(projectId: number, nodeLevel: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public importFromAPI(projectId: number, nodeLevel: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public importFromAPI(projectId: number, nodeLevel: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public importFromAPI(projectId: number, nodeLevel: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling importFromAPI.');
        }

        if (nodeLevel === null || nodeLevel === undefined) {
            throw new Error('Required parameter nodeLevel was null or undefined when calling importFromAPI.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/v1/Elements/importFromAPI/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(nodeLevel))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMatchWbs(body?: ElementsMatchWbsBody, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public postMatchWbs(body?: ElementsMatchWbsBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public postMatchWbs(body?: ElementsMatchWbsBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public postMatchWbs(body?: ElementsMatchWbsBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/v1/Elements/MatchWbs`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
