/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExportViewModel } from '../model/exportViewModel';
import { ImportViewModel } from '../model/importViewModel';
import { MasterViewModel } from '../model/masterViewModel';
import { TopicExportBody } from '../model/topicExportBody';
import { TopicExportBody1 } from '../model/topicExportBody1';
import { TopicExportBody2 } from '../model/topicExportBody2';
import { TopicExportBody3 } from '../model/topicExportBody3';
import { TopicImportBody } from '../model/topicImportBody';
import { TopicImportBody1 } from '../model/topicImportBody1';
import { TopicImportBody2 } from '../model/topicImportBody2';
import { TopicImportBody3 } from '../model/topicImportBody3';
import { TopicImportPreviewBody } from '../model/topicImportPreviewBody';
import { TopicImportPreviewBody1 } from '../model/topicImportPreviewBody1';
import { TopicImportPreviewBody2 } from '../model/topicImportPreviewBody2';
import { TopicImportPreviewBody3 } from '../model/topicImportPreviewBody3';
import { TopicViewModel } from '../model/topicViewModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TopicService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param inspectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllInspectionTopics(inspectionId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TopicViewModel>>;
    public getAllInspectionTopics(inspectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TopicViewModel>>>;
    public getAllInspectionTopics(inspectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TopicViewModel>>>;
    public getAllInspectionTopics(inspectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (inspectionId === null || inspectionId === undefined) {
            throw new Error('Required parameter inspectionId was null or undefined when calling getAllInspectionTopics.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TopicViewModel>>('get',`${this.basePath}/v1/Topic/allInspectionTopics/${encodeURIComponent(String(inspectionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param toTemplateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTopicWithTransferConfig(toTemplateId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TopicViewModel>>;
    public getAllTopicWithTransferConfig(toTemplateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TopicViewModel>>>;
    public getAllTopicWithTransferConfig(toTemplateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TopicViewModel>>>;
    public getAllTopicWithTransferConfig(toTemplateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (toTemplateId === null || toTemplateId === undefined) {
            throw new Error('Required parameter toTemplateId was null or undefined when calling getAllTopicWithTransferConfig.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TopicViewModel>>('get',`${this.basePath}/v1/Topic/allTopics/${encodeURIComponent(String(toTemplateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTopics(observe?: 'body', reportProgress?: boolean): Observable<Array<MasterViewModel>>;
    public getAllTopics(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MasterViewModel>>>;
    public getAllTopics(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MasterViewModel>>>;
    public getAllTopics(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MasterViewModel>>('get',`${this.basePath}/v1/Topic/allTopics`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postExportTopics(body?: TopicExportBody, observe?: 'body', reportProgress?: boolean): Observable<ExportViewModel>;
    public postExportTopics(body?: TopicExportBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExportViewModel>>;
    public postExportTopics(body?: TopicExportBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExportViewModel>>;
    public postExportTopics(body?: TopicExportBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ExportViewModel>('post',`${this.basePath}/v1/Topic/Export`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postImportPreviewTopics(body?: TopicImportPreviewBody, observe?: 'body', reportProgress?: boolean): Observable<ImportViewModel>;
    public postImportPreviewTopics(body?: TopicImportPreviewBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportViewModel>>;
    public postImportPreviewTopics(body?: TopicImportPreviewBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportViewModel>>;
    public postImportPreviewTopics(body?: TopicImportPreviewBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ImportViewModel>('post',`${this.basePath}/v1/Topic/ImportPreview`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postImportTopics(body?: TopicImportBody, observe?: 'body', reportProgress?: boolean): Observable<ImportViewModel>;
    public postImportTopics(body?: TopicImportBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportViewModel>>;
    public postImportTopics(body?: TopicImportBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportViewModel>>;
    public postImportTopics(body?: TopicImportBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ImportViewModel>('post',`${this.basePath}/v1/Topic/Import`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
