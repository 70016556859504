import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DEFAULT_ROUTE_APP, DEFAULT_NO_PROJECTS } from 'src/app/models/const';
import { AuthService, URLKEY } from '../services/auth.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html'
})
export class AuthCallbackComponent implements OnInit {

  message = '';

  constructor(private authService: AuthService,
              private router: Router,
              private loginService: LoginService) { }

  private defaultRedirect() {
    let urlRedirected = sessionStorage.getItem(URLKEY);
    sessionStorage.removeItem(URLKEY);
    if (this.checkUserPermissions()) {
      if (urlRedirected === '/') {
        urlRedirected = DEFAULT_ROUTE_APP;
      }
      setTimeout(() => {
        this.router.navigateByUrl(urlRedirected);
      }, 500);
    }
  }

  private checkUserPermissions() {
    const user = this.loginService.user;
    if (user && user.projectsTemplates.length === 0 && !user.role) {
      this.message = 'This user doesn\'t have permissions for this application';
      return false;
    } else if (!!user.role && user.projectsTemplates.length === 0) {
      setTimeout(() => {
        this.router.navigateByUrl(DEFAULT_NO_PROJECTS);
      }, 500);
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.authService.completeAuthentication().then((user) => {
      this.message = 'Getting project information...';
      // Particular to the project used
      if (user) {
          this.loginService.loadUserConnected()
        .pipe(take(1))
          .subscribe(userWap => {
            this.loginService.user = userWap;
            this.loginService.resetUserProjects();
            this.defaultRedirect();
          });
      }
    });
  }

}
