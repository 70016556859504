import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { AliasStoreService } from '../stores/alias.store';


@Injectable()
export class AliasGuardService implements CanActivate {

  constructor(private aliasStore: AliasStoreService, private loginService: LoginService) { }

  canActivate(): Observable<boolean> {
    return new Observable((observator) => {
      const projectTemplate = this.loginService.projectTemplate;
      const aliasData = this.aliasStore.alias;
      if (aliasData && aliasData.projectId === projectTemplate.project.id && aliasData.templateId === projectTemplate.template.id) {
        observator.next(true);
        observator.complete();
      } else {
        this.aliasStore.loadAlias().subscribe(data => {
          this.aliasStore.alias = { data, projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
          observator.next(true);
          observator.complete();
        });
      }
    });
  }
}
