/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MediaTypeDto } from './mediaTypeDto';
import { PunchRemarkDto } from './punchRemarkDto';

export interface PunchMediaDto { 
    id?: number;
    punchId?: number;
    punchRemarkId?: number;
    mimeType?: string;
    name?: string;
    thumbnail?: string;
    imageFileServer?: string;
    mediaTypeId?: number;
    cover?: boolean;
    realNameFile?: string;
    mediaType?: MediaTypeDto;
    punchRemark?: PunchRemarkDto;
    lastModifiedAt?: Date;
}