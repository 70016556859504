/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomFieldBookmarks } from './customFieldBookmarks';
import { SignatureBookmarks } from './signatureBookmarks';

export interface PunchBookmarksViewModel { 
    id?: number;
    code?: string;
    description?: string;
    originator?: string;
    raisedBy?: string;
    inspection?: string;
    templateId?: number;
    commentary?: string;
    punchItems?: string;
    subContractorId?: number;
    subContractor?: string;
    disciplineId?: number;
    discipline?: string;
    categoryId?: number;
    category?: string;
    statusId?: number;
    status?: string;
    actionById?: number;
    actionBy?: string;
    topicId?: number;
    topic?: string;
    customFields?: Array<CustomFieldBookmarks>;
    signs?: Array<SignatureBookmarks>;
}