import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { DisciplineService } from 'src/proxy';
import { StoreModel } from '../models/model.store';


@Injectable()
export class DisciplineStoreService {

    _disciplines$ = new BehaviorSubject<StoreModel>(null);

    constructor(
        private disciplineService: DisciplineService,
        private loginService: LoginService
        ) {
    }

    reloadDisciplines() {
        const projectTemplate = this.loginService.projectTemplate;
        this.loadDiscipline().pipe(take(1)).subscribe(data => {
            this.discipline = { data, projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
        });
    }

    loadDiscipline() {
        return this.disciplineService.getAllDisciplines();
    }

    get discipline() {
        return this._disciplines$.value;
    }

    set discipline(data: StoreModel) {
        this._disciplines$.next(data);
    }

    get discipline$() {
        return this._disciplines$;
    }
}