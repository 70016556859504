export enum IconsPng {
  categories = 'categories',
  config = 'config',
  hierarchy = 'hierarchy',
  alerts = 'alerts',
  customFields = 'customFields',
  fields = 'fields',
  home = 'home',
  inspection = 'inspection',
  overview = 'overview',
  projects = 'projects',
  live_reports ='live_reports',
  punches = 'punches',
  search = 'search',
  sign = 'sign',
  templates = 'templates',
  topics = 'topics',
  users = 'icon-users',
  masterTables = 'users',
  meetingPoint = 'meetingPoint',
  documents = 'documents',
  downloads = 'wap-reports',
  process = 'process',
  invitation = 'invitation',
  items = 'items',
  consolidate = 'consolidate',
  maps = 'maps'
}
