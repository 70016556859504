/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElementsViewModel } from './elementsViewModel';
import { PunchCustomFieldViewModel } from './punchCustomFieldViewModel';
import { TagsViewModel } from './tagsViewModel';
import { UserViewModel } from './userViewModel';

export interface PunchDetailInViewModel { 
    id?: number;
    disciplineId?: number;
    categoryId?: number;
    topicId?: number;
    priorityId?: number;
    location?: string;
    description?: string;
    user?: UserViewModel;
    originatorId?: number;
    userOriginatorId?: string;
    subContractorId?: number;
    departmentActionId?: number;
    commentary?: string;
    itemsAffected?: Array<ElementsViewModel>;
    customsFields?: Array<PunchCustomFieldViewModel>;
    tags?: Array<TagsViewModel>;
    expectedClosingDate?: Date;
}