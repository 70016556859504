/**
 * TR Application Master Data API
 * This is the TR Application Master Data API.
 *
 * OpenAPI spec version: v1
 * Contact: tr@tecnicasreunidas.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddVersionDto } from '../modelAMD/addVersionDto';
import { AppVersionDtoIEnumerableEnvelope } from '../modelAMD/appVersionDtoIEnumerableEnvelope';
import { ApplicationConfigurationDtoIEnumerableEnvelope } from '../modelAMD/applicationConfigurationDtoIEnumerableEnvelope';
import { ApplicationDtoEnvelope } from '../modelAMD/applicationDtoEnvelope';
import { ApplicationDtoIEnumerableEnvelope } from '../modelAMD/applicationDtoIEnumerableEnvelope';
import { ApplicationLevelsDtoIEnumerableEnvelope } from '../modelAMD/applicationLevelsDtoIEnumerableEnvelope';
import { BooleanEnvelope } from '../modelAMD/booleanEnvelope';
import { CheckVersionResultDtoEnvelope } from '../modelAMD/checkVersionResultDtoEnvelope';
import { CreateApplicationDto } from '../modelAMD/createApplicationDto';
import { EditApplicationDto } from '../modelAMD/editApplicationDto';
import { EditLevelConfigDto } from '../modelAMD/editLevelConfigDto';
import { EditVersionDto } from '../modelAMD/editVersionDto';
import { Int32Envelope } from '../modelAMD/int32Envelope';
import { SaveLevelConfigDto } from '../modelAMD/saveLevelConfigDto';
import { StringEnvelope } from '../modelAMD/stringEnvelope';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ApplicationsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject('pathAMD') basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdDelete(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdDelete(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdDelete(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdDelete(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param appConfigType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdEntitiesAppConfigTypeGet(applicationId: number, appConfigType: number, observe?: 'body', reportProgress?: boolean): Observable<ApplicationConfigurationDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdEntitiesAppConfigTypeGet(applicationId: number, appConfigType: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationConfigurationDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdEntitiesAppConfigTypeGet(applicationId: number, appConfigType: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationConfigurationDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdEntitiesAppConfigTypeGet(applicationId: number, appConfigType: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdEntitiesAppConfigTypeGet.');
        }

        if (appConfigType === null || appConfigType === undefined) {
            throw new Error('Required parameter appConfigType was null or undefined when calling apiApplicationApplicationIdEntitiesAppConfigTypeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ApplicationConfigurationDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/entities/${encodeURIComponent(String(appConfigType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<ApplicationDtoEnvelope>;
    public apiApplicationApplicationIdGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationDtoEnvelope>>;
    public apiApplicationApplicationIdGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationDtoEnvelope>>;
    public apiApplicationApplicationIdGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ApplicationDtoEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdLevelsGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<ApplicationLevelsDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdLevelsGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationLevelsDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdLevelsGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationLevelsDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdLevelsGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdLevelsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ApplicationLevelsDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/levels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdLevelsPost(applicationId: number, body?: SaveLevelConfigDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdLevelsPost(applicationId: number, body?: SaveLevelConfigDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdLevelsPost(applicationId: number, body?: SaveLevelConfigDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdLevelsPost(applicationId: number, body?: SaveLevelConfigDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdLevelsPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/levels`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdLevelsPut(applicationId: number, body?: EditLevelConfigDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdLevelsPut(applicationId: number, body?: EditLevelConfigDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdLevelsPut(applicationId: number, body?: EditLevelConfigDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdLevelsPut(applicationId: number, body?: EditLevelConfigDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdLevelsPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/levels`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdPut(applicationId: number, body?: EditApplicationDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdPut(applicationId: number, body?: EditApplicationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdPut(applicationId: number, body?: EditApplicationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdPut(applicationId: number, body?: EditApplicationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param appVersion 
     * @param platform 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdVersionsCheckGet(applicationId: number, appVersion?: string, platform?: string, observe?: 'body', reportProgress?: boolean): Observable<CheckVersionResultDtoEnvelope>;
    public apiApplicationApplicationIdVersionsCheckGet(applicationId: number, appVersion?: string, platform?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CheckVersionResultDtoEnvelope>>;
    public apiApplicationApplicationIdVersionsCheckGet(applicationId: number, appVersion?: string, platform?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CheckVersionResultDtoEnvelope>>;
    public apiApplicationApplicationIdVersionsCheckGet(applicationId: number, appVersion?: string, platform?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdVersionsCheckGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (appVersion !== undefined && appVersion !== null) {
            queryParameters = queryParameters.set('appVersion', <any>appVersion);
        }
        if (platform !== undefined && platform !== null) {
            queryParameters = queryParameters.set('platform', <any>platform);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CheckVersionResultDtoEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/versions/check`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdVersionsGet(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<AppVersionDtoIEnumerableEnvelope>;
    public apiApplicationApplicationIdVersionsGet(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppVersionDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdVersionsGet(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppVersionDtoIEnumerableEnvelope>>;
    public apiApplicationApplicationIdVersionsGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdVersionsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AppVersionDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/versions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdVersionsPost(applicationId: number, body?: AddVersionDto, observe?: 'body', reportProgress?: boolean): Observable<Int32Envelope>;
    public apiApplicationApplicationIdVersionsPost(applicationId: number, body?: AddVersionDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Int32Envelope>>;
    public apiApplicationApplicationIdVersionsPost(applicationId: number, body?: AddVersionDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Int32Envelope>>;
    public apiApplicationApplicationIdVersionsPost(applicationId: number, body?: AddVersionDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdVersionsPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Int32Envelope>('post',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/versions`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdVersionsPut(applicationId: number, body?: EditVersionDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdVersionsPut(applicationId: number, body?: EditVersionDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdVersionsPut(applicationId: number, body?: EditVersionDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdVersionsPut(applicationId: number, body?: EditVersionDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdVersionsPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('put',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/versions`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param versionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationApplicationIdVersionsVersionidDelete(applicationId: number, versionId?: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationApplicationIdVersionsVersionidDelete(applicationId: number, versionId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationApplicationIdVersionsVersionidDelete(applicationId: number, versionId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationApplicationIdVersionsVersionidDelete(applicationId: number, versionId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling apiApplicationApplicationIdVersionsVersionidDelete.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (versionId !== undefined && versionId !== null) {
            queryParameters = queryParameters.set('versionId', <any>versionId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanEnvelope>('delete',`${this.basePath}/api/application/${encodeURIComponent(String(applicationId))}/versions/versionid`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param includeVersions 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationGet(includeVersions?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ApplicationDtoIEnumerableEnvelope>;
    public apiApplicationGet(includeVersions?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationDtoIEnumerableEnvelope>>;
    public apiApplicationGet(includeVersions?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationDtoIEnumerableEnvelope>>;
    public apiApplicationGet(includeVersions?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeVersions !== undefined && includeVersions !== null) {
            queryParameters = queryParameters.set('includeVersions', <any>includeVersions);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ApplicationDtoIEnumerableEnvelope>('get',`${this.basePath}/api/application`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicationPost(body?: CreateApplicationDto, observe?: 'body', reportProgress?: boolean): Observable<BooleanEnvelope>;
    public apiApplicationPost(body?: CreateApplicationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanEnvelope>>;
    public apiApplicationPost(body?: CreateApplicationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanEnvelope>>;
    public apiApplicationPost(body?: CreateApplicationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BooleanEnvelope>('post',`${this.basePath}/api/application`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
