/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ProcessStatus = 1 | 2 | 4 | 8 | 16;

export const ProcessStatus = {
    NUMBER_1: 1 as ProcessStatus,
    NUMBER_2: 2 as ProcessStatus,
    NUMBER_4: 4 as ProcessStatus,
    NUMBER_8: 8 as ProcessStatus,
    NUMBER_16: 16 as ProcessStatus
};