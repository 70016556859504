/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EntryTypeViewModel } from './entryTypeViewModel';
import { MasterViewModel } from './masterViewModel';
import { PunchLogChangeViewModel } from './punchLogChangeViewModel';
import { PunchRemarkViewModel } from './punchRemarkViewModel';
import { UserInfoViewModel } from './userInfoViewModel';

export interface PunchLogViewModel { 
    id?: number;
    entryType?: EntryTypeViewModel;
    category?: MasterViewModel;
    deparmentAction?: MasterViewModel;
    priority?: MasterViewModel;
    status?: MasterViewModel;
    entryDate?: Date;
    originator?: UserInfoViewModel;
    user?: UserInfoViewModel;
    actionUser?: UserInfoViewModel;
    remark?: PunchRemarkViewModel;
    subContractor?: UserInfoViewModel;
    userGuest?: string;
    commentary?: string;
    changes?: Array<PunchLogChangeViewModel>;
}