/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryViewModel } from './categoryViewModel';
import { InspectionTypeViewModel } from './inspectionTypeViewModel';
import { ProjectViewModel } from './projectViewModel';
import { TemplateProjectConfigViewModel } from './templateProjectConfigViewModel';
import { TemplatePunchCustomFieldViewModel } from './templatePunchCustomFieldViewModel';
import { TopicViewModel } from './topicViewModel';

export interface TemplateViewModel { 
    id?: number;
    code?: string;
    description?: string;
    hcsLinked?: boolean;
    topicsCount?: number;
    tags?: Array<string>;
    projects?: Array<ProjectViewModel>;
    configuration?: Array<TemplateProjectConfigViewModel>;
    templatePunchCustomField?: Array<TemplatePunchCustomFieldViewModel>;
    inspectionType?: Array<InspectionTypeViewModel>;
    category?: Array<CategoryViewModel>;
    topic?: Array<TopicViewModel>;
}