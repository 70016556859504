import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { PunchesTemplatesStoreService } from '../stores';


@Injectable()
export class PunchesTemplatesGuardService implements CanActivate {

  constructor(private punchesTemplateStore: PunchesTemplatesStoreService) { }

  canActivate(): Observable<boolean> {
    return new Observable((observator) => {
      const templateTypes = this.punchesTemplateStore.punchesTemplates;
      if (templateTypes) {
        observator.next(true);
        observator.complete();
      } else {
        this.punchesTemplateStore.loadPunchesTemplates().subscribe((data) => {
          this.punchesTemplateStore.punchesTemplates = data;
          observator.next(true);
          observator.complete();
        });
      }
    });
  }
}
