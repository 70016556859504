/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type FilterType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;

export const FilterType = {
    NUMBER_1: 1 as FilterType,
    NUMBER_2: 2 as FilterType,
    NUMBER_3: 3 as FilterType,
    NUMBER_4: 4 as FilterType,
    NUMBER_5: 5 as FilterType,
    NUMBER_6: 6 as FilterType,
    NUMBER_7: 7 as FilterType,
    NUMBER_8: 8 as FilterType,
    NUMBER_9: 9 as FilterType,
    NUMBER_10: 10 as FilterType,
    NUMBER_11: 11 as FilterType,
    NUMBER_12: 12 as FilterType,
    NUMBER_13: 13 as FilterType,
    NUMBER_14: 14 as FilterType
};