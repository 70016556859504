import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loading$ = new BehaviorSubject<boolean>(false);
  private counter = 0;

  constructor() { }

  startLoading() {
    this.counter++;
    this.loading$.next(true);
  }

  stopLoading(finish = false) {
    this.counter--;
    if (finish) {
      this.counter = 0;
    }
    if (this.counter <= 0) {
      this.counter = 0;
      this.loading$.next(false);
    }
  }

  get loadingS() {
    return this.loading$;
  }


}
