/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConfiguredReportMediaViewModel } from './configuredReportMediaViewModel';
import { ConfiguredReportTagViewModel } from './configuredReportTagViewModel';
import { PbiReportViewModel } from './pbiReportViewModel';

export interface ConfiguredReportViewModel { 
    id?: number;
    name?: string;
    description?: string;
    tags?: Array<ConfiguredReportTagViewModel>;
    lastUpdate?: Date;
    media?: ConfiguredReportMediaViewModel;
    reportSource?: PbiReportViewModel;
}