/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FilterViewModel } from './filterViewModel';

export interface SaveCustomReportViewModel { 
    templateProjectCustomReportId?: number;
    allowedProjectTemplates?: Array<string>;
    sourceId?: number;
    name?: string;
    description?: string;
    allowedRoles?: Array<string>;
    columns?: Array<string>;
    filters?: Array<FilterViewModel>;
}