import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { CustomTemplateService } from 'src/proxy';


@Injectable()
export class TemplateTypeStoreService {

    _templateTypes$ = new BehaviorSubject<any[]>(null);

    constructor(
        private customTemplateService: CustomTemplateService
        ) {
    }

    loadTemplateTypes() {
        return this.customTemplateService.getTypes();
    }

    get templateTypes() {
        return this._templateTypes$.value;
    }

    set templateTypes(data: any[]) {
        this._templateTypes$.next(data);
    }

    get templateTypes$() {
        return this._templateTypes$;
    }
}