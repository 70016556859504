/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElementsViewModel } from './elementsViewModel';
import { InspectionMediaViewModel } from './inspectionMediaViewModel';
import { InspectionMemberUnknownViewModel } from './inspectionMemberUnknownViewModel';
import { InspectionRemarkViewModel } from './inspectionRemarkViewModel';
import { InspectionStatusViewModel } from './inspectionStatusViewModel';
import { MasterViewModel } from './masterViewModel';
import { UserInfoViewModel } from './userInfoViewModel';
import { UserInspectionViewModel } from './userInspectionViewModel';
import { UserWalkInfoViewModel } from './userWalkInfoViewModel';
import { WbsViewModel } from './wbsViewModel';

export interface InspectionDetailViewModel { 
    id?: number;
    code?: string;
    description?: string;
    name?: string;
    information?: string;
    location?: string;
    tracked?: boolean;
    date?: Date;
    lastModifiedAt?: Date;
    inspectionType?: MasterViewModel;
    inspectionState?: InspectionStatusViewModel;
    wbs?: WbsViewModel;
    attendes?: Array<UserInspectionViewModel>;
    items?: Array<ElementsViewModel>;
    userWalks?: Array<UserWalkInfoViewModel>;
    attendesCount?: number;
    files?: Array<InspectionMediaViewModel>;
    organizer?: UserInfoViewModel;
    walks?: number;
    walksStarted?: number;
    walksCompleted?: number;
    walksScheduled?: number;
    walksPaused?: number;
    walksCanceled?: number;
    attendeesUnknown?: Array<InspectionMemberUnknownViewModel>;
    remarks?: Array<InspectionRemarkViewModel>;
}