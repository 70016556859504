/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ProcessType = 1 | 2 | 4 | 8;

export const ProcessType = {
    NUMBER_1: 1 as ProcessType,
    NUMBER_2: 2 as ProcessType,
    NUMBER_4: 4 as ProcessType,
    NUMBER_8: 8 as ProcessType
};