import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PROJECT_ID, TEMPLATE_ID } from 'src/app/core/policy/authorization-properties.utils';
import { PunchesDetailEnum } from 'src/app/models/const/routes.enum';
import { SubcontractService, UserService } from 'src/proxy';
import { NotificationService } from 'src/proxy/apiNotifications/notification.service';
import { NotificationViewModel } from 'src/proxy/modelNotifications/models';
import { NotificationDistributionPropertyDto } from 'src/proxy/modelNotifications/notificationDistributionPropertyDto';
import { NotificationEnum } from '../../templates/components/alerts-setup/models/alert.enum';

export const CTE_BASE_PATH_IMAGES = 'assets/icon/';

@Injectable({
  providedIn: 'root'
})

export class SharedService {
 
  constructor( 
      private userService: UserService,
      private notificationsService: NotificationService,
      private subcontractService: SubcontractService
     ) { }

  checkUrl(currentUrl: string, urlArr: string[]) {
    let res = false;
    if (urlArr.filter(r => r.indexOf(':') !== -1).length > 0) {
      urlArr.forEach(route => {
        if (currentUrl.indexOf(route) !== -1) {
          res = true;
        }
        if (route.indexOf(':') !== -1 && currentUrl.indexOf(route.replace(':', '')) !== -1) {
          res = true;
        }
      });
    }
    return res || urlArr.indexOf(currentUrl) !== -1;
  }

  checkUrlParamsTemplateId(currentUrl: string) {
    return currentUrl.indexOf(PunchesDetailEnum.templateId) !== -1;
  }

  getUsersByName(userName: string) {
    if (userName && userName !== '') {
      return this.userService.getByName(userName);
    }
    return of([]);
  }

  getUsersByNameSubcontractor(userName: string) {
    if (userName && userName !== '') {
      return this.subcontractService.getByName(userName).pipe(map((s: any) => s.resultObject));
    }
    return of([]);
  }

  loadAlertTypes(projectTemplate: string) {
    const keys = this.getKeysProjectTemplate(projectTemplate);
    return this.notificationsService.notificationAllPost(keys);
  }

  // async saveAlerts(alerts: any) { // TODO delete
  //   for (const [key, value] of Object.entries(alerts)) {
  //     const res = await this.saveNotification(value, key);
  //   }
  //   return of(true);
  // }

  saveAlert(alert: NotificationViewModel, projectTemplate: any) {
    const keys = this.getKeysProjectTemplate(projectTemplate);
    if (alert.isSubscribed) {
      return this.notificationsService.notificationNotificationIdUnsubscribePatch(alert.id, keys);
    }
    return this.notificationsService.notificationNotificationIdSubscribePatch(alert.id, keys);
  }

  saveAlertEmail(alert: any, projectTemplate: any, email: boolean) {
    const keys = this.getKeysProjectTemplate(projectTemplate);
    return this.notificationsService.notificationNotificationIdSubscribePatch(alert.id, keys, email);
  }

  private getProjectTemplate(projectTemplate: string) {
    const values = projectTemplate.split('$');
    return {
      projectId: values[0],
      templateId: values[1]
    };
  }

  private getKeysProjectTemplate(projectTemplate: string): NotificationDistributionPropertyDto[] {
    const projectTemplateValues = this.getProjectTemplate(projectTemplate);

    return [
      { key: PROJECT_ID, value: projectTemplateValues.projectId },
      { key: TEMPLATE_ID, value: projectTemplateValues.templateId }
    ];
  }

  private saveNotification(value, type) {
    if (value) {
      return this.notificationsService.notificationNotificationIdSubscribePatch(NotificationEnum.Alert, type).toPromise();
    } else {
      return this.notificationsService.notificationNotificationIdUnsubscribePatch(NotificationEnum.Alert, type).toPromise();
    }
  }
}


export function getPngImage(image: string) {
  return `${CTE_BASE_PATH_IMAGES}${image}.png`;
}

export function getSvgImage(image: string) {
  return `${CTE_BASE_PATH_IMAGES}${image}.svg`;
}
