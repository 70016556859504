import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { StatusStoreService } from '../stores';


@Injectable()
export class StatusGuardService implements CanActivate {

  constructor(private statusStore: StatusStoreService) { }

  canActivate(): Observable<boolean> {
    return new Observable((observator) => {
      const status = this.statusStore.status;
      if (status) {
        observator.next(true);
        observator.complete();
      } else {
        this.statusStore.loadStatus().subscribe((data) => {
          this.statusStore.status = data;
          observator.next(true);
          observator.complete();
        });
      }
    });
  }
}
