import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Route } from '@angular/router';
import { EnvService } from 'src/app/core/deployment/env.service';
import { APIS } from './api/api';
import { APIS_AMD } from './apiAMD/api';
import { AuthorizationService, UserAuthService } from './apiAS/api';

import { NotificationService } from './apiNotifications/notification.service';
import { RolesService } from './apiPS/api';
import { Configuration } from './configuration';
import { BASE_PATH } from './variables';


@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        ...APIS,
        ...APIS_AMD,
        NotificationService,
        AuthorizationService,
        UserAuthService,
        RolesService,
        {
            provide: 'env', 
            useFactory: (env: EnvService) => env.serverNotifications,
            deps: [EnvService]
        },
        {
            provide: BASE_PATH,
            useFactory: (env: EnvService) => env.server,
            deps: [EnvService]
        },
        {
            provide: 'pathAS',
            useFactory: (env: EnvService) => env.endpointAS,
            deps: [EnvService]
        },
        {
            provide: 'pathPS',
            useFactory: (env: EnvService) => env.endpointPS,
            deps: [EnvService]
        },
        {
            provide: 'pathAMD',
            useFactory: (env: EnvService) => env.endpointAMD,
            deps: [EnvService]
        },
    ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<Route> {
        return {
            ngModule: ApiModule,
            providers: [{ provide: Configuration, useFactory: configurationFactory }]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: ApiModule,
        @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
                'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
