/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MasterViewModel } from './masterViewModel';
import { ProjectTemplateViewModel } from './projectTemplateViewModel';

export interface UserViewModel { 
    id?: string;
    email?: string;
    name?: string;
    phone?: string;
    company?: string;
    disciplines?: Array<MasterViewModel>;
    role?: string;
    projectsTemplates?: Array<ProjectTemplateViewModel>;
    units?: Array<MasterViewModel>;
    isOrganizer?: boolean;
}