import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { TagService } from 'src/proxy';
import { StoreModel } from '../models/model.store';


@Injectable()
export class TagsStoreService {

    _tags$ = new BehaviorSubject<StoreModel>(null);

    constructor(
        private tagService: TagService,
        private loginService: LoginService
        ) {
    }

    reloadTags() {
        const projectTemplate = this.loginService.projectTemplate;
        this.loadTag().pipe(take(1)).subscribe(data => {
            this.tag = { data, projectId: projectTemplate.project.id, templateId: projectTemplate.template.id };
        });
    }

    loadTag() {
        return this.tagService.getAllTags();
    }

    get tag() {
        return this._tags$.value;
    }

    set tag(data: StoreModel) {
        this._tags$.next(data);
    }

    get tag$() {
        return this._tags$;
    }
}