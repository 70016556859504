import * as faSolid from '@fortawesome/free-solid-svg-icons';
import { Policies } from 'src/app/core/policy/policy';
import { CTE_PROJECTS } from 'src/app/core/services/coockies.user';
import { IconsPng } from 'src/app/wap/configuration/shared/models/icons.model';
import { getPngImage } from 'src/app/wap/configuration/shared/services/shared.service';
import { RouteAppGroup } from '../route.model';
import { RoutesShortWandP, TextMenu } from './routes.enum';

export const SERVER_ROUTE = 'wap/';
// ----------------------------------------------------- Routes ------------------------------------------------------------------
// Default route where the user is redirected when login
export const DEFAULT_ROUTE_APP = `${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Inspections}`;
export const DEFAULT_NO_PROJECTS = `${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesGlobalCollections}`;

export const ROUTE_INSPECTIONS_DETAIL_NEW = `${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Inspections}/${RoutesShortWandP.Detail}/${RoutesShortWandP.New}`;
export const ROUTE_INSPECTIONS_DETAIL = `${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Inspections}/${RoutesShortWandP.Detail}/`;

export const ROUTE_FULL_COLLECTIONS_GLOBAL = `${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesGlobalCollections}`;
export const ROUTE_FULL_COLLECTIONS_PROJECT = `${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesProjectCollections}`;
export const ROUTE_FULL_DETAIL_TEMPLATE_GLOBAL =
`/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesGlobal}/`;
export const ROUTE_FULL_DETAIL_TEMPLATE_PROJECT =
`/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesDetail}/`;
export const ROUTE_SHORT_DETAIL_TEMPLATE = RoutesShortWandP.TemplatesDetail;
export const ROUTE_SHORT_COLLECTION_PROJECT_TEMPLATE = RoutesShortWandP.TemplatesProjectCollections;

export const ROUTE_REPORTS = `${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Reports}`;
export const ROUTE_REPORTS_DETAIL = `${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Reports}/${RoutesShortWandP.Detail}/`;

export const ROUTE_LIVE_REPORTS_NEW = `${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.LiveReports}/${RoutesShortWandP.Detail}`;

// ----------------------------------------------------- Routes ------------------------------------------------------------------

// --------------------------------------------------- Query Params ----------------------------
export const ROUTE_LOGIN_QUERY_PARAMS_NOPROJECTS = 'noProjects';


// ------------------------------------------------- Configuration Menu ----------------------------------------------------------
export const MAIN_ROUTES: RouteAppGroup = {
  name: 'Main',
  conditionToShow: () => checkProjects(),
  routes: [
    { id: TextMenu.Inspections, name: TextMenu.Inspections, img: getPngImage(IconsPng.inspection), path: RoutesShortWandP.Inspections,
      conditionToShow: () => checkProjects(), policies: [Policies.InspectionsView] },
    { id: TextMenu.Punches, name: TextMenu.Punches, img: getPngImage(IconsPng.punches), path: RoutesShortWandP.Punches,
      conditionToShow: () => checkProjects(), policies: [Policies.PunchesView] },
    { id: TextMenu.Reports, name: TextMenu.Reports, img: getPngImage(IconsPng.documents), path: RoutesShortWandP.Reports,
      conditionToShow: () => checkProjects(), policies: [Policies.BiReportsView] },
    { id: TextMenu.Downloads, name: TextMenu.Downloads, img: getPngImage(IconsPng.downloads), path: RoutesShortWandP.Downloads,
      conditionToShow: () => checkProjects(), policies: [Policies.WapReportsView] },
   
  ]
};

export const SECOND_MENU_ROUTER: RouteAppGroup[] = [{
  name: 'Project setup',
  conditionToShow: () => checkProjects(),
  routes: [
    { id: TextMenu.Configuration, name: TextMenu.Configuration,
      path: `${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesDetail}`,
      img: getPngImage(IconsPng.config), policies: [Policies.ConfigurationView],
      conditionToShow: () => checkProjects()
    },
    { id: TextMenu.Users, name: TextMenu.Users, path: RoutesShortWandP.UsersSetup,
      img: getPngImage(IconsPng.users), policies: [Policies.UsersView],
      conditionToShow: () => checkProjects() },
    { id: TextMenu.Maps, name: TextMenu.Maps, path: RoutesShortWandP.Maps,
      img: getPngImage(IconsPng.maps), policies: [Policies.MapsView],
      conditionToShow: () => checkProjects() }
  ]
}, {
  name: 'Admin',
  conditionToShow: () => true,
  routes: [
    { id: TextMenu.Templates, name: TextMenu.Templates, path: `${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesGlobalCollections}`,
      img: getPngImage(IconsPng.templates) , policies: [Policies.TemplatesView],
      conditionToShow: () => true
    },
    { id: TextMenu.Projects, name: TextMenu.Projects, path: RoutesShortWandP.Projects,
      img: getPngImage(IconsPng.projects) , policies: [Policies.ProjectsView],
      conditionToShow: () => true
    },
    { id: TextMenu.MasterTables, name: TextMenu.MasterTables, path: RoutesShortWandP.MasterTables,
      img: getPngImage(IconsPng.masterTables) , policies: [Policies.MasterTablesView],
      conditionToShow: () => checkProjects()
    },
    { id: TextMenu.LiveReports, name: TextMenu.LiveReports, path: RoutesShortWandP.LiveReports,
      img: getPngImage(IconsPng.live_reports) , policies: [Policies.LiveReports],
      conditionToShow: () => true
    },
    { id: TextMenu.Process, name: TextMenu.Process, img: getPngImage(IconsPng.process), path: RoutesShortWandP.Process,
      conditionToShow: () => checkProjects(), policies: [Policies.WapProcessView] }
  ]
}];



export const LAST_ELEMENTS: RouteAppGroup = null;
// ------------------------------------------------- Configuration Menu ----------------------------------------------------------


// ------------------------------------------------- Filter projects Navbar ------------------------------------------------------
export const ROUTES_WITH_PROJECT = [
  `/auth-callback`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesDetail}/:`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesProjectCollections}`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Inspections}`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Inspections}/${RoutesShortWandP.Detail}/:`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Inspections}/${RoutesShortWandP.Detail}/${RoutesShortWandP.New}`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Punches}`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.UsersSetup}`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Maps}`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Reports}`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Reports}/${RoutesShortWandP.Detail}/:`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Downloads}`,
];
export const ROUTES_DISABLED_PROJECT = [
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesDetail}/:`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Inspections}/${RoutesShortWandP.Detail}/:`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Projects}`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Reports}/${RoutesShortWandP.Detail}/:`,
];
export const ROUTES_RELOAD = [
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesGlobalCollections}`,
]

// ------------------------------------------------- Filter projects Navbar ------------------------------------------------------
export const ROUTES_WITH_FILTER = [
  // `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Punches}`
];

// ------------------------------------------------- Special layout ------------------------------------------------------
export const ROUTES_FULL_SCREEN = [
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Inspections}/${RoutesShortWandP.Detail}/:`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Punches}`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Reports}/${RoutesShortWandP.Detail}/:`,
];

export const ROUTES_FULL_SCREEN_2 = [
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Reports}/${RoutesShortWandP.Detail}/:`,
];

export const ROUTES_HIDDEN_SIDEBAR = [
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Templates}${RoutesShortWandP.TemplatesGlobal}/:`,
  `/${RoutesShortWandP.RootConfiguration}${RoutesShortWandP.Inspections}/${RoutesShortWandP.Detail}/:`,
];

export function checkProjects(): boolean {
  const projects: any[] = JSON.parse(localStorage.getItem(CTE_PROJECTS));
  return projects.length > 0;
}
