/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryViewModel } from './categoryViewModel';
import { ElementsViewModel } from './elementsViewModel';
import { InspectionTypeViewModel } from './inspectionTypeViewModel';
import { InspectionViewModel } from './inspectionViewModel';
import { MasterViewModel } from './masterViewModel';
import { OriginatorDto } from './originatorDto';
import { PunchCustomFieldViewModel } from './punchCustomFieldViewModel';
import { PunchMediaViewModel } from './punchMediaViewModel';
import { StatusViewModel } from './statusViewModel';
import { SubcontractorDto } from './subcontractorDto';
import { TagsViewModel } from './tagsViewModel';
import { UserInfoViewModel } from './userInfoViewModel';

export interface PunchViewModel { 
    id?: number;
    code?: string;
    description?: string;
    altitude?: number;
    location?: string;
    pdfPositionX?: number;
    pdfPositionY?: number;
    pdfPage?: number;
    pdfFile?: number;
    punchCoordinator?: string;
    rejectCount?: number;
    dateEntry?: Date;
    createdAt?: Date;
    lastModifiedAt?: Date;
    expectedClosingDate?: Date;
    discipline?: MasterViewModel;
    category?: CategoryViewModel;
    departmentAction?: MasterViewModel;
    itemsAffected?: Array<ElementsViewModel>;
    attachments?: Array<PunchMediaViewModel>;
    punchCustomFields?: Array<PunchCustomFieldViewModel>;
    priority?: MasterViewModel;
    status?: StatusViewModel;
    topic?: MasterViewModel;
    entryType?: MasterViewModel;
    originator?: OriginatorDto;
    userOriginator?: UserInfoViewModel;
    subContractor?: SubcontractorDto;
    cover?: PunchMediaViewModel;
    userValid?: boolean;
    commentary?: string;
    user?: UserInfoViewModel;
    tags?: Array<TagsViewModel>;
    inspection?: InspectionViewModel;
    inspectionCode?: string;
    inspectionType?: InspectionTypeViewModel;
    unit?: MasterViewModel;
    subSystem?: MasterViewModel;
    isMyPunch?: boolean;
    isRejected?: boolean;
    isSignable?: boolean;
    isRejectable?: boolean;
    reasonNotSignable?: string;
    onDispute?: boolean;
    closed?: boolean;
    isLocked?: boolean;
}