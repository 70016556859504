export const EXAMPLE_USER = {
  company: 'TR',
  departmentDescription: 'MATERIALS',
  disciplinesDescription: 'CIVIL',
  email: 'taskiadmin@tecnicasreunidas.es',
  familyDescription: 'FOUND',
  groups: 'GrupoPrueba6',
  id: '3a624dac-c36a-4466-8c83-cdc206ff1713',
  idRol: 1,
  name: 'TaskiAdmin',
  phone: '999999',
  projects: [{ id: 10160, name: 'CRISP', default: true }, { id: 10100, name: 'Ras Tanura', default: false }],
  rolCode: 'User',
  unitsDescription: 'DHU S1 - DAO Hydroprocessing Unit S1',
  access_token: ''
};
