/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PunchDto } from './punchDto';
import { PunchMediaDto } from './punchMediaDto';
import { RemarkTypesDto } from './remarkTypesDto';
import { UserDetailDto } from './userDetailDto';

export interface PunchRemarkDto { 
    id?: number;
    punchId?: number;
    punch?: PunchDto;
    remark?: string;
    createdBy?: string;
    createdAt?: Date;
    user?: UserDetailDto;
    remarkTypeId?: number;
    remarkType?: RemarkTypesDto;
    punchMedia?: Array<PunchMediaDto>;
    isEdited?: boolean;
}