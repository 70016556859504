import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { canAccess } from '../policy/policies';
import { CTE_POLICY } from '../policy/policy';
import { AuthService } from '../services/auth.service';
import { PolicyService } from '../services/policy.service';



@Injectable()
export class PolicyGuardService implements CanActivateChild {

  constructor(private authService: AuthService, private policyService: PolicyService) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const policy = route.data[CTE_POLICY];
    if (!policy) {
      return of(true);
    }
    return new Observable((observator) => {
      this.authService.loadUser().subscribe((user) => {
        observator.next(canAccess(policy, this.authService.verificator, this.policyService.getRolesFromPolicies.bind(this.policyService)));
        observator.complete();
      });
    });
  }
}
