/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryViewModel } from './categoryViewModel';
import { InspectionTypeFlowSignViewModel } from './inspectionTypeFlowSignViewModel';
import { TopicViewModel } from './topicViewModel';

export interface InspectionTypeViewModel { 
    id?: number;
    code?: string;
    description?: string;
    name?: string;
    connector?: string;
    templateId?: number;
    tags?: Array<string>;
    category?: Array<CategoryViewModel>;
    topic?: Array<TopicViewModel>;
    inspectionTypeFlowSign?: Array<InspectionTypeFlowSignViewModel>;
}