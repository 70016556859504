/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryDto } from './categoryDto';
import { DisciplineDto } from './disciplineDto';
import { ElementOutDto } from './elementOutDto';
import { InspectionDetailDto } from './inspectionDetailDto';
import { InspectionTypeDto } from './inspectionTypeDto';
import { MasterDto } from './masterDto';
import { OriginatorDto } from './originatorDto';
import { PunchCustomFieldDto } from './punchCustomFieldDto';
import { PunchMediaOutDto } from './punchMediaOutDto';
import { StatusDto } from './statusDto';
import { SubcontractorDto } from './subcontractorDto';
import { TagsDto } from './tagsDto';
import { UserDto } from './userDto';

export interface PunchInspectionOutDto { 
    id?: number;
    code?: string;
    description?: string;
    altitude?: number;
    location?: string;
    pdfPositionX?: number;
    pdfPositionY?: number;
    pdfPage?: number;
    pdfFile?: number;
    punchCoordinator?: string;
    rejectCount?: number;
    dateEntry?: Date;
    createdAt?: Date;
    lastModifiedAt?: Date;
    expectedClosingDate?: Date;
    discipline?: DisciplineDto;
    category?: CategoryDto;
    departmentAction?: MasterDto;
    punchCustomFields?: Array<PunchCustomFieldDto>;
    itemsAffected?: Array<ElementOutDto>;
    attachments?: Array<PunchMediaOutDto>;
    priority?: MasterDto;
    status?: StatusDto;
    topic?: MasterDto;
    entryType?: MasterDto;
    originator?: OriginatorDto;
    userOriginator?: UserDto;
    subContractor?: SubcontractorDto;
    cover?: PunchMediaOutDto;
    userValid?: boolean;
    user?: UserDto;
    tags?: Array<TagsDto>;
    inspection?: InspectionDetailDto;
    inspectionCode?: string;
    inspectionType?: InspectionTypeDto;
    unit?: MasterDto;
    subSystem?: MasterDto;
    isMyPunch?: boolean;
    isRejected?: boolean;
    isSignable?: boolean;
    isRejectable?: boolean;
    reasonNotSignable?: string;
    onDispute?: boolean;
    closed?: boolean;
}