/**
 * WalkAndPunch API
 * This is the WalkAndPunch Service API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DisciplineDto } from './disciplineDto';
import { ElementsDto } from './elementsDto';
import { InspectionWalkDto } from './inspectionWalkDto';
import { TemplateProjectDto } from './templateProjectDto';

export interface UserDetailDto { 
    id?: string;
    email?: string;
    name?: string;
    phone?: string;
    company?: string;
    departmentId?: number;
    userProjectTemplate?: Array<TemplateProjectDto>;
    userDiscipline?: Array<DisciplineDto>;
    userUnit?: Array<ElementsDto>;
    walks?: Array<InspectionWalkDto>;
    isOrganizer?: boolean;
    role?: string;
}