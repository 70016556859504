import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomTemplateService } from 'src/proxy';

export enum TemplateTypeEnum {
    Punches = 1
}


@Injectable()
export class PunchesTemplatesStoreService {

    _punchesTemplates$ = new BehaviorSubject<any[]>(null);

    constructor(
        private customTemplateService: CustomTemplateService
        ) {
    }

    loadPunchesTemplates() {
        return this.customTemplateService.getByProjectTemplateAndType(TemplateTypeEnum.Punches);
    }

    refreshPunchesTemplates() {
        this.loadPunchesTemplates().subscribe(data => {
            this.punchesTemplates = data;
        });
    }

    get punchesTemplates() {
        return this._punchesTemplates$.value;
    }

    set punchesTemplates(data: any[]) {
        this._punchesTemplates$.next(data);
    }

    get punchesTemplates$() {
        return this._punchesTemplates$;
    }
}